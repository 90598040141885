// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthInput_inputContainer__FkX4j {
  display: block;
  width: 100%;
  padding: 0 20px;
  background: #E9EFF6;
  line-height: 40px;
  border-width: 0;
  border-radius: 20px;
  font-family: "Roboto", sans-serif;
  padding-block: 1px;
  padding-inline: 21px;
  outline: none;
}
.AuthInput_inputContainer__FkX4j:focus-visible {
  outline-offset: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/AuthInput/index.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EACA,eAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,mBAAA;EACA,iCAAA;EACA,kBAAA;EACA,oBAAA;EACA,aAAA;AACF;AAAE;EACE,iBAAA;AAEJ","sourcesContent":[".inputContainer{\r\n  display: block;\r\n  width: 100%;\r\n  padding: 0 20px;\r\n  background: #E9EFF6;\r\n  line-height: 40px;\r\n  border-width: 0;\r\n  border-radius: 20px;\r\n  font-family: 'Roboto', sans-serif;\r\n  padding-block: 1px;\r\n  padding-inline: 21px;\r\n  outline: none;\r\n  &:focus-visible{\r\n    outline-offset: 0;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `AuthInput_inputContainer__FkX4j`
};
export default ___CSS_LOADER_EXPORT___;
