import React from 'react'
import SelectComponent from '../../SelectComponent'

function CommercialRealEstate({
  filtersAll,
  whoPosted,
  setWhoPosted,
  typeBuilding,
  setTypeBuilding,
  transactionType,
  setTransactionType,
}) {
  return (
    <>
      <SelectComponent
        filtersAll={filtersAll}
        value={typeBuilding}
        setValue={setTypeBuilding}
        center={true}
        filter={'typeBuilding'}
        label={'Тип строения'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={transactionType}
        setValue={setTransactionType}
        center={true}
        filter={'transactionType'}
        label={'Тип сделки'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={whoPosted}
        setValue={setWhoPosted}
        center={true}
        filter={'whoPosted'}
        label={'Кто разместил'}
      />
    </>
  )
}

export default CommercialRealEstate
