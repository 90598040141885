import React from 'react'
import SelectComponent from '../../SelectComponent'

function SpecialEquipmentAndMotorcycles({
  filtersAll,
  columnId,
  typeA,
  setTypeA,
  state,
  setState,
  type,
  setType,
}) {
  return (
    <>
      <SelectComponent
        filtersAll={filtersAll}
        value={typeA}
        setValue={setTypeA}
        center={true}
        filter={columnId}
        label={'Тип'}
      />
      {columnId === 'Спецтехника' && (
        <SelectComponent
          filtersAll={filtersAll}
          value={state}
          setValue={setState}
          center={true}
          filter={'stateProduct'}
          label={'Состояние товара'}
        />
      )}
      <SelectComponent
        filtersAll={filtersAll}
        value={type}
        setValue={setType}
        center={true}
        filter={'typeProduct'}
        label={'Тип объявления'}
      />
    </>
  )
}
export default SpecialEquipmentAndMotorcycles
