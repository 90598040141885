import React from 'react'
import styles from './index.module.scss'
import { useDispatch } from 'react-redux'
import * as actions from '../../actions'

function IsActiveBrandList({ brand }) {
  const dispatch = useDispatch()

  return (
    <div
      className={styles.labelItem}
      onClick={() => {
        dispatch(actions.toggleFilters(brand.i))
      }}
    >
      {brand.checked ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          data-test-id="329483"
          data-test-value="checked"
          className="sc-kxtuKG fDSKkD"
        >
          <path
            d="M17 3H7a4 4 0 0 0-4 4v10a4 4 0 0 0 4 4h10a4 4 0 0 0 4-4V7a4 4 0 0 0-4-4z"
            fill="#7092fe"
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 12.115l.8-.807 2.057 2.077L15.2 9l.8.808L10.857 15 8 12.115z"
            fill="#fff"
            stroke="#fff"
          ></path>
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          data-test-id="329483"
          data-test-value="unchecked"
          className={styles.labelItemIcon}
        >
          <path
            d="M16.5 3.5h-9a4 4 0 0 0-4 4v9a4 4 0 0 0 4 4h9a4 4 0 0 0 4-4v-9a4 4 0 0 0-4-4z"
            fill="#fff"
          ></path>
        </svg>
      )}
      <span className={styles.itemName}>{brand.name}</span>
    </div>
  )
}

export default IsActiveBrandList
