import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Parallax, Navigation } from 'swiper/modules'
import styles from './index.module.scss'
import { getCategoryUrl } from '../../api'
import { Link } from 'react-router-dom'
import SelectCategoryList from '../SelectCategoryList'

function SelectCategory({ category }) {
  const [width, setWidth] = useState(window.innerWidth <= 600)
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth <= 600)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return (
    <Swiper
      slidesPerView={width ? 2.8 : 8}
      speed={500}
      navigation={true}
      parallax={true}
      modules={[Parallax, Navigation]}
      className={styles.swiper}
    >
      {category.map((item, index) => {
        if (item.image && item.categoryRoute) {
          return (
            <SwiperSlide
              key={`item-${item.id}-${index}-${item.categoryRoute}`}
              className={styles.slide}
            >
              <SelectCategoryList
                className={styles.slideName}
                item={item}
                key={`${item.id}_${index}-item`}
                route={item.categoryRoute}
              />
            </SwiperSlide>
          )
        } else if (item.columns.length > 0) {
          return item.columns.map(
            (s, i) =>
              s.image && (
                <SwiperSlide
                  key={`column-${s.id}-${i}`}
                  className={styles.slide}
                >
                  <SelectCategoryList
                    className={styles.slideName}
                    item={s}
                    key={`${s.id}_${i}-column`}
                    route={
                      item.categoryRoute
                        ? `/${item.categoryRoute}/${s.columnRoute}`
                        : `/${s.columnRoute}`
                    }
                  />
                </SwiperSlide>
              ),
          )
        }
      })}
    </Swiper>
  )
}
export default SelectCategory
