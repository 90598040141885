import React from 'react'
import styles from './index.module.scss'

function MobileMain({ category, dispatch }) {
  return (
    <div className={styles.mobileMain}>
      <div className={styles.mainContainer}>
        <></>
      </div>
    </div>
  )
}
export default MobileMain
