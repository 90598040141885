import React from 'react'
import styles from './index.module.scss'

function VariantContainer({ label, center = false, children }) {
  return (
    <div
      className={
        center
          ? `${styles.flexCreatorIn} ${styles.flexCreatorInC}`
          : `${styles.flexCreatorIn} ${styles.flexCreatorInS}`
      }
    >
      <div className={styles.labelW}>{label}</div>
      <div className={styles.labelS}>{children}</div>
    </div>
  )
}
export default VariantContainer
