import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist'
import { createBrowserHistory } from 'history'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import rootSaga from '../../sagas'
import rootReducer from '../../redux'

const persistConfig = {
  key: 'root',
  storage,
}

export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const middleware = [sagaMiddleware, routerMiddleware(history)]
const persistedReducer = persistReducer(persistConfig, rootReducer(history))
export default function configureStore() {
  const enhancer = compose(applyMiddleware(...middleware, thunk))
  const store = createStore(persistedReducer, enhancer)
  const persist = persistStore(store)
  sagaMiddleware.run(rootSaga)
  return { store, persist }
}
