// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IsActiveBrandList_labelItem__8yYOx {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  cursor: pointer;
}
.IsActiveBrandList_labelItem__8yYOx:hover .IsActiveBrandList_labelItemIcon__KjC7B {
  stroke: rgb(112, 146, 254);
  stroke-opacity: 1;
}

.IsActiveBrandList_labelItemIcon__KjC7B {
  width: 24px;
  height: 24px;
  stroke-opacity: 0.4;
  stroke: rgb(143, 143, 143);
  transition: stroke, stroke-opacity 0.2s ease-in;
}`, "",{"version":3,"sources":["webpack://./src/components/IsActiveBrandList/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,WAAA;EACA,eAAA;AACF;AACI;EACE,0BAAA;EACA,iBAAA;AACN;;AAGA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,0BAAA;EACA,+CAAA;AAAF","sourcesContent":[".labelItem{\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 5px;\r\n  width: 100%;\r\n  cursor: pointer;\r\n  &:hover{\r\n    .labelItemIcon{\r\n      stroke: rgb(112, 146, 254);\r\n      stroke-opacity: 1;\r\n    }\r\n  }\r\n}\r\n.labelItemIcon{\r\n  width: 24px;\r\n  height: 24px;\r\n  stroke-opacity: 0.4;\r\n  stroke: rgb(143, 143, 143);\r\n  transition: stroke, stroke-opacity 0.2s ease-in;\r\n}\r\n.itemName{\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelItem": `IsActiveBrandList_labelItem__8yYOx`,
	"labelItemIcon": `IsActiveBrandList_labelItemIcon__KjC7B`
};
export default ___CSS_LOADER_EXPORT___;
