import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import styles from './index.module.scss'
import ProductList from '../ProductList'

function ForNothingList({ forNothing, user, width }) {
  return (
    <Swiper
      slidesPerView={width <= 576 ? 1.99 : width >= 576 && width <= 992 ? 5 : 4}
      spaceBetween={0}
      navigation={true}
      modules={[Navigation]}
      className={styles.swiper}
    >
      {forNothing.map((item, index) => (
        <SwiperSlide key={`${item.id}_${index + 2}`}>
          <ProductList product={item} user={user} key={`${item.id}_${index}`} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
export default ForNothingList
