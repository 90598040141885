import React from 'react'
import { useParams } from 'react-router-dom'
import IdContainer from '../../../components/IdContainer'

function WomenWardrobeAId() {
  const { id } = useParams()
  return (
    <>
      <IdContainer id={id} />
    </>
  )
}
export default WomenWardrobeAId
