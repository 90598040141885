import React from 'react'
import ProductFilter from '../ProductFilter'
import styles from './index.module.scss'

function PageContainer({
  categoryRoute,
  columnRoute,
  subCategoryRoute,
  children,
}) {
  return (
    <main className={styles.main}>
      <div>для рекламного блока</div>
      <div className={styles.rowContainer}>
        <div className={styles.col1}>{children}</div>
        <ProductFilter
          categoryRoute={categoryRoute}
          columnRoute={columnRoute}
          subCategoryRoute={subCategoryRoute}
        />
      </div>
    </main>
  )
}
export default PageContainer
