import * as ActionTypes from '../constants/user.constants'

/* User Info */
export const userInfo = (values) => ({
  type: ActionTypes.USER_INFO_REQUEST,
  payload: values,
})
export const userInfoSuccess = (values) => ({
  type: ActionTypes.USER_INFO_SUCCESS,
  payload: values,
})
export const userInfoError = (error) => ({
  type: ActionTypes.USER_INFO_ERROR,
  payload: error,
})

export const uploadAvatar = (values) => ({
  type: ActionTypes.UPLOAD_USER_AVATAR,
  payload: values,
})
export const uploadAvatarProgress = (values) => ({
  type: ActionTypes.UPLOAD_USER_AVATAR_PROGRESS,
  payload: values,
})
export const uploadAvatarSuccess = (values) => ({
  type: ActionTypes.UPLOAD_USER_AVATAR_SUCCESS,
  payload: values,
})

export const toggleCropperAvatar = (values) => ({
  type: ActionTypes.TOGGLE_CROPPER_AVATAR,
  payload: values,
})

export const cropperAvatarSave = (values) => ({
  type: ActionTypes.CROPPER_AVATAR_SAVE,
  payload: values,
})
export const cropperAvatarSaveSuccess = (values) => ({
  type: ActionTypes.CROPPER_AVATAR_SAVE_SUCCESS,
  payload: values,
})
