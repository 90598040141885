import React from 'react'
import styles from './index.module.scss'
function ListColumnCreator({ setColumnId, columns }) {
  return (
    <li className={styles.columnsBox}>
      <button
        className={styles.setColumnId}
        onClick={() => {
          setColumnId({ value: columns.id, label: columns.name })
        }}
      >
        {columns.name}
      </button>
    </li>
  )
}
export default ListColumnCreator
