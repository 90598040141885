import React from 'react'
import { Link } from 'react-router-dom'
import styles from './index.module.scss'
import { getProductImage } from '../../api'

function ProductList({ product, user }) {
  const isFavorite =
    user && user.productId && user.productId.some((f) => f === product.id)

  const route = () => {
    if (
      !product.category.categoryRoute &&
      product.category.columns.subCategory
    ) {
      return `/${product.category.columns.columnRoute}/${product.category.columns.subCategory.subCategoryRoute}/${product.id}`
    } else if (
      product.category.categoryRoute &&
      product.category.columns.subCategory
    ) {
      return `/${product.category.categoryRoute}/${product.category.columns.columnRoute}/${product.category.columns.subCategory.subCategoryRoute}/${product.id}`
    } else if (
      product.category.categoryRoute &&
      !product.category.columns.subCategory
    ) {
      return `/${product.category.categoryRoute}/${product.category.columns.columnRoute}/${product.id}`
    } else {
      return ''
    }
  }
  return (
    <>
      <div className={styles.productItem}>
        <span
          className={
            product.vip || product.start || product.min
              ? styles.vip
              : styles.noneVip
          }
        >
          <span className={styles.linkContainer}>
            <Link to={route()} className={styles.link} title={product.name}>
              <figure className={styles.figure}>
                <div className={styles.figureInner}>
                  <div className={styles.imageContainer}>
                    <svg role={'img'} className={styles.imgSvg}>
                      {product.album && product.album.length > 0 ? (
                        <image
                          preserveAspectRatio="xMidYMid slice"
                          xlinkHref={getProductImage(product.album[0].image)}
                          width="100%"
                          height="100%"
                          x="0"
                          y="0"
                        ></image>
                      ) : (
                        <image
                          preserveAspectRatio="xMidYMid slice"
                          xlinkHref="https://cdn1.youla.io/files/images/360_360/66/3d/663deb25cb255ddaa905a322-2.jpg"
                          width="100%"
                          height="100%"
                          x="0"
                          y="0"
                        ></image>
                      )}
                    </svg>
                  </div>
                  <div className={styles.productBage}>
                    <div className={styles.productBageInner}>
                      <div className={styles.productBageWrap}>
                        <div>
                          <div className={styles.bageIconInner}>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              data-test-block="PaymentIcon"
                              className={styles.bageIcon}
                            >
                              <g fill="#FFF" fillRule="evenodd">
                                <path d="M14 2.8v6.179c0 2.21-1.492 4.164-3.682 4.824L8 14.5l-2.317-.697C3.492 13.143 2 11.19 2 8.98V2.8l6-1.3 6 1.3zm-6.318 7.988v-.447h1.08a.55.55 0 0 0 .363-.112.378.378 0 0 0 .134-.307.385.385 0 0 0-.134-.31.543.543 0 0 0-.364-.114H7.706v-.685h.938c.501 0 .924-.085 1.267-.253.343-.168.601-.404.774-.707a2.11 2.11 0 0 0 .259-1.06c0-.415-.09-.776-.269-1.082A1.784 1.784 0 0 0 9.894 5c-.342-.166-.754-.25-1.235-.25H7.394c-.342 0-.595.08-.76.241-.164.16-.246.41-.246.75v2.066h-.361c-.18.003-.32.05-.42.14a.465.465 0 0 0-.152.365c0 .155.05.277.152.364.1.087.24.132.42.136h.361v.685h-.435a.541.541 0 0 0-.366.114.388.388 0 0 0-.132.31c0 .13.044.232.132.307.088.074.21.112.366.112h.46v.447a.605.605 0 0 0 .222.376.657.657 0 0 0 .412.134c.16 0 .297-.045.413-.134a.605.605 0 0 0 .222-.376z"></path>
                                <path d="M8.322 7.808c.442 0 .766-.078.971-.233.205-.156.308-.414.308-.776 0-.359-.098-.62-.293-.781-.195-.162-.487-.243-.874-.243H7.7v2.033h.62z"></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className={styles.productBageWrapper}>
                        <div className={styles.bageInfoContainer}>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            data-test-block="DeliveryIcon"
                            className={styles.bageIcon}
                          >
                            <path
                              fill="#fff"
                              fillRule="evenodd"
                              d="M3.393 11.5l.643-3h6.214a.75.75 0 1 0 0-1.5H4.357l.389-1.814A1.5 1.5 0 0 1 6.213 4h8.932a1.5 1.5 0 0 1 1.466 1.814L16.143 8h.15a3 3 0 0 1 2.581 1.472l2.336 3.947a2 2 0 0 1 .258 1.302l-.223 1.562A2 2 0 0 1 19.265 18h-.436a3.001 3.001 0 0 1-5.658 0H13v-.015c-.07.01-.14.015-.213.015H8.83a3.001 3.001 0 0 1-5.72-.2 1.5 1.5 0 0 1-.72-1.614L3.07 13h6.18a.75.75 0 1 0 0-1.5H3.393zM6 18a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm11.686-7.448a2 2 0 0 0-1.704-.952H15.8l-.9 3.9h4.6l-1.814-2.948zM16 18a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                            ></path>
                          </svg>
                          <span className={styles.bageCountry}>
                            {product.country || 'Наро-Фоминск'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.priceContainer}>
                    <div className={styles.priceInner}>
                      <p className={styles.priceWrapperContainer}>
                        <span className={styles.priceInnerW}>
                          <span className={styles.sum}>
                            {product.price.toString().length === 5
                              ? product.price.toString().slice(0, 2) +
                                ' ' +
                                product.price.toString().slice(2)
                              : product.price.toString().length === 6
                                ? product.price.toString().slice(0, 3) +
                                  ' ' +
                                  product.price.toString().slice(3)
                                : product.price}
                          </span>
                          &nbsp;
                          <span>
                            <span className={styles.r}>₽</span>
                          </span>
                        </span>
                        <span className={styles.typePrice}>в месяц</span>
                      </p>
                    </div>
                    <div
                      role={'button'}
                      className={styles.favoritesInfoContainer}
                    >
                      <div className={styles.favoritesIconContainer}>
                        <div className={styles.favoritesIconWrapper}>
                          <div className={styles.favoritesIconInner}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill={isFavorite ? '#fb0202' : 'currentColor'}
                            >
                              <path d="M12.333 5.673L12 5.97l-.333-.298C10.487 4.618 9.229 4 8 4 4.962 4 3 5.962 3 9c0 4.01 3.244 7.656 8.842 10.975a.4.4 0 0 0 .326-.004C17.772 16.615 21 12.996 21 9c0-3.038-1.962-5-5-5-1.229 0-2.488.618-3.667 1.673zM16 3c3.59 0 6 2.41 6 6 0 4.452-3.44 8.308-9.311 11.824-.394.246-.98.246-1.366.006C5.46 17.353 2 13.466 2 9c0-3.59 2.41-6 6-6 1.39 0 2.746.61 4 1.641C13.254 3.61 14.61 3 16 3z"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <figcaption className={styles.figcaption}>
                    <span
                      data-test-block="ProductName"
                      color="primary"
                      className={styles.productName}
                    >
                      {product.name}
                    </span>
                  </figcaption>
                </div>
              </figure>
            </Link>
          </span>
        </span>
      </div>
    </>
  )
}

export default ProductList
