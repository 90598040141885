import React from 'react'
import styles from '../index.module.scss'
import InputForm from '../../InputForm'
import SelectComponent from '../../SelectComponent'

function SaleOfLand({
  filtersAll,
  gas,
  setGas,
  periodOfUse,
  setPeriodOfUse,
  whoPosted,
  setWhoPosted,
  electricity,
  setElectricity,
  heating,
  setHeating,
  waterSupply,
  setWaterSupply,
  plotArea,
  setPlotArea,
  plotType,
  setPlotType,
}) {
  return (
    <>
      <div className={styles.border} />
      <div className={styles.title}>Параметры участкаа</div>
      <InputForm
        label={'Площадь участка'}
        value={plotArea}
        setValue={setPlotArea}
        subLabel={'сот.'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={plotType}
        setValue={setPlotType}
        center={true}
        filter={'plotType'}
        label={'Тип участка'}
      />
      <div className={styles.title}>Коммуникации</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={electricity}
        setValue={setElectricity}
        center={true}
        filter={'electricity'}
        label={'Электричество'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={heating}
        setValue={setHeating}
        center={true}
        filter={'heating'}
        label={'Отопление'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={gas}
        setValue={setGas}
        center={true}
        filter={'gamePc'}
        label={'Газ'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={waterSupply}
        setValue={setWaterSupply}
        center={true}
        filter={'heating'}
        label={'Водоснабжение'}
      />
      <div className={styles.border} />
      <div className={styles.title}>Условия</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={periodOfUse}
        setValue={setPeriodOfUse}
        center={true}
        filter={'periodOfUseN'}
        label={'Срок владения'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={whoPosted}
        setValue={setWhoPosted}
        center={true}
        filter={'whoPosted'}
        label={'Кто разместил'}
      />
      <div className={styles.border} />
    </>
  )
}

export default SaleOfLand
