import React from 'react'
import SelectComponent from '../../SelectComponent'

function Services({
  filtersAll,
  subCategoryId,
  priceOf,
  setPriceOf,
  placeDelivery,
  setPlaceDelivery,
}) {
  return (
    <>
      {subCategoryId && subCategoryId.value ? (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={priceOf}
            setValue={setPriceOf}
            center={true}
            filter={'priceOf'}
            label={'Стоимость указана'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={placeDelivery}
            setValue={setPlaceDelivery}
            center={true}
            filter={'placeDelivery'}
            label={'Место оказания'}
          />
        </>
      ) : (
        <>
          <></>
        </>
      )}
    </>
  )
}
export default Services
