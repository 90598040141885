import routesLik from './constants/routes.constants'
import Home from './pages/publicPages/Home'
import LoginPage from './pages/publicPages/LoginPage'
import RegisterPage from './pages/publicPages/RegisterPage'
import ProductCreate from './pages/privatePages/ProductCreate'
import WomenWardrobe from './pages/publicPages/WomenWardrobe'
import WomenWardrobeA from './pages/publicPages/WomenWardrobeA'
import WomenWardrobeAId from './pages/publicPages/WomenWardrobeAId'
import WomenWardrobeDlyaBeremennyh from './pages/publicPages/WomenWardrobeDlyaBeremennyh'
import WomenWardrobeVerhnyaya from './pages/publicPages/WomenWardrobeVerhnyaya'
import WomenWardrobeVerhnyayaId from './pages/publicPages/WomenWardrobeVerhnyayaId'

export const publicRouteConfig = [
  {
    id: 0,
    path: routesLik.root,
    component: Home,
    exact: true,
  },
  {
    id: 1,
    path: routesLik.login,
    component: LoginPage,
    exact: true,
  },
  {
    id: 2,
    path: routesLik.register,
    component: RegisterPage,
    exact: true,
  },
  {
    id: 3,
    path: routesLik.productCreate,
    component: ProductCreate,
    exact: true,
  },
  {
    id: 4,
    path: routesLik.womenWardrobe,
    component: WomenWardrobe,
    exact: true,
  },
  {
    id: 5,
    path: routesLik.womenWardrobeA,
    component: WomenWardrobeA,
    exact: true,
  },
  {
    id: 6,
    path: routesLik.womenWardrobeAId,
    component: WomenWardrobeAId,
    exact: true,
  },
  {
    id: 7,
    path: routesLik.womenWardrobeBluzy,
    component: WomenWardrobeAId,
    exact: true,
  },
  {
    id: 8,
    path: routesLik.womenWardrobeBluzyId,
    component: WomenWardrobeAId,
    exact: true,
  },
  {
    id: 9,
    path: routesLik.womenWardrobeDlyaBeremennyh,
    component: WomenWardrobeDlyaBeremennyh,
    exact: true,
  },
  {
    id: 10,
    path: routesLik.womenWardrobeDlyaBeremennyhId,
    component: WomenWardrobeDlyaBeremennyh,
    exact: true,
  },
  {
    id: 11,
    path: routesLik.womenWardrobeVerhnyaya,
    component: WomenWardrobeVerhnyaya,
    exact: true,
  },
  {
    id: 12,
    path: routesLik.womenWardrobeVerhnyayaId,
    component: WomenWardrobeVerhnyayaId,
    exact: true,
  },
]

export const privateRouteConfig = [
  ...publicRouteConfig.filter((r) => r.id !== 1 && r.id !== 2),
  {
    id: 88,
    path: routesLik.productCreate,
    component: ProductCreate,
    exact: true,
  },
]
