import React from 'react'
import styles from '../index.module.scss'
import SelectComponent from '../../SelectComponent'
import InputForm from '../../InputForm'

function RentingAnApartmentForALongTime({
  filtersAll,
  columnId,
  yearConstruction,
  setYearConstruction,
  houseType,
  setHouseType,
  toTheMetro,
  setToTheMetro,
  elevators,
  setElevators,
  infrastructure,
  setInfrastructure,
  roomsCount,
  setRoomsCount,
  floor,
  setFloor,
  floorH,
  setFloorH,
  totalArea,
  setTotalArea,
  livingArea,
  setLivingArea,
  kitchenArea,
  setKitchenArea,
  repair,
  setRepair,
  bathrooms,
  setBathrooms,
  balcony,
  setBalcony,
  whoPosted,
  setWhoPosted,
  roomArea,
  setRoomArea,
  fridge,
  setFridge,
  dishwasher,
  setDishwasher,
  washingMachine,
  setWashingMachine,
  prepayment,
  setPrepayment,
  publicUtilities,
  setPublicUtilities,
}) {
  const isEnabled = columnId === 54 || columnId === 57
  const isEnabled2 = columnId === 54 || columnId === 53

  return (
    <>
      <div className={styles.border} />
      <div className={styles.title}>Параметры дома</div>
      <div className={styles.titleL}>Основные</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={houseType}
        setValue={setHouseType}
        center={true}
        filter={'houseType'}
        label={'Тип дома'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={toTheMetro}
        setValue={setToTheMetro}
        center={true}
        filter={'toTheMetro'}
        label={'До метро'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={elevators}
        setValue={setElevators}
        center={true}
        filter={'elevators'}
        label={'Лифты'}
      />
      <div className={styles.titleL}>Дополнительные</div>
      <InputForm
        label={'Год постройки'}
        value={yearConstruction}
        setValue={setYearConstruction}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={infrastructure}
        setValue={setInfrastructure}
        center={true}
        isMulti={true}
        filter={'infrastructure'}
        label={'Инфраструктура'}
      />
      <div className={styles.border} />
      <div className={styles.title}>Параметры квартиры</div>
      <div className={styles.titleL}>Основные</div>
      {isEnabled && (
        <InputForm
          label={'Площадь комнаты'}
          subLabel={'м²'}
          value={roomArea}
          setValue={setRoomArea}
        />
      )}
      <SelectComponent
        filtersAll={filtersAll}
        value={roomsCount}
        setValue={setRoomsCount}
        center={true}
        filter={'roomsCount'}
        label={'Комнат в квартире'}
      />
      <InputForm label={'Этаж'} value={floor} setValue={setFloor} />
      <InputForm label={'Этажность дома'} value={floorH} setValue={setFloorH} />
      <div className={styles.titleL}>Площадь</div>
      <InputForm
        label={'Общая площадь'}
        subLabel={'м²'}
        value={totalArea}
        setValue={setTotalArea}
      />
      <InputForm
        label={'Жилая площадь'}
        subLabel={'м²'}
        value={livingArea}
        setValue={setLivingArea}
      />
      <InputForm
        label={'Площадь кухни'}
        subLabel={'м²'}
        value={kitchenArea}
        setValue={setKitchenArea}
      />
      <div className={styles.titleL}>Дополнительные</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={repair}
        setValue={setRepair}
        center={true}
        filter={'repair'}
        label={'Ремонт'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={bathrooms}
        setValue={setBathrooms}
        center={true}
        filter={'bathrooms'}
        label={'Санузлы'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={balcony}
        setValue={setBalcony}
        center={true}
        filter={'balcony'}
        label={'Балкон'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={fridge}
        setValue={setFridge}
        center={true}
        filter={'gamePc'}
        label={'Холодильник'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={dishwasher}
        setValue={setDishwasher}
        center={true}
        filter={'gamePc'}
        label={'Посудомоечная машина'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={washingMachine}
        setValue={setWashingMachine}
        center={true}
        filter={'gamePc'}
        label={'Стиральная машина'}
      />
      <div className={styles.border} />
      <div className={styles.title}>Условия</div>
      {isEnabled2 ? (
        <SelectComponent
          filtersAll={filtersAll}
          value={prepayment}
          setValue={setPrepayment}
          center={true}
          filter={'prepayment'}
          label={'Предоплата'}
        />
      ) : (
        <InputForm
          label={'Залог собственнику'}
          value={prepayment}
          setValue={setPrepayment}
          subLabel={'₽'}
        />
      )}
      {columnId !== 56 && (
        <SelectComponent
          filtersAll={filtersAll}
          value={publicUtilities}
          setValue={setPublicUtilities}
          center={true}
          filter={'publicUtilities'}
          label={'Коммунальные услуги'}
        />
      )}
      <SelectComponent
        filtersAll={filtersAll}
        value={whoPosted}
        setValue={setWhoPosted}
        center={true}
        filter={'whoPosted'}
        label={'Кто разместил'}
      />
      <div className={styles.border} />
    </>
  )
}

export default RentingAnApartmentForALongTime
