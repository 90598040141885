import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import CategoryCreatorList from '../../../components/CategoryCreatorList'
import * as actions from '../../../actions'
import SelectVariant from '../../../components/SelectVariant'
import MapYandex from '../../../components/MapYandex'
import Select from 'react-select'

function ProductCreate() {
  const dispatch = useDispatch()
  const coords = useSelector((state) => state.app.coords)
  const productCategory = useSelector((state) => state.app.productCreateStage)
  const category = useSelector((state) => state.app.category)
  const categoryId = useSelector((state) => state.app.categoryId)
  const ymapsRef = useRef(null)
  const [width, setWidth] = useState(window.innerWidth)
  const [columnId, setColumnId] = useState(null)
  const [subCategoryId, setSubCategoryId] = useState(null)
  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  const [state, setState] = useState(null)
  const [color, setColor] = useState(null)
  const [type, setType] = useState(null)
  const [country, setCountry] = useState(null)
  const [price, setPrice] = useState(0)
  const [size, setSize] = useState(null)
  const [season, setSeason] = useState(null)
  const [typeA, setTypeA] = useState(null)
  const [brand, setBrand] = useState(null)
  const [length, setLength] = useState(null)
  const [sex, setSex] = useState(null)
  const [sizeHeight, setSizeHeight] = useState(null)
  const [weightGroup, setWeightGroup] = useState(null)
  const [fasteningC, setFasteningC] = useState(null)
  const [peculiaritiesC, setPeculiaritiesC] = useState(null)
  const [adjustmentsC, setAdjustmentsC] = useState(null)
  const [strollersT, setStrollersT] = useState(null)
  const [strollersBl, setStrollersBl] = useState(null)
  const [strollersCir, setStrollersCir] = useState(null)
  const [operatingOs, setOperatingOs] = useState(null)
  const [builtMemory, setBuiltMemory] = useState(null)
  const [periodOfUse, setPeriodOfUse] = useState(null)
  const [diagonal, setDiagonal] = useState(null)
  const [ozu, setOzu] = useState(null)
  const [camera1, setCamera1] = useState(null)
  const [camera2, setCamera2] = useState(null)
  const [compatibilityA, setCompatibilityA] = useState(null)
  const [compatibilityI, setCompatibilityI] = useState(null)
  const [moistureProtection, setMoistureProtection] = useState(null)
  const [pedometer, setPedometer] = useState(null)
  const [heartMete, setHeartMete] = useState(null)
  const [sleepMete, setSleepMete] = useState(null)
  const [phoneCall, setPhoneCall] = useState(null)
  const [autoResp, setAutoResp] = useState(null)
  const [aon, setAon] = useState(null)
  const [device, setDevice] = useState(null)
  const [amperes, setAmperes] = useState(null)
  const [usbCount, setUsbCount] = useState(null)
  const [connectT, setConnectT] = useState(null)
  const [cable, setCable] = useState(null)
  const [materialCh, setMaterialCh] = useState(null)
  const [categCam, setCategCam] = useState(null)
  const [viewfinder, setViewfinder] = useState(null)
  const [imageStabilizer, setImageStabilizer] = useState(null)
  const [videoQuality, setVideoQuality] = useState(null)
  const [typeSd, setTypeSd] = useState(null)
  const [zoomOp, setZoomOp] = useState(null)
  const [actionCamera, setActionCamera] = useState(null)
  const [r360, setR360] = useState(null)
  const [conType, setConType] = useState(null)
  const [motionSen, setMotionSen] = useState(null)
  const [microphone, setMicrophone] = useState(null)
  const [wifi, setWifi] = useState(null)
  const [mountType, setMountType] = useState(null)
  const [autofocus, setAutofocus] = useState(null)
  const [swivelHead, setSwivelHead] = useState(null)
  const [playVid, setPlayVid] = useState(null)
  const [ssd, setSsd] = useState(null)
  const [videoTy, setVideoTy] = useState(null)
  const [game, setGame] = useState(null)
  const [opticalDrive, setOpticalDrive] = useState(null)
  const [hdmi, setHdmi] = useState(null)
  const [speakers, setSpeakers] = useState(null)
  const [usb, setUsb] = useState(null)
  const [smartTV, setSmartTV] = useState(null)
  const [t3d, setT3d] = useState(null)
  const [hd, setHd] = useState(null)
  const [hdr, setHdr] = useState(null)
  const [sizeDisplay, setSizeDisplay] = useState(null)
  const [karaoke, setKaraoke] = useState(null)
  const [acousticType, setAcousticType] = useState(null)
  const [portable, setPortable] = useState(null)
  const [touchScreen, setTouchScreen] = useState(null)
  const [fm, setFm] = useState(null)
  const [displayType, setDisplayType] = useState(null)
  const [displayColor, setDisplayColor] = useState(null)
  const [backlight, setBacklight] = useState(null)
  const [wireless, setWireless] = useState(null)
  const [volumeCh, setVolumeCh] = useState(null)
  const [waterN, setWaterN] = useState(null)
  const [noiseReduction, setNoiseReduction] = useState(null)
  const [connectors, setConnectors] = useState(null)
  const [scopeOfApplication, setScopeOfApplication] = useState(null)
  const [safety, setSafety] = useState(null)
  const [timer, setTimer] = useState(null)
  const [installationType, setInstallationType] = useState(null)
  const [utensilCapacity, setUtensilCapacity] = useState(null)
  const [leakProtection, setLeakProtection] = useState(null)
  const [delayStart, setDelayStart] = useState(null)
  const [typeCleaning, setTypeCleaning] = useState(null)
  const [kg, setKg] = useState(null)
  const [spin, setSpin] = useState(null)
  const [drying, setDrying] = useState(null)
  const [loadType, setLoadType] = useState(null)
  const [height, setHeight] = useState(null)
  const [depth, setDepth] = useState(null)
  const [swidth, setSWidth] = useState(null)
  const [applicationArea, setApplicationArea] = useState(null)
  const [typeSport, setTypeSport] = useState(null)
  const [forChild, setForChild] = useState(null)
  const [frame, setFrame] = useState(null)
  const [platform, setPlatform] = useState(null)
  const [genre, setGenre] = useState(null)
  const [format, setFormat] = useState(null)
  const [theme, setTheme] = useState(null)
  const [priceOf, setPriceOf] = useState(null)
  const [placeDelivery, setPlaceDelivery] = useState(null)
  const [typeService, setTypeService] = useState(null)
  //
  const [apartmentType, setApartmentType] = useState(null)
  const [yearConstruction, setYearConstruction] = useState(null)
  const [houseType, setHouseType] = useState(null)
  const [toTheMetro, setToTheMetro] = useState(null)
  const [elevators, setElevators] = useState(null)
  const [gas, setGas] = useState(null)
  const [infrastructure, setInfrastructure] = useState(null)
  const [roomsCount, setRoomsCount] = useState(null)
  const [floor, setFloor] = useState(null)
  const [floorH, setFloorH] = useState(null)
  const [totalArea, setTotalArea] = useState(null)
  const [livingArea, setLivingArea] = useState(null)
  const [kitchenArea, setKitchenArea] = useState(null)
  const [ceilingHeight, setCeilingHeight] = useState(null)
  const [repair, setRepair] = useState(null)
  const [bathrooms, setBathrooms] = useState(null)
  const [balcony, setBalcony] = useState(null)
  const [whoPosted, setWhoPosted] = useState(null)
  const [mortgagePossible, setMortgagePossible] = useState(null)
  const [roomArea, setRoomArea] = useState(null)
  const [materHouse, setMaterHouse] = useState(null)
  const [romSlipC, setRomSlipC] = useState(null)
  const [electricity, setElectricity] = useState(null)
  const [heating, setHeating] = useState(null)
  const [waterSupply, setWaterSupply] = useState(null)
  const [garage, setGarage] = useState(null)
  const [plotArea, setPlotArea] = useState(null)
  const [plotType, setPlotType] = useState(null)
  const [fridge, setFridge] = useState(null)
  const [dishwasher, setDishwasher] = useState(null)
  const [washingMachine, setWashingMachine] = useState(null)
  const [prepayment, setPrepayment] = useState(null)
  const [publicUtilities, setPublicUtilities] = useState(null)
  const [typeBuilding, setTypeBuilding] = useState(null)
  const [transactionType, setTransactionType] = useState(null)
  const [negotiable, setNegotiable] = useState(null)
  const [breed, setBreed] = useState(null)
  const [productType, setProductType] = useState(null)
  const [yearIssue, setYearIssue] = useState(null)
  const [rul, setRul] = useState(null)
  const [body, setBody] = useState(null)
  const [countDor, setCountDor] = useState(null)
  const [horseW, setHorseW] = useState(null)
  const [engineType, setEngineType] = useState(null)
  const [engineSize, setEngineSize] = useState(null)
  const [transmission, setTransmission] = useState(null)
  const [drive, setDrive] = useState(null)
  const [mileage, setMileage] = useState(null)
  const [stateAuto, setStateAuto] = useState(null)
  const [customsA, setCustomsA] = useState(null)
  const [guarantees, setGuarantees] = useState(null)
  const [pts, setPts] = useState(null)
  const [vin, setVin] = useState(null)
  const [climateControl, setClimateControl] = useState(null)
  const [windowLifters, setWindowLifters] = useState(null)
  const [heatedSeats, setHeatedSeats] = useState(null)
  const [rulR, setRulR] = useState(null)
  const [conditioner, setConditioner] = useState(null)
  const [rulT, setRulT] = useState(null)
  const [tinted, setTinted] = useState(null)
  const [seatRegV, setSeatRegV] = useState(null)
  const [seatRegP, setSeatRegP] = useState(null)
  const [upholstery, setUpholstery] = useState(null)
  const [seatCount, setSeatCount] = useState(null)
  const [luke, setLuke] = useState(null)
  const [steeringWheel, setSteeringWheel] = useState(null)
  const [panoramicRoof, setPanoramicRoof] = useState(null)
  const [centralLock, setCentralLock] = useState(null)
  const [signaling, setSignaling] = useState(null)
  const [immobilizer, setImmobilizer] = useState(null)
  const [alloyWheels, setAlloyWheels] = useState(null)
  const [aeroBody, setAeroBody] = useState(null)
  const [towbar, setTowbar] = useState(null)
  const [winterTires, setWinterTires] = useState(null)
  const [powerSteering, setPowerSteering] = useState(null)
  const [roadAdaptation, setRoadAdaptation] = useState(null)
  const [cruiseControl, setCruiseControl] = useState(null)
  const [headlightsT, setHeadlightsT] = useState(null)
  const [fogLights, setFogLights] = useState(null)
  const [headlightWashers, setHeadlightWashers] = useState(null)
  const [lightSensor, setLightSensor] = useState(null)
  const [electricMirrors, setElectricMirrors] = useState(null)
  const [rainSensor, setRainSensor] = useState(null)
  const [heatedMirrors, setHeatedMirrors] = useState(null)
  const [airbag, setAirbag] = useState(null)
  const [eba, setEba] = useState(null)
  const [abs, setAbs] = useState(null)
  const [esp, setEsp] = useState(null)
  const [asr, setAsr] = useState(null)
  const [boardComputer, setBoardComputer] = useState(null)
  const [gps, setGps] = useState(null)
  const [typeAutoD, setTypeAutoD] = useState(null)
  const [typeAuto, setTypeAuto] = useState(null)
  const [article, setArticle] = useState(null)
  const [diameter, setDiameter] = useState(null)
  const [profession, setProfession] = useState(null)
  const [requiredExperience, setRequiredExperience] = useState(null)
  const [workingHours, setWorkingHours] = useState(null)
  const [employmentType, setEmploymentType] = useState(null)
  const [natureWork, setNatureWork] = useState(null)
  const [otherTerms, setOtherTerms] = useState(null)
  const [company, setCompany] = useState(null)
  const [salary, setSalary] = useState(null)
  const [categoryBusiness, setCategoryBusiness] = useState(null)
  //
  const [album, seAlbum] = useState([])
  const [albumSend, seAlbumSend] = useState([])

  const cleanState = () => {
    setState(null)
    setColor(null)
    setType(null)
    setPrice(0)
    setSize(null)
    setSeason(null)
    setTypeA(null)
    setBrand(null)
    setLength(null)
    setSex(null)
    setSizeHeight(null)
    setWeightGroup(null)
    setFasteningC(null)
    setPeculiaritiesC(null)
    setAdjustmentsC(null)
    setStrollersT(null)
    setStrollersBl(null)
    setStrollersCir(null)
    setOperatingOs(null)
    setBuiltMemory(null)
    setPeriodOfUse(null)
    setDiagonal(null)
    setOzu(null)
    setCamera1(null)
    setCamera2(null)
    setCompatibilityA(null)
    setCompatibilityI(null)
    setMoistureProtection(null)
    setPedometer(null)
    setHeartMete(null)
    setSleepMete(null)
    setPhoneCall(null)
    setAon(null)
    setDevice(null)
    setAmperes(null)
    setUsbCount(null)
    setConnectT(null)
    setCable(null)
    setMaterialCh(null)
    setCategCam(null)
    setViewfinder(null)
    setImageStabilizer(null)
    setVideoQuality(null)
    setTypeSd(null)
    setZoomOp(null)
    setActionCamera(null)
    setR360(null)
    setConType(null)
    setMotionSen(null)
    setMicrophone(null)
    setWifi(null)
    setMountType(null)
    setAutofocus(null)
    setSwivelHead(null)
    setPlayVid(null)
    setSsd(null)
    setVideoTy(null)
    setGame(null)
    setOpticalDrive(null)
    setHdmi(null)
    setSpeakers(null)
    setUsb(null)
    setSmartTV(null)
    setT3d(null)
    setHd(null)
    setHdr(null)
    setSizeDisplay(null)
    setKaraoke(null)
    setAcousticType(null)
    setPortable(null)
    setTouchScreen(null)
    setFm(null)
    setDisplayType(null)
    setDisplayColor(null)
    setBacklight(null)
    setWireless(null)
    setVolumeCh(null)
    setWaterN(null)
    setNoiseReduction(null)
    setConnectors(null)
    setScopeOfApplication(null)
    setSafety(null)
    setTimer(null)
    setInstallationType(null)
    setUtensilCapacity(null)
    setLeakProtection(null)
    setDelayStart(null)
    setTypeCleaning(null)
    setKg(null)
    setSpin(null)
    setDrying(null)
    setLoadType(null)
    setHeight(null)
    setDepth(null)
    setSWidth(null)
    setApplicationArea(null)
    setTypeSport(null)
    setForChild(null)
    setFrame(null)
    setPlatform(null)
    setGenre(null)
    setFormat(null)
    setTheme(null)
    setPriceOf(null)
    setPlaceDelivery(null)
    setTypeService(null)
    setApartmentType(null)
    setYearConstruction(null)
    setHouseType(null)
    setToTheMetro(null)
    setElevators(null)
    setGas(null)
    setInfrastructure(null)
    setRoomsCount(null)
    setFloor(null)
    setFloorH(null)
    setTotalArea(null)
    setLivingArea(null)
    setKitchenArea(null)
    setCeilingHeight(null)
    setRepair(null)
    setBathrooms(null)
    setBalcony(null)
    setWhoPosted(null)
    setMortgagePossible(null)
    setRoomArea(null)
    setMaterHouse(null)
    setRomSlipC(null)
    setElectricity(null)
    setHeating(null)
    setWaterSupply(null)
    setGarage(null)
    setPlotArea(null)
    setPlotType(null)
    setFridge(null)
    setDishwasher(null)
    setWashingMachine(null)
    setPrepayment(null)
    setPublicUtilities(null)
    setTypeBuilding(null)
    setTransactionType(null)
    setNegotiable(null)
    setBreed(null)
    setProductType(null)
    setYearIssue(null)
    setRul(null)
    setBody(null)
    setCountDor(null)
    setHorseW(null)
    setEngineType(null)
    setEngineSize(null)
    setTransmission(null)
    setMileage(null)
    setStateAuto(null)
    setCustomsA(null)
    setGuarantees(null)
    setPts(null)
    setVin(null)
    setClimateControl(null)
    setWindowLifters(null)
    setHeatedSeats(null)
    setRulR(null)
    setConditioner(null)
    setRulT(null)
    setTinted(null)
    setSeatRegV(null)
    setSeatRegP(null)
    setUpholstery(null)
    setSeatCount(null)
    setLuke(null)
    setSteeringWheel(null)
    setPanoramicRoof(null)
    setCentralLock(null)
    setSignaling(null)
    setImmobilizer(null)
    setAlloyWheels(null)
    setAeroBody(null)
    setTowbar(null)
    setWinterTires(null)
    setPowerSteering(null)
    setRoadAdaptation(null)
    setCruiseControl(null)
    setHeadlightsT(null)
    setFogLights(null)
    setHeadlightWashers(null)
    setLightSensor(null)
    setElectricMirrors(null)
    setRainSensor(null)
    setHeatedMirrors(null)
    setAirbag(null)
    setEba(null)
    setAbs(null)
    setEsp(null)
    setAsr(null)
    setBoardComputer(null)
    setGps(null)
    setTypeAutoD(null)
    setTypeAuto(null)
    setArticle(null)
    setDiameter(null)
    setProfession(null)
    setRequiredExperience(null)
    setWorkingHours(null)
    setEmploymentType(null)
    setNatureWork(null)
    setOtherTerms(null)
    setCompany(null)
    setSalary(null)
    setCategoryBusiness(null)
  }

  const submitCreate = () => {
    dispatch(
      actions.productCreateRequest({
        name: name,
        coords: coords,
        description: description,
        state: state?.value || null,
        color: color?.value || null,
        type: type?.value || null,
        country: country,
        price: price,
        size: size?.value || null,
        season: season?.value || null,
        typeA: typeA?.value || null,
        brand: brand?.value || null,
        wifi: wifi?.value || null,
        length: length?.value || null,
        sex: sex?.value || null,
        sizeHeight: sizeHeight?.value || null,
        weightGroup: weightGroup?.value || null,
        fasteningC: fasteningC || null,
        peculiaritiesC: peculiaritiesC || null,
        adjustmentsC: adjustmentsC || null,
        strollersT: strollersT || null,
        strollersBl: strollersBl || null,
        strollersCir: strollersCir?.value || null,
        operatingOs: operatingOs?.value || null,
        builtMemory: builtMemory?.value || null,
        periodOfUse: periodOfUse?.value || null,
        diagonal: diagonal?.value || null,
        ozu: ozu?.value || null,
        camera1: camera1?.value || null,
        camera2: camera2?.value || null,
        compatibilityA: compatibilityA?.value || null,
        compatibilityI: compatibilityI?.value || null,
        moistureProtection: moistureProtection?.value || null,
        pedometer: pedometer?.value || null,
        heartMete: heartMete?.value || null,
        sleepMete: sleepMete?.value || null,
        phoneCall: phoneCall?.value || null,
        autoResp: autoResp?.value || null,
        aon: aon?.value || null,
        device: device?.value || null,
        connectT: connectT?.value || null,
        cable: cable?.value || null,
        amperes: amperes?.value || null,
        usbCount: usbCount?.value || null,
        materialCh: materialCh?.value || null,
        categCam: categCam?.value || null,
        viewfinder: viewfinder?.value || null,
        imageStabilizer: imageStabilizer?.value || null,
        videoQuality: videoQuality?.value || null,
        typeSd: typeSd?.value || null,
        zoomOp: zoomOp?.value || null,
        actionCamera: actionCamera?.value || null,
        r360: r360?.value || null,
        conType: conType?.value || null,
        motionSen: motionSen?.value || null,
        microphone: microphone?.value || null,
        mountType: mountType?.value || null,
        autofocus: autofocus?.value || null,
        swivelHead: swivelHead?.value || null,
        playVid: playVid?.value || null,
        ssd: ssd?.value || null,
        videoTy: videoTy?.value || null,
        game: game?.value || null,
        opticalDrive: opticalDrive?.value || null,
        hdmi: hdmi?.value || null,
        speakers: speakers?.value || null,
        usb: usb?.value || null,
        smartTV: smartTV?.value || null,
        t3d: t3d?.value || null,
        hd: hd?.value || null,
        hdr: hdr?.value || null,
        sizeDisplay: sizeDisplay?.value || null,
        karaoke: karaoke?.value || null,
        acousticType: acousticType?.value || null,
        portable: portable?.value || null,
        touchScreen: touchScreen?.value || null,
        fm: fm?.value || null,
        displayType: displayType?.value || null,
        displayColor: displayColor?.value || null,
        backlight: backlight?.value || null,
        wireless: wireless?.value || null,
        volumeCh: volumeCh?.value || null,
        waterN: waterN?.value || null,
        noiseReduction: noiseReduction?.value || null,
        connectors: connectors?.value || null,
        scopeOfApplication: scopeOfApplication?.value || null,
        safety: safety?.value || null,
        timer: timer?.value || null,
        installationType: installationType?.value || null,
        utensilCapacity: utensilCapacity?.value || null,
        leakProtection: leakProtection?.value || null,
        delayStart: delayStart?.value || null,
        typeCleaning: typeCleaning?.value || null,
        kg: kg?.value || null,
        spin: spin?.value || null,
        drying: drying?.value || null,
        loadType: loadType?.value || null,
        height: height?.value || null,
        depth: depth?.value || null,
        width: swidth?.value || null,
        applicationArea: applicationArea?.value || null,
        typeSport: typeSport?.value || null,
        forChild: forChild?.value || null,
        frame: frame?.value || null,
        platform: platform?.value || null,
        genre: genre?.value || null,
        format: format?.value || null,
        theme: theme?.value || null,
        typeService: typeService?.value || null,
        priceOf: priceOf?.value || null,
        placeDelivery: placeDelivery?.value || null,
        apartmentType: apartmentType?.value || null,
        yearConstruction: yearConstruction,
        houseType: houseType?.value || null,
        toTheMetro: toTheMetro?.value || null,
        elevators: elevators?.value || null,
        gas: gas?.value || null,
        infrastructure: infrastructure || null,
        roomsCount: roomsCount?.value || null,
        floor: floor,
        floorH: floorH,
        totalArea: totalArea,
        livingArea: livingArea,
        kitchenArea: kitchenArea,
        ceilingHeight: ceilingHeight,
        repair: repair?.value || null,
        bathrooms: bathrooms?.value || null,
        balcony: balcony?.value || null,
        whoPosted: whoPosted?.value || null,
        mortgagePossible: mortgagePossible?.value || null,
        roomArea: roomArea,
        materHouse: materHouse,
        romSlipC: romSlipC,
        electricity: electricity?.value || null,
        heating: heating?.value || null,
        waterSupply: waterSupply?.value || null,
        garage: garage?.value || null,
        plotArea: plotArea,
        plotType: plotType?.value || null,
        fridge: fridge?.value || null,
        dishwasher: dishwasher?.value || null,
        washingMachine: washingMachine?.value || null,
        prepayment: prepayment?.value || null,
        publicUtilities: publicUtilities?.value || null,
        typeBuilding: typeBuilding?.value || null,
        transactionType: transactionType?.value || null,
        negotiable: negotiable?.value || null,
        breed: breed?.value || null,
        productType: productType?.value || null,
        yearIssue: yearIssue,
        rul: rul?.value || null,
        body: body?.value || null,
        countDor: countDor,
        horseW: horseW,
        engineType: engineType?.value || null,
        engineSize: engineSize,
        transmission: transmission?.value || null,
        drive: drive?.value || null,
        mileage: mileage,
        stateAuto: stateAuto?.value || null,
        customsA: customsA?.value || null,
        guarantees: guarantees?.value || null,
        pts: pts?.value || null,
        vin: vin?.value || null,
        climateControl: climateControl?.value || null,
        windowLifters: windowLifters?.value || null,
        heatedSeats: heatedSeats?.value || null,
        rulR: rulR?.value || null,
        conditioner: conditioner?.value || null,
        rulT: rulT?.value || null,
        tinted: tinted?.value || null,
        seatRegV: seatRegV?.value || null,
        seatRegP: seatRegP?.value || null,
        upholstery: upholstery?.value || null,
        seatCount: seatCount,
        luke: luke?.value || null,
        steeringWheel: steeringWheel?.value || null,
        panoramicRoof: panoramicRoof?.value || null,
        centralLock: centralLock?.value || null,
        signaling: signaling?.value || null,
        immobilizer: immobilizer?.value || null,
        alloyWheels: alloyWheels?.value || null,
        aeroBody: aeroBody?.value || null,
        towbar: towbar?.value || null,
        winterTires: winterTires?.value || null,
        powerSteering: powerSteering?.value || null,
        roadAdaptation: roadAdaptation?.value || null,
        cruiseControl: cruiseControl?.value || null,
        headlightsT: headlightsT?.value || null,
        fogLights: fogLights?.value || null,
        headlightWashers: headlightWashers?.value || null,
        lightSensor: lightSensor?.value || null,
        electricMirrors: electricMirrors?.value || null,
        rainSensor: rainSensor?.value || null,
        heatedMirrors: heatedMirrors?.value || null,
        airbag: airbag?.value || null,
        eba: eba?.value || null,
        abs: abs?.value || null,
        esp: esp?.value || null,
        asr: asr?.value || null,
        boardComputer: boardComputer?.value || null,
        gps: gps?.value || null,
        typeAutoD: typeAutoD?.value || null,
        typeAuto: typeAuto?.value || null,
        article: article,
        diameter: diameter,
        profession: profession?.value || null,
        requiredExperience: requiredExperience?.value || null,
        workingHours: workingHours?.value || null,
        employmentType: employmentType?.value || null,
        natureWork: natureWork?.value || null,
        otherTerms: otherTerms?.value || null,
        company: company,
        salary: salary?.value || null,
        categoryBusiness: categoryBusiness?.value || null,
        album: albumSend,
        categoryId: categoryId,
        columnId: columnId?.value || null,
        subCategoryId: subCategoryId?.value || null,
      }),
    )
    setSubCategoryId(null)
    setColumnId(null)
    setName(null)
    setDescription(null)
    cleanState()
  }

  const goBack = useCallback(
    (callback) => {
      dispatch(actions.productCreateStage(callback))
      dispatch(actions.categoryId(null))
      setSubCategoryId(null)
      setColumnId(null)
      setName(null)
      setDescription(null)
      cleanState()
    },
    [dispatch],
  )

  useEffect(() => {
    if (coords) {
      ;(async () => {
        const apiKey = '949eee84-97c1-4b8b-b7c1-bbb221de7872'
        const response = await fetch(
          `https://geocode-maps.yandex.ru/1.x/?apikey=${apiKey}&geocode=${coords.longitude},${coords.latitude}&format=json`,
        )
        const data = await response.json()

        if (
          data.response &&
          data.response.GeoObjectCollection &&
          data.response.GeoObjectCollection.featureMember.length > 0
        ) {
          const addressString =
            data.response.GeoObjectCollection.featureMember[0].GeoObject
              .metaDataProperty.GeocoderMetaData.Address.formatted
          setCountry(addressString)
        } else {
          console.error('Error fetching address:', data)
        }
      })()
    }
  }, [coords])

  const handleFiles = (files) => {
    const newImages = Array.from(files).map((file) => URL.createObjectURL(file))
    seAlbum((prev) => [...prev, ...newImages])
    const newImagesSend = Array.from(files)
    seAlbumSend((prev) => [...prev, ...newImagesSend])
  }

  useEffect(() => {
    const handleDrop = (e) => {
      e.preventDefault()
      e.stopPropagation()

      const { files } = e.dataTransfer
      if (files && files.length) {
        handleFiles(files)
      }
    }

    const handleDragOver = (e) => {
      e.preventDefault()
      e.stopPropagation()
    }

    window.addEventListener('dragover', handleDragOver)
    window.addEventListener('drop', handleDrop)

    return () => {
      window.removeEventListener('dragover', handleDragOver)
      window.removeEventListener('drop', handleDrop)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (width >= 991) {
    if (productCategory === 'start') {
      return (
        <main className={styles.startContainer}>
          <div className={styles.columnContainer}>
            <div className={styles.columnInn}>
              <div className={styles.columnInne}>
                <div className={styles.columnInner}>
                  <div className={styles.columnW}>
                    <div className={styles.columnWI}>
                      <h2 className={styles.title}>Выберите категорию</h2>
                    </div>
                    <div className={styles.columnWIn}>
                      {category.map((item, index) => (
                        <CategoryCreatorList
                          category={item}
                          key={index}
                          setColumnId={setColumnId}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )
    } else {
      if (categoryId) {
        const categoryR = category.find((c) => c.id === categoryId)
        const categorySelect = [
          ...category
            .find((c) => c.id === categoryId)
            .columns.map((item) => {
              return {
                value: item.id,
                label: item.name,
              }
            }),
        ]
        const subCate =
          columnId &&
          categoryR.columns.find((i) => i.id === Number(columnId.value)) &&
          categoryR.columns.find((i) => i.id === Number(columnId.value))
            .subCategory
            ? [
                ...categoryR.columns
                  .find((i) => i.id === Number(columnId.value))
                  .subCategory.map((item) => {
                    return {
                      value: item.id,
                      label: item.name,
                    }
                  }),
              ]
            : null
        const d = !columnId || !subCategoryId
        const c = !columnId
        const isDisabled = subCate ? d : c
        return (
          <>
            {categoryR && (
              <main className={styles.startContainer}>
                <div className={styles.columnContainer}>
                  <div className={styles.columnInn}>
                    <div className={styles.columnInne}>
                      <div className={styles.columnInner}>
                        <div className={styles.columnWs}>
                          <div className={styles.columnWI}>
                            <div
                              className={styles.goBackD}
                              onClick={() => {
                                goBack('start')
                              }}
                            >
                              <svg baseProfile="basic" viewBox="0 0 24 24">
                                <path
                                  fill="#393939"
                                  d="M22.5 11.4H3.4l9.1-9.7-.7-.6L1.5 11.9l10.3 11 .7-.7-9.2-9.8h19.2z"
                                ></path>
                              </svg>
                            </div>
                            <h2 className={styles.title}>{categoryR.name}</h2>
                          </div>
                          <div className={styles.rowCreatorD}>
                            <div className={styles.columnInnCol1}>
                              <div
                                className={`${styles.flexCreatorIn} ${styles.flexCreatorInC}`}
                              >
                                <div className={styles.labelW}>Категория</div>
                                <div className={styles.labelS}>
                                  <div className={styles.selectTorContainer}>
                                    <Select
                                      className={styles.select}
                                      value={columnId}
                                      placeholder={'Выберите категорию'}
                                      onChange={(newValue) => {
                                        setColumnId(newValue)
                                        setSubCategoryId(null)
                                        cleanState()
                                      }}
                                      options={categorySelect}
                                      isClearable
                                      isSearchable
                                    />
                                    {subCate && (
                                      <Select
                                        className={styles.select}
                                        value={subCategoryId}
                                        placeholder={'Выберите подкатегорию'}
                                        onChange={(newValue) => {
                                          setSubCategoryId(newValue)
                                          cleanState()
                                        }}
                                        options={subCate}
                                        isClearable
                                        isSearchable
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <SelectVariant
                                variant={productCategory}
                                columnId={columnId}
                                subCategoryId={subCategoryId}
                                setTypeA={setTypeA}
                                typeA={typeA}
                                brand={brand}
                                setBrand={setBrand}
                                state={state}
                                setState={setState}
                                color={color}
                                setColor={setColor}
                                type={type}
                                setType={setType}
                                size={size}
                                setSize={setSize}
                                season={season}
                                setSeason={setSeason}
                                length={length}
                                setLength={setLength}
                                sex={sex}
                                setSex={setSex}
                                sizeHeight={sizeHeight}
                                setSizeHeight={setSizeHeight}
                                weightGroup={weightGroup}
                                setWeightGroup={setWeightGroup}
                                fasteningC={fasteningC}
                                setFasteningC={setFasteningC}
                                peculiaritiesC={peculiaritiesC}
                                setPeculiaritiesC={setPeculiaritiesC}
                                adjustmentsC={adjustmentsC}
                                setAdjustmentsC={setAdjustmentsC}
                                strollersT={strollersT}
                                setStrollersT={setStrollersT}
                                strollersBl={strollersBl}
                                setStrollersBl={setStrollersBl}
                                strollersCir={strollersCir}
                                setStrollersCir={setStrollersCir}
                                operatingOs={operatingOs}
                                setOperatingOs={setOperatingOs}
                                builtMemory={builtMemory}
                                setBuiltMemory={setBuiltMemory}
                                periodOfUse={periodOfUse}
                                setPeriodOfUse={setPeriodOfUse}
                                diagonal={diagonal}
                                setDiagonal={setDiagonal}
                                ozu={ozu}
                                setOzu={setOzu}
                                camera1={camera1}
                                setCamera1={setCamera1}
                                camera2={camera2}
                                setCamera2={setCamera2}
                                compatibilityA={compatibilityA}
                                setCompatibilityA={setCompatibilityA}
                                compatibilityI={compatibilityI}
                                setCompatibilityI={setCompatibilityI}
                                moistureProtection={moistureProtection}
                                setMoistureProtection={setMoistureProtection}
                                pedometer={pedometer}
                                setPedometer={setPedometer}
                                heartMete={heartMete}
                                setHeartMete={setHeartMete}
                                sleepMete={sleepMete}
                                setSleepMete={setSleepMete}
                                phoneCall={phoneCall}
                                setPhoneCall={setPhoneCall}
                                autoResp={autoResp}
                                setAutoResp={setAutoResp}
                                aon={aon}
                                setAon={setAon}
                                device={device}
                                setDevice={setDevice}
                                amperes={amperes}
                                setAmperes={setAmperes}
                                usbCount={usbCount}
                                setUsbCount={setUsbCount}
                                connectT={connectT}
                                setConnectT={setConnectT}
                                cable={cable}
                                setCable={setCable}
                                materialCh={materialCh}
                                setMaterialCh={setMaterialCh}
                                categCam={categCam}
                                setCategCam={setCategCam}
                                viewfinder={viewfinder}
                                setViewfinder={setViewfinder}
                                imageStabilizer={imageStabilizer}
                                setImageStabilizer={setImageStabilizer}
                                videoQuality={videoQuality}
                                setVideoQuality={setVideoQuality}
                                typeSd={typeSd}
                                setTypeSd={setTypeSd}
                                zoomOp={zoomOp}
                                setZoomOp={setZoomOp}
                                actionCamera={actionCamera}
                                setActionCamera={setActionCamera}
                                r360={r360}
                                setR360={setR360}
                                conType={conType}
                                setConType={setConType}
                                wifi={wifi}
                                setWifi={setWifi}
                                motionSen={motionSen}
                                setMotionSen={setMotionSen}
                                microphone={microphone}
                                setMicrophone={setMicrophone}
                                mountType={mountType}
                                setMountType={setMountType}
                                autofocus={autofocus}
                                setAutofocus={setAutofocus}
                                swivelHead={swivelHead}
                                setSwivelHead={setSwivelHead}
                                playVid={playVid}
                                setPlayVid={setPlayVid}
                                ssd={ssd}
                                setSsd={setSsd}
                                videoTy={videoTy}
                                setVideoTy={setVideoTy}
                                game={game}
                                setGame={setGame}
                                opticalDrive={opticalDrive}
                                setOpticalDrive={setOpticalDrive}
                                hdmi={hdmi}
                                setHdmi={setHdmi}
                                speakers={speakers}
                                setSpeakers={setSpeakers}
                                usb={usb}
                                setUsb={setUsb}
                                smartTV={smartTV}
                                setSmartTV={setSmartTV}
                                t3d={t3d}
                                setT3d={setT3d}
                                hd={hd}
                                setHd={setHd}
                                sizeDisplay={sizeDisplay}
                                setSizeDisplay={setSizeDisplay}
                                hdr={hdr}
                                setHdr={setHdr}
                                karaoke={karaoke}
                                setKaraoke={setKaraoke}
                                acousticType={acousticType}
                                setAcousticType={setAcousticType}
                                portable={portable}
                                setPortable={setPortable}
                                fm={fm}
                                setFm={setFm}
                                touchScreen={touchScreen}
                                setTouchScreen={setTouchScreen}
                                displayType={displayType}
                                setDisplayType={setDisplayType}
                                displayColor={displayColor}
                                setDisplayColor={setDisplayColor}
                                backlight={backlight}
                                setBacklight={setBacklight}
                                wireless={wireless}
                                setWireless={setWireless}
                                volumeCh={volumeCh}
                                setVolumeCh={setVolumeCh}
                                waterN={waterN}
                                setWaterN={setWaterN}
                                noiseReduction={noiseReduction}
                                setNoiseReduction={setNoiseReduction}
                                scopeOfApplication={scopeOfApplication}
                                setScopeOfApplication={setScopeOfApplication}
                                safety={safety}
                                setSafety={setSafety}
                                timer={timer}
                                setTimer={setTimer}
                                installationType={installationType}
                                setInstallationType={setInstallationType}
                                utensilCapacity={utensilCapacity}
                                setUtensilCapacity={setUtensilCapacity}
                                leakProtection={leakProtection}
                                setLeakProtection={setLeakProtection}
                                delayStart={delayStart}
                                setDelayStart={setDelayStart}
                                typeCleaning={typeCleaning}
                                setTypeCleaning={setTypeCleaning}
                                kg={kg}
                                setKg={setKg}
                                spin={spin}
                                setSpin={setSpin}
                                drying={drying}
                                setDrying={setDrying}
                                loadType={loadType}
                                setLoadType={setLoadType}
                                height={height}
                                setHeight={setHeight}
                                depth={depth}
                                setDepth={setDepth}
                                swidth={swidth}
                                setSWidth={setSWidth}
                                applicationArea={applicationArea}
                                setApplicationArea={setApplicationArea}
                                forChild={forChild}
                                setForChild={setForChild}
                                typeSport={typeSport}
                                setTypeSport={setTypeSport}
                                frame={frame}
                                setFrame={setFrame}
                                platform={platform}
                                setPlatform={setPlatform}
                                genre={genre}
                                setGenre={setGenre}
                                theme={theme}
                                setTheme={setTheme}
                                priceOf={priceOf}
                                setPriceOf={setPriceOf}
                                placeDelivery={placeDelivery}
                                setPlaceDelivery={setPlaceDelivery}
                                typeService={typeService}
                                setTypeService={setTypeService}
                                apartmentType={apartmentType}
                                setApartmentType={setApartmentType}
                                yearConstruction={yearConstruction}
                                setYearConstruction={setYearConstruction}
                                houseType={houseType}
                                setHouseType={setHouseType}
                                toTheMetro={toTheMetro}
                                setToTheMetro={setToTheMetro}
                                elevators={elevators}
                                setElevators={setElevators}
                                gas={gas}
                                setGas={setGas}
                                infrastructure={infrastructure}
                                setInfrastructure={setInfrastructure}
                                roomArea={roomArea}
                                setRoomArea={setRoomArea}
                                roomsCount={roomsCount}
                                setRoomsCount={setRoomsCount}
                                floor={floor}
                                setFloor={setFloor}
                                floorH={floorH}
                                setFloorH={setFloorH}
                                totalArea={totalArea}
                                setTotalArea={setTotalArea}
                                livingArea={livingArea}
                                setLivingArea={setLivingArea}
                                kitchenArea={kitchenArea}
                                setKitchenArea={setKitchenArea}
                                ceilingHeight={ceilingHeight}
                                setCeilingHeight={setCeilingHeight}
                                repair={repair}
                                setRepair={setRepair}
                                bathrooms={bathrooms}
                                setBathrooms={setBathrooms}
                                balcony={balcony}
                                setBalcony={setBalcony}
                                whoPosted={whoPosted}
                                setWhoPosted={setWhoPosted}
                                mortgagePossible={mortgagePossible}
                                setMortgagePossible={setMortgagePossible}
                                materHouse={materHouse}
                                setMaterHouse={setMaterHouse}
                                romSlipC={romSlipC}
                                setRomSlipC={setRomSlipC}
                                electricity={electricity}
                                setElectricity={setElectricity}
                                heating={heating}
                                setHeating={setHeating}
                                waterSupply={waterSupply}
                                setWaterSupply={setWaterSupply}
                                garage={garage}
                                setGarage={setGarage}
                                plotArea={plotArea}
                                setPlotArea={setPlotArea}
                                plotType={plotType}
                                setPlotType={setPlotType}
                                fridge={fridge}
                                setFridge={setFridge}
                                dishwasher={dishwasher}
                                setDishwasher={setDishwasher}
                                washingMachine={washingMachine}
                                setWashingMachine={setWashingMachine}
                                prepayment={prepayment}
                                setPrepayment={setPrepayment}
                                publicUtilities={publicUtilities}
                                setPublicUtilities={setPublicUtilities}
                                typeBuilding={typeBuilding}
                                setTypeBuilding={setTypeBuilding}
                                transactionType={transactionType}
                                setTransactionType={setTransactionType}
                                negotiable={negotiable}
                                setNegotiable={setNegotiable}
                                breed={breed}
                                setBreed={setBreed}
                                productType={productType}
                                setProductType={setProductType}
                                //
                                yearIssue={yearIssue}
                                setYearIssue={setYearIssue}
                                rul={rul}
                                setRul={setRul}
                                body={body}
                                setBody={setBody}
                                countDor={countDor}
                                setCountDor={setCountDor}
                                horseW={horseW}
                                setHorseW={setHorseW}
                                engineType={engineType}
                                setEngineType={setEngineType}
                                engineSize={engineSize}
                                setEngineSize={setEngineSize}
                                transmission={transmission}
                                setTransmission={setTransmission}
                                drive={drive}
                                setDrive={setDrive}
                                mileage={mileage}
                                setMileage={setMileage}
                                stateAuto={stateAuto}
                                setStateAuto={setStateAuto}
                                customsA={customsA}
                                setCustomsA={setCustomsA}
                                guarantees={guarantees}
                                setGuarantees={setGuarantees}
                                pts={pts}
                                setPts={setPts}
                                vin={vin}
                                setVin={setVin}
                                climateControl={climateControl}
                                setClimateControl={setClimateControl}
                                windowLifters={windowLifters}
                                setWindowLifters={setWindowLifters}
                                heatedSeats={heatedSeats}
                                setHeatedSeats={setHeatedSeats}
                                rulR={rulR}
                                setRulR={setRulR}
                                conditioner={conditioner}
                                setConditioner={setConditioner}
                                rulT={rulT}
                                setRulT={setRulT}
                                tinted={tinted}
                                setTinted={setTinted}
                                seatRegV={seatRegV}
                                setSeatRegV={setSeatRegV}
                                seatRegP={seatRegP}
                                setSeatRegP={setSeatRegP}
                                upholstery={upholstery}
                                setUpholstery={setUpholstery}
                                seatCount={seatCount}
                                setSeatCount={setSeatCount}
                                luke={luke}
                                setLuke={setLuke}
                                steeringWheel={steeringWheel}
                                setSteeringWheel={setSteeringWheel}
                                panoramicRoof={panoramicRoof}
                                setPanoramicRoof={setPanoramicRoof}
                                centralLock={centralLock}
                                setCentralLock={setCentralLock}
                                signaling={signaling}
                                setSignaling={setSignaling}
                                immobilizer={immobilizer}
                                setImmobilizer={setImmobilizer}
                                alloyWheels={alloyWheels}
                                setAlloyWheels={setAlloyWheels}
                                aeroBody={aeroBody}
                                setAeroBody={setAeroBody}
                                towbar={towbar}
                                setTowbar={setTowbar}
                                winterTires={winterTires}
                                setWinterTires={setWinterTires}
                                powerSteering={powerSteering}
                                setPowerSteering={setPowerSteering}
                                roadAdaptation={roadAdaptation}
                                setRoadAdaptation={setRoadAdaptation}
                                cruiseControl={cruiseControl}
                                setCruiseControl={setCruiseControl}
                                headlightsT={headlightsT}
                                setHeadlightsT={setHeadlightsT}
                                fogLights={fogLights}
                                setFogLights={setFogLights}
                                headlightWashers={headlightWashers}
                                setHeadlightWashers={setHeadlightWashers}
                                lightSensor={lightSensor}
                                setLightSensor={setLightSensor}
                                electricMirrors={electricMirrors}
                                setElectricMirrors={setElectricMirrors}
                                rainSensor={rainSensor}
                                setRainSensor={setRainSensor}
                                heatedMirrors={heatedMirrors}
                                setHeatedMirrors={setHeatedMirrors}
                                airbag={airbag}
                                setAirbag={setAirbag}
                                eba={eba}
                                setEba={setEba}
                                abs={abs}
                                setAbs={setAbs}
                                esp={esp}
                                setEsp={setEsp}
                                asr={asr}
                                setAsr={setAsr}
                                boardComputer={boardComputer}
                                setBoardComputer={setBoardComputer}
                                gps={gps}
                                setGps={setGps}
                                typeAutoD={typeAutoD}
                                setTypeAutoD={setTypeAutoD}
                                typeAuto={typeAuto}
                                setTypeAuto={setTypeAuto}
                                article={article}
                                setArticle={setArticle}
                                diameter={diameter}
                                setDiameter={setDiameter}
                                profession={profession}
                                setProfession={setProfession}
                                requiredExperience={requiredExperience}
                                setRequiredExperience={setRequiredExperience}
                                workingHours={workingHours}
                                setWorkingHours={setWorkingHours}
                                employmentType={employmentType}
                                setEmploymentType={setEmploymentType}
                                natureWork={natureWork}
                                setNatureWork={setNatureWork}
                                otherTerms={otherTerms}
                                setOtherTerms={setOtherTerms}
                                company={company}
                                setCompany={setCompany}
                                salary={salary}
                                setSalary={setSalary}
                                categoryBusiness={categoryBusiness}
                                setCategoryBusiness={setCategoryBusiness}
                              />
                              <div
                                className={
                                  isDisabled
                                    ? `${styles.flexCreatorIn} ${styles.flexCreatorInC} ${styles.disabled}`
                                    : `${styles.flexCreatorIn} ${styles.flexCreatorInC}`
                                }
                              >
                                <div className={styles.labelW}>
                                  Название товара
                                </div>
                                <div className={styles.labelS}>
                                  <input
                                    value={name || ''}
                                    required
                                    onChange={(e) => setName(e.target.value)}
                                    className={styles.inputName}
                                  />
                                </div>
                              </div>
                              <div
                                className={
                                  isDisabled
                                    ? `${styles.flexCreatorIn} ${styles.flexCreatorInC} ${styles.disabled}`
                                    : `${styles.flexCreatorIn} ${styles.flexCreatorInC}`
                                }
                              >
                                <div className={styles.labelW}>Цена</div>
                                <div className={styles.labelS}>
                                  <div className={styles.inputPriceC}>
                                    <input
                                      value={price}
                                      onChange={(e) => setPrice(e.target.value)}
                                      className={styles.inputPrice}
                                    />
                                    <div>₽</div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  isDisabled
                                    ? `${styles.flexCreatorIn} ${styles.flexCreatorInS} ${styles.disabled}`
                                    : `${styles.flexCreatorIn} ${styles.flexCreatorInS}`
                                }
                              >
                                <div className={styles.labelW}>Описание</div>
                                <div className={styles.labelS}>
                                  <textarea
                                    value={description || ''}
                                    aria-multiline={true}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                    className={styles.inputDesc}
                                  />
                                </div>
                              </div>
                              <div
                                className={
                                  isDisabled
                                    ? `${styles.flexCreatorIn} ${styles.flexCreatorInS} ${styles.disabled}`
                                    : `${styles.flexCreatorIn} ${styles.flexCreatorInS}`
                                }
                              >
                                <div className={styles.labelW}>Фотографии</div>
                                <div className={styles.labelS}>
                                  <div className={styles.uploadPhotoCotainer}>
                                    <ul className={styles.uploaderContainer}>
                                      {album.length > 0 && (
                                        <>
                                          {album.map((item, index) => (
                                            <li
                                              key={index}
                                              className={styles.itemContainer}
                                            >
                                              <div
                                                className={styles.itemInnerD}
                                              >
                                                <div
                                                  className={styles.itemInputC}
                                                >
                                                  <div
                                                    className={
                                                      styles.placeHolderI
                                                    }
                                                  >
                                                    <img src={item} alt={''} />
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          ))}
                                        </>
                                      )}
                                      {Array.from({
                                        length:
                                          album.length < 4
                                            ? 4 - album.length
                                            : 1,
                                      }).map((_, index) => (
                                        <li
                                          key={index}
                                          className={styles.itemContainer}
                                        >
                                          <div className={styles.itemInnerD}>
                                            <div className={styles.itemInputC}>
                                              <input
                                                hidden
                                                className={
                                                  'attachment_dropzone--file'
                                                }
                                                multiple
                                                accept="image/jpeg, image/png"
                                                type={'file'}
                                                onChange={(e) =>
                                                  handleFiles(e.target.files)
                                                }
                                              />
                                              <div
                                                onClick={() => {
                                                  if (!isDisabled) {
                                                    document
                                                      .querySelector(
                                                        '.attachment_dropzone--file',
                                                      )
                                                      .click()
                                                  }
                                                }}
                                                className={styles.placeHolderI}
                                              >
                                                <div
                                                  className={
                                                    styles.placeHolderIn
                                                  }
                                                >
                                                  <i className={styles.iconEl}>
                                                    <svg viewBox="0 0 24 24">
                                                      <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="3.2"
                                                      ></circle>
                                                      <path d="M9 2 7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5"></path>
                                                    </svg>
                                                  </i>
                                                  <div
                                                    className={styles.photoL}
                                                  >
                                                    Добавить
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  isDisabled
                                    ? `${styles.flexCreatorIn} ${styles.flexCreatorInS} ${styles.disabled}`
                                    : `${styles.flexCreatorIn} ${styles.flexCreatorInS}`
                                }
                              >
                                <div className={styles.labelW}>
                                  Местоположение
                                </div>
                                <div className={styles.labelS}>
                                  <input
                                    value={country || ''}
                                    required
                                    className={styles.inpuGeo}
                                    disabled
                                  />
                                  {coords && (
                                    <MapYandex
                                      coords={coords}
                                      width={570}
                                      height={300}
                                      ymapsRef={ymapsRef}
                                      isControls={true}
                                    />
                                  )}
                                </div>
                              </div>
                              <div
                                className={
                                  isDisabled
                                    ? `${styles.flexCreatorIn} ${styles.flexCreatorInS} ${styles.disabled}`
                                    : `${styles.flexCreatorIn} ${styles.flexCreatorInS}`
                                }
                              >
                                <div className={styles.labelW}></div>
                                <div className={styles.labelS}>
                                  <button
                                    onClick={submitCreate}
                                    className={styles.submitCreate}
                                  >
                                    Опубликовать
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className={styles.columnInnCol2}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            )}
          </>
        )
      }
    }
  } else {
    switch (productCategory) {
      case 'start':
        return (
          <main className={styles.startContainer}>
            <div className={styles.columnContainerM}>
              <header className={styles.headerM}></header>
              <div className={styles.selectCategoryIn}>
                <ul className={styles.listCategory}></ul>
              </div>
            </div>
          </main>
        )
      default:
        break
    }
  }
}

export default ProductCreate
