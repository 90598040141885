import React from 'react'
import styles from './index.module.scss'

function FormAuthContainer({ children }) {
  return (
    <>
      <div className={styles.formLeftDecoration} />
      <div className={styles.formRightDecoration} />
      <div className={styles.circle} />
      <div className={styles.formInner}>{children}</div>
    </>
  )
}

export default FormAuthContainer
