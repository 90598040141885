import React from 'react'
import styles from '../index.module.scss'
import SelectComponent from '../../SelectComponent'
import InputForm from '../../InputForm'

function SellingAnApartment({
  filtersAll,
  apartmentType,
  setApartmentType,
  yearConstruction,
  setYearConstruction,
  houseType,
  setHouseType,
  toTheMetro,
  setToTheMetro,
  elevators,
  setElevators,
  gas,
  setGas,
  infrastructure,
  setInfrastructure,
  roomsCount,
  setRoomsCount,
  floor,
  setFloor,
  floorH,
  setFloorH,
  totalArea,
  setTotalArea,
  livingArea,
  setLivingArea,
  kitchenArea,
  setKitchenArea,
  ceilingHeight,
  setCeilingHeight,
  repair,
  setRepair,
  bathrooms,
  setBathrooms,
  balcony,
  setBalcony,
  periodOfUse,
  setPeriodOfUse,
  whoPosted,
  setWhoPosted,
  mortgagePossible,
  setMortgagePossible,
}) {
  return (
    <>
      <div className={styles.border} />
      <div className={styles.title}>Параметры дома</div>
      <div className={styles.titleL}>Основные</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={apartmentType}
        setValue={setApartmentType}
        center={true}
        filter={'setApartmentType'}
        label={'Тип квартиры'}
      />
      <InputForm
        label={'Год постройки'}
        value={yearConstruction}
        setValue={setYearConstruction}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={houseType}
        setValue={setHouseType}
        center={true}
        filter={'houseType'}
        label={'Тип дома'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={toTheMetro}
        setValue={setToTheMetro}
        center={true}
        filter={'toTheMetro'}
        label={'До метро'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={elevators}
        setValue={setElevators}
        center={true}
        filter={'elevators'}
        label={'Лифты'}
      />
      <div className={styles.titleL}>Дополнительные</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={gas}
        setValue={setGas}
        center={true}
        filter={'gamePc'}
        label={'Газ'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={infrastructure}
        setValue={setInfrastructure}
        center={true}
        isMulti={true}
        filter={'infrastructure'}
        label={'Инфраструктура'}
      />
      <div className={styles.border} />
      <div className={styles.title}>Параметры квартиры</div>
      <div className={styles.titleL}>Основные</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={roomsCount}
        setValue={setRoomsCount}
        center={true}
        filter={'roomsCount'}
        label={'Комнат в квартире'}
      />
      <InputForm label={'Этаж'} value={floor} setValue={setFloor} />
      <InputForm label={'Этажность дома'} value={floorH} setValue={setFloorH} />
      <div className={styles.titleL}>Площадь</div>
      <InputForm
        label={'Общая площадь'}
        subLabel={'м²'}
        value={totalArea}
        setValue={setTotalArea}
      />
      <InputForm
        label={'Жилая площадь'}
        subLabel={'м²'}
        value={livingArea}
        setValue={setLivingArea}
      />
      <InputForm
        label={'Площадь кухни'}
        subLabel={'м²'}
        value={kitchenArea}
        setValue={setKitchenArea}
      />
      <div className={styles.titleL}>Дополнительные</div>
      <InputForm
        label={'Высота потолка'}
        subLabel={'м'}
        value={ceilingHeight}
        setValue={setCeilingHeight}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={repair}
        setValue={setRepair}
        center={true}
        filter={'repair'}
        label={'Ремонт'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={bathrooms}
        setValue={setBathrooms}
        center={true}
        filter={'bathrooms'}
        label={'Санузлы'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={balcony}
        setValue={setBalcony}
        center={true}
        filter={'balcony'}
        label={'Балкон'}
      />
      <div className={styles.border} />
      <div className={styles.title}>Условия</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={periodOfUse}
        setValue={setPeriodOfUse}
        center={true}
        filter={'periodOfUseN'}
        label={'Срок владения'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={whoPosted}
        setValue={setWhoPosted}
        center={true}
        filter={'whoPosted'}
        label={'Кто разместил'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={mortgagePossible}
        setValue={setMortgagePossible}
        center={true}
        filter={'gamePc'}
        label={'Возможна ипотека'}
      />
      <div className={styles.border} />
    </>
  )
}

export default SellingAnApartment
