import React from 'react'
import SelectComponent from '../../SelectComponent'

function ApplicationsForServices({
  filtersAll,
  columnId,
  typeService,
  setTypeService,
}) {
  const isDisabled = columnId && columnId.value !== 48
  return (
    <>
      {columnId && columnId.value ? (
        <>
          {isDisabled && (
            <SelectComponent
              filtersAll={filtersAll}
              value={typeService}
              setValue={setTypeService}
              center={true}
              filter={columnId.label}
              label={'Вид услуг'}
            />
          )}
        </>
      ) : (
        <>
          <></>
        </>
      )}
    </>
  )
}
export default ApplicationsForServices
