import React from 'react'
import SellingAnApartment from '../SellingAnApartment'
import SellingARoom from '../SellingARoom'
import SellingAHouse from '../SellingAHouse'
import SaleOfLand from '../SaleOfLand'
import RentingAnApartmentForALongTime from '../RentingAnApartmentForALongTime'
import CommercialRealEstate from '../CommercialRealEstate'
import OtherBuildings from '../OtherBuildings'

function RealEstate({
  filtersAll,
  columnId,
  subCategoryId,
  apartmentType,
  setApartmentType,
  yearConstruction,
  setYearConstruction,
  houseType,
  setHouseType,
  toTheMetro,
  setToTheMetro,
  elevators,
  setElevators,
  gas,
  setGas,
  infrastructure,
  setInfrastructure,
  roomArea,
  setRoomArea,
  roomsCount,
  setRoomsCount,
  floor,
  setFloor,
  floorH,
  setFloorH,
  totalArea,
  setTotalArea,
  livingArea,
  setLivingArea,
  kitchenArea,
  setKitchenArea,
  ceilingHeight,
  setCeilingHeight,
  repair,
  setRepair,
  bathrooms,
  setBathrooms,
  balcony,
  setBalcony,
  periodOfUse,
  setPeriodOfUse,
  whoPosted,
  setWhoPosted,
  mortgagePossible,
  setMortgagePossible,
  materHouse,
  setMaterHouse,
  romSlipC,
  setRomSlipC,
  electricity,
  setElectricity,
  heating,
  setHeating,
  waterSupply,
  setWaterSupply,
  garage,
  setGarage,
  plotArea,
  setPlotArea,
  plotType,
  setPlotType,
  fridge,
  setFridge,
  dishwasher,
  setDishwasher,
  washingMachine,
  setWashingMachine,
  prepayment,
  setPrepayment,
  publicUtilities,
  setPublicUtilities,
  typeBuilding,
  setTypeBuilding,
  transactionType,
  setTransactionType,
  negotiable,
  setNegotiable,
}) {
  switch (columnId) {
    case 49:
      return (
        <>
          <SellingAnApartment
            filtersAll={filtersAll}
            apartmentType={apartmentType}
            setApartmentType={setApartmentType}
            yearConstruction={yearConstruction}
            setYearConstruction={setYearConstruction}
            houseType={houseType}
            setHouseType={setHouseType}
            toTheMetro={toTheMetro}
            setToTheMetro={setToTheMetro}
            elevators={elevators}
            setElevators={setElevators}
            gas={gas}
            setGas={setGas}
            infrastructure={infrastructure}
            setInfrastructure={setInfrastructure}
            roomsCount={roomsCount}
            setRoomsCount={setRoomsCount}
            floor={floor}
            setFloor={setFloor}
            floorH={floorH}
            setFloorH={setFloorH}
            totalArea={totalArea}
            setTotalArea={setTotalArea}
            livingArea={livingArea}
            setLivingArea={setLivingArea}
            kitchenArea={kitchenArea}
            setKitchenArea={setKitchenArea}
            ceilingHeight={ceilingHeight}
            setCeilingHeight={setCeilingHeight}
            repair={repair}
            setRepair={setRepair}
            bathrooms={bathrooms}
            setBathrooms={setBathrooms}
            balcony={balcony}
            setBalcony={setBalcony}
            periodOfUse={periodOfUse}
            setPeriodOfUse={setPeriodOfUse}
            whoPosted={whoPosted}
            setWhoPosted={setWhoPosted}
            mortgagePossible={mortgagePossible}
            setMortgagePossible={setMortgagePossible}
            fridge={fridge}
            setFridge={setFridge}
            dishwasher={dishwasher}
            setDishwasher={setDishwasher}
            washingMachine={washingMachine}
            setWashingMachine={setWashingMachine}
            prepayment={prepayment}
            setPrepayment={setPrepayment}
            publicUtilities={publicUtilities}
            setPublicUtilities={setPublicUtilities}
            typeBuilding={typeBuilding}
            setTypeBuilding={setTypeBuilding}
            transactionType={transactionType}
            setTransactionType={setTransactionType}
            negotiable={negotiable}
            setNegotiable={setNegotiable}
          />
        </>
      )
    case 50:
      return (
        <>
          <SellingARoom
            filtersAll={filtersAll}
            apartmentType={apartmentType}
            setApartmentType={setApartmentType}
            yearConstruction={yearConstruction}
            setYearConstruction={setYearConstruction}
            houseType={houseType}
            setHouseType={setHouseType}
            toTheMetro={toTheMetro}
            setToTheMetro={setToTheMetro}
            elevators={elevators}
            setElevators={setElevators}
            gas={gas}
            setGas={setGas}
            infrastructure={infrastructure}
            setInfrastructure={setInfrastructure}
            roomArea={roomArea}
            setRoomArea={setRoomArea}
            roomsCount={roomsCount}
            setRoomsCount={setRoomsCount}
            floor={floor}
            setFloor={setFloor}
            floorH={floorH}
            setFloorH={setFloorH}
            totalArea={totalArea}
            setTotalArea={setTotalArea}
            livingArea={livingArea}
            setLivingArea={setLivingArea}
            kitchenArea={kitchenArea}
            setKitchenArea={setKitchenArea}
            ceilingHeight={ceilingHeight}
            setCeilingHeight={setCeilingHeight}
            repair={repair}
            setRepair={setRepair}
            bathrooms={bathrooms}
            setBathrooms={setBathrooms}
            balcony={balcony}
            setBalcony={setBalcony}
            periodOfUse={periodOfUse}
            setPeriodOfUse={setPeriodOfUse}
            whoPosted={whoPosted}
            setWhoPosted={setWhoPosted}
            mortgagePossible={mortgagePossible}
            setMortgagePossible={setMortgagePossible}
            fridge={fridge}
            setFridge={setFridge}
            dishwasher={dishwasher}
            setDishwasher={setDishwasher}
            washingMachine={washingMachine}
            setWashingMachine={setWashingMachine}
            prepayment={prepayment}
            setPrepayment={setPrepayment}
            publicUtilities={publicUtilities}
            setPublicUtilities={setPublicUtilities}
            typeBuilding={typeBuilding}
            setTypeBuilding={setTypeBuilding}
            transactionType={transactionType}
            setTransactionType={setTransactionType}
            negotiable={negotiable}
            setNegotiable={setNegotiable}
          />
        </>
      )
    case 51:
      return (
        <>
          <SellingAHouse
            filtersAll={filtersAll}
            apartmentType={apartmentType}
            setApartmentType={setApartmentType}
            yearConstruction={yearConstruction}
            setYearConstruction={setYearConstruction}
            houseType={houseType}
            setHouseType={setHouseType}
            toTheMetro={toTheMetro}
            setToTheMetro={setToTheMetro}
            elevators={elevators}
            setElevators={setElevators}
            gas={gas}
            setGas={setGas}
            infrastructure={infrastructure}
            setInfrastructure={setInfrastructure}
            roomArea={roomArea}
            setRoomArea={setRoomArea}
            roomsCount={roomsCount}
            setRoomsCount={setRoomsCount}
            floor={floor}
            setFloor={setFloor}
            floorH={floorH}
            setFloorH={setFloorH}
            totalArea={totalArea}
            setTotalArea={setTotalArea}
            livingArea={livingArea}
            setLivingArea={setLivingArea}
            kitchenArea={kitchenArea}
            setKitchenArea={setKitchenArea}
            ceilingHeight={ceilingHeight}
            setCeilingHeight={setCeilingHeight}
            repair={repair}
            setRepair={setRepair}
            bathrooms={bathrooms}
            setBathrooms={setBathrooms}
            balcony={balcony}
            setBalcony={setBalcony}
            periodOfUse={periodOfUse}
            setPeriodOfUse={setPeriodOfUse}
            whoPosted={whoPosted}
            setWhoPosted={setWhoPosted}
            mortgagePossible={mortgagePossible}
            setMortgagePossible={setMortgagePossible}
            materHouse={materHouse}
            setMaterHouse={setMaterHouse}
            romSlipC={romSlipC}
            setRomSlipC={setRomSlipC}
            electricity={electricity}
            setElectricity={setElectricity}
            heating={heating}
            setHeating={setHeating}
            waterSupply={waterSupply}
            setWaterSupply={setWaterSupply}
            garage={garage}
            setGarage={setGarage}
            plotArea={plotArea}
            setPlotArea={setPlotArea}
            plotType={plotType}
            setPlotType={setPlotType}
            fridge={fridge}
            setFridge={setFridge}
            dishwasher={dishwasher}
            setDishwasher={setDishwasher}
            washingMachine={washingMachine}
            setWashingMachine={setWashingMachine}
            prepayment={prepayment}
            setPrepayment={setPrepayment}
            publicUtilities={publicUtilities}
            setPublicUtilities={setPublicUtilities}
            typeBuilding={typeBuilding}
            setTypeBuilding={setTypeBuilding}
            transactionType={transactionType}
            setTransactionType={setTransactionType}
            negotiable={negotiable}
            setNegotiable={setNegotiable}
          />
        </>
      )
    case 52:
      return (
        <>
          <SaleOfLand
            filtersAll={filtersAll}
            apartmentType={apartmentType}
            setApartmentType={setApartmentType}
            yearConstruction={yearConstruction}
            setYearConstruction={setYearConstruction}
            houseType={houseType}
            setHouseType={setHouseType}
            toTheMetro={toTheMetro}
            setToTheMetro={setToTheMetro}
            elevators={elevators}
            setElevators={setElevators}
            gas={gas}
            setGas={setGas}
            infrastructure={infrastructure}
            setInfrastructure={setInfrastructure}
            roomArea={roomArea}
            setRoomArea={setRoomArea}
            roomsCount={roomsCount}
            setRoomsCount={setRoomsCount}
            floor={floor}
            setFloor={setFloor}
            floorH={floorH}
            setFloorH={setFloorH}
            totalArea={totalArea}
            setTotalArea={setTotalArea}
            livingArea={livingArea}
            setLivingArea={setLivingArea}
            kitchenArea={kitchenArea}
            setKitchenArea={setKitchenArea}
            ceilingHeight={ceilingHeight}
            setCeilingHeight={setCeilingHeight}
            repair={repair}
            setRepair={setRepair}
            bathrooms={bathrooms}
            setBathrooms={setBathrooms}
            balcony={balcony}
            setBalcony={setBalcony}
            periodOfUse={periodOfUse}
            setPeriodOfUse={setPeriodOfUse}
            whoPosted={whoPosted}
            setWhoPosted={setWhoPosted}
            mortgagePossible={mortgagePossible}
            setMortgagePossible={setMortgagePossible}
            materHouse={materHouse}
            setMaterHouse={setMaterHouse}
            romSlipC={romSlipC}
            setRomSlipC={setRomSlipC}
            electricity={electricity}
            setElectricity={setElectricity}
            heating={heating}
            setHeating={setHeating}
            waterSupply={waterSupply}
            setWaterSupply={setWaterSupply}
            garage={garage}
            setGarage={setGarage}
            plotArea={plotArea}
            setPlotArea={setPlotArea}
            plotType={plotType}
            setPlotType={setPlotType}
            fridge={fridge}
            setFridge={setFridge}
            dishwasher={dishwasher}
            setDishwasher={setDishwasher}
            washingMachine={washingMachine}
            setWashingMachine={setWashingMachine}
            prepayment={prepayment}
            setPrepayment={setPrepayment}
            publicUtilities={publicUtilities}
            setPublicUtilities={setPublicUtilities}
            typeBuilding={typeBuilding}
            setTypeBuilding={setTypeBuilding}
            transactionType={transactionType}
            setTransactionType={setTransactionType}
            negotiable={negotiable}
            setNegotiable={setNegotiable}
          />
        </>
      )
    case 53:
      return (
        <>
          {subCategoryId && subCategoryId.value && (
            <RentingAnApartmentForALongTime
              filtersAll={filtersAll}
              columnId={columnId}
              apartmentType={apartmentType}
              setApartmentType={setApartmentType}
              yearConstruction={yearConstruction}
              setYearConstruction={setYearConstruction}
              houseType={houseType}
              setHouseType={setHouseType}
              toTheMetro={toTheMetro}
              setToTheMetro={setToTheMetro}
              elevators={elevators}
              setElevators={setElevators}
              gas={gas}
              setGas={setGas}
              infrastructure={infrastructure}
              setInfrastructure={setInfrastructure}
              roomArea={roomArea}
              setRoomArea={setRoomArea}
              roomsCount={roomsCount}
              setRoomsCount={setRoomsCount}
              floor={floor}
              setFloor={setFloor}
              floorH={floorH}
              setFloorH={setFloorH}
              totalArea={totalArea}
              setTotalArea={setTotalArea}
              livingArea={livingArea}
              setLivingArea={setLivingArea}
              kitchenArea={kitchenArea}
              setKitchenArea={setKitchenArea}
              ceilingHeight={ceilingHeight}
              setCeilingHeight={setCeilingHeight}
              repair={repair}
              setRepair={setRepair}
              bathrooms={bathrooms}
              setBathrooms={setBathrooms}
              balcony={balcony}
              setBalcony={setBalcony}
              periodOfUse={periodOfUse}
              setPeriodOfUse={setPeriodOfUse}
              whoPosted={whoPosted}
              setWhoPosted={setWhoPosted}
              mortgagePossible={mortgagePossible}
              setMortgagePossible={setMortgagePossible}
              materHouse={materHouse}
              setMaterHouse={setMaterHouse}
              romSlipC={romSlipC}
              setRomSlipC={setRomSlipC}
              electricity={electricity}
              setElectricity={setElectricity}
              heating={heating}
              setHeating={setHeating}
              waterSupply={waterSupply}
              setWaterSupply={setWaterSupply}
              garage={garage}
              setGarage={setGarage}
              plotArea={plotArea}
              setPlotArea={setPlotArea}
              plotType={plotType}
              setPlotType={setPlotType}
              fridge={fridge}
              setFridge={setFridge}
              dishwasher={dishwasher}
              setDishwasher={setDishwasher}
              washingMachine={washingMachine}
              setWashingMachine={setWashingMachine}
              prepayment={prepayment}
              setPrepayment={setPrepayment}
              publicUtilities={publicUtilities}
              setPublicUtilities={setPublicUtilities}
              typeBuilding={typeBuilding}
              setTypeBuilding={setTypeBuilding}
              transactionType={transactionType}
              setTransactionType={setTransactionType}
              negotiable={negotiable}
              setNegotiable={setNegotiable}
            />
          )}
        </>
      )
    case 54:
      return (
        <>
          <RentingAnApartmentForALongTime
            filtersAll={filtersAll}
            columnId={columnId}
            apartmentType={apartmentType}
            setApartmentType={setApartmentType}
            yearConstruction={yearConstruction}
            setYearConstruction={setYearConstruction}
            houseType={houseType}
            setHouseType={setHouseType}
            toTheMetro={toTheMetro}
            setToTheMetro={setToTheMetro}
            elevators={elevators}
            setElevators={setElevators}
            gas={gas}
            setGas={setGas}
            infrastructure={infrastructure}
            setInfrastructure={setInfrastructure}
            roomArea={roomArea}
            setRoomArea={setRoomArea}
            roomsCount={roomsCount}
            setRoomsCount={setRoomsCount}
            floor={floor}
            setFloor={setFloor}
            floorH={floorH}
            setFloorH={setFloorH}
            totalArea={totalArea}
            setTotalArea={setTotalArea}
            livingArea={livingArea}
            setLivingArea={setLivingArea}
            kitchenArea={kitchenArea}
            setKitchenArea={setKitchenArea}
            ceilingHeight={ceilingHeight}
            setCeilingHeight={setCeilingHeight}
            repair={repair}
            setRepair={setRepair}
            bathrooms={bathrooms}
            setBathrooms={setBathrooms}
            balcony={balcony}
            setBalcony={setBalcony}
            periodOfUse={periodOfUse}
            setPeriodOfUse={setPeriodOfUse}
            whoPosted={whoPosted}
            setWhoPosted={setWhoPosted}
            mortgagePossible={mortgagePossible}
            setMortgagePossible={setMortgagePossible}
            materHouse={materHouse}
            setMaterHouse={setMaterHouse}
            romSlipC={romSlipC}
            setRomSlipC={setRomSlipC}
            electricity={electricity}
            setElectricity={setElectricity}
            heating={heating}
            setHeating={setHeating}
            waterSupply={waterSupply}
            setWaterSupply={setWaterSupply}
            garage={garage}
            setGarage={setGarage}
            plotArea={plotArea}
            setPlotArea={setPlotArea}
            plotType={plotType}
            setPlotType={setPlotType}
            fridge={fridge}
            setFridge={setFridge}
            dishwasher={dishwasher}
            setDishwasher={setDishwasher}
            washingMachine={washingMachine}
            setWashingMachine={setWashingMachine}
            prepayment={prepayment}
            setPrepayment={setPrepayment}
            publicUtilities={publicUtilities}
            setPublicUtilities={setPublicUtilities}
            typeBuilding={typeBuilding}
            setTypeBuilding={setTypeBuilding}
            transactionType={transactionType}
            setTransactionType={setTransactionType}
            negotiable={negotiable}
            setNegotiable={setNegotiable}
          />
        </>
      )
    case 55:
      return (
        <>
          <SellingAHouse
            filtersAll={filtersAll}
            columnId={columnId}
            apartmentType={apartmentType}
            setApartmentType={setApartmentType}
            yearConstruction={yearConstruction}
            setYearConstruction={setYearConstruction}
            houseType={houseType}
            setHouseType={setHouseType}
            toTheMetro={toTheMetro}
            setToTheMetro={setToTheMetro}
            elevators={elevators}
            setElevators={setElevators}
            gas={gas}
            setGas={setGas}
            infrastructure={infrastructure}
            setInfrastructure={setInfrastructure}
            roomArea={roomArea}
            setRoomArea={setRoomArea}
            roomsCount={roomsCount}
            setRoomsCount={setRoomsCount}
            floor={floor}
            setFloor={setFloor}
            floorH={floorH}
            setFloorH={setFloorH}
            totalArea={totalArea}
            setTotalArea={setTotalArea}
            livingArea={livingArea}
            setLivingArea={setLivingArea}
            kitchenArea={kitchenArea}
            setKitchenArea={setKitchenArea}
            ceilingHeight={ceilingHeight}
            setCeilingHeight={setCeilingHeight}
            repair={repair}
            setRepair={setRepair}
            bathrooms={bathrooms}
            setBathrooms={setBathrooms}
            balcony={balcony}
            setBalcony={setBalcony}
            periodOfUse={periodOfUse}
            setPeriodOfUse={setPeriodOfUse}
            whoPosted={whoPosted}
            setWhoPosted={setWhoPosted}
            mortgagePossible={mortgagePossible}
            setMortgagePossible={setMortgagePossible}
            materHouse={materHouse}
            setMaterHouse={setMaterHouse}
            romSlipC={romSlipC}
            setRomSlipC={setRomSlipC}
            electricity={electricity}
            setElectricity={setElectricity}
            heating={heating}
            setHeating={setHeating}
            waterSupply={waterSupply}
            setWaterSupply={setWaterSupply}
            garage={garage}
            setGarage={setGarage}
            plotArea={plotArea}
            setPlotArea={setPlotArea}
            plotType={plotType}
            setPlotType={setPlotType}
            fridge={fridge}
            setFridge={setFridge}
            dishwasher={dishwasher}
            setDishwasher={setDishwasher}
            washingMachine={washingMachine}
            setWashingMachine={setWashingMachine}
            prepayment={prepayment}
            setPrepayment={setPrepayment}
            publicUtilities={publicUtilities}
            setPublicUtilities={setPublicUtilities}
            typeBuilding={typeBuilding}
            setTypeBuilding={setTypeBuilding}
            transactionType={transactionType}
            setTransactionType={setTransactionType}
            negotiable={negotiable}
            setNegotiable={setNegotiable}
          />
        </>
      )
    case 56:
      return (
        <>
          <RentingAnApartmentForALongTime
            filtersAll={filtersAll}
            columnId={columnId}
            apartmentType={apartmentType}
            setApartmentType={setApartmentType}
            yearConstruction={yearConstruction}
            setYearConstruction={setYearConstruction}
            houseType={houseType}
            setHouseType={setHouseType}
            toTheMetro={toTheMetro}
            setToTheMetro={setToTheMetro}
            elevators={elevators}
            setElevators={setElevators}
            gas={gas}
            setGas={setGas}
            infrastructure={infrastructure}
            setInfrastructure={setInfrastructure}
            roomArea={roomArea}
            setRoomArea={setRoomArea}
            roomsCount={roomsCount}
            setRoomsCount={setRoomsCount}
            floor={floor}
            setFloor={setFloor}
            floorH={floorH}
            setFloorH={setFloorH}
            totalArea={totalArea}
            setTotalArea={setTotalArea}
            livingArea={livingArea}
            setLivingArea={setLivingArea}
            kitchenArea={kitchenArea}
            setKitchenArea={setKitchenArea}
            ceilingHeight={ceilingHeight}
            setCeilingHeight={setCeilingHeight}
            repair={repair}
            setRepair={setRepair}
            bathrooms={bathrooms}
            setBathrooms={setBathrooms}
            balcony={balcony}
            setBalcony={setBalcony}
            periodOfUse={periodOfUse}
            setPeriodOfUse={setPeriodOfUse}
            whoPosted={whoPosted}
            setWhoPosted={setWhoPosted}
            mortgagePossible={mortgagePossible}
            setMortgagePossible={setMortgagePossible}
            materHouse={materHouse}
            setMaterHouse={setMaterHouse}
            romSlipC={romSlipC}
            setRomSlipC={setRomSlipC}
            electricity={electricity}
            setElectricity={setElectricity}
            heating={heating}
            setHeating={setHeating}
            waterSupply={waterSupply}
            setWaterSupply={setWaterSupply}
            garage={garage}
            setGarage={setGarage}
            plotArea={plotArea}
            setPlotArea={setPlotArea}
            plotType={plotType}
            setPlotType={setPlotType}
            fridge={fridge}
            setFridge={setFridge}
            dishwasher={dishwasher}
            setDishwasher={setDishwasher}
            washingMachine={washingMachine}
            setWashingMachine={setWashingMachine}
            prepayment={prepayment}
            setPrepayment={setPrepayment}
            publicUtilities={publicUtilities}
            setPublicUtilities={setPublicUtilities}
            typeBuilding={typeBuilding}
            setTypeBuilding={setTypeBuilding}
            transactionType={transactionType}
            setTransactionType={setTransactionType}
            negotiable={negotiable}
            setNegotiable={setNegotiable}
          />
        </>
      )
    case 57:
      return (
        <>
          <RentingAnApartmentForALongTime
            filtersAll={filtersAll}
            columnId={columnId}
            apartmentType={apartmentType}
            setApartmentType={setApartmentType}
            yearConstruction={yearConstruction}
            setYearConstruction={setYearConstruction}
            houseType={houseType}
            setHouseType={setHouseType}
            toTheMetro={toTheMetro}
            setToTheMetro={setToTheMetro}
            elevators={elevators}
            setElevators={setElevators}
            gas={gas}
            setGas={setGas}
            infrastructure={infrastructure}
            setInfrastructure={setInfrastructure}
            roomArea={roomArea}
            setRoomArea={setRoomArea}
            roomsCount={roomsCount}
            setRoomsCount={setRoomsCount}
            floor={floor}
            setFloor={setFloor}
            floorH={floorH}
            setFloorH={setFloorH}
            totalArea={totalArea}
            setTotalArea={setTotalArea}
            livingArea={livingArea}
            setLivingArea={setLivingArea}
            kitchenArea={kitchenArea}
            setKitchenArea={setKitchenArea}
            ceilingHeight={ceilingHeight}
            setCeilingHeight={setCeilingHeight}
            repair={repair}
            setRepair={setRepair}
            bathrooms={bathrooms}
            setBathrooms={setBathrooms}
            balcony={balcony}
            setBalcony={setBalcony}
            periodOfUse={periodOfUse}
            setPeriodOfUse={setPeriodOfUse}
            whoPosted={whoPosted}
            setWhoPosted={setWhoPosted}
            mortgagePossible={mortgagePossible}
            setMortgagePossible={setMortgagePossible}
            materHouse={materHouse}
            setMaterHouse={setMaterHouse}
            romSlipC={romSlipC}
            setRomSlipC={setRomSlipC}
            electricity={electricity}
            setElectricity={setElectricity}
            heating={heating}
            setHeating={setHeating}
            waterSupply={waterSupply}
            setWaterSupply={setWaterSupply}
            garage={garage}
            setGarage={setGarage}
            plotArea={plotArea}
            setPlotArea={setPlotArea}
            plotType={plotType}
            setPlotType={setPlotType}
            fridge={fridge}
            setFridge={setFridge}
            dishwasher={dishwasher}
            setDishwasher={setDishwasher}
            washingMachine={washingMachine}
            setWashingMachine={setWashingMachine}
            prepayment={prepayment}
            setPrepayment={setPrepayment}
            publicUtilities={publicUtilities}
            setPublicUtilities={setPublicUtilities}
            typeBuilding={typeBuilding}
            setTypeBuilding={setTypeBuilding}
            transactionType={transactionType}
            setTransactionType={setTransactionType}
            negotiable={negotiable}
            setNegotiable={setNegotiable}
          />
        </>
      )
    case 58:
      return (
        <>
          <SellingAHouse
            filtersAll={filtersAll}
            columnId={columnId}
            apartmentType={apartmentType}
            setApartmentType={setApartmentType}
            yearConstruction={yearConstruction}
            setYearConstruction={setYearConstruction}
            houseType={houseType}
            setHouseType={setHouseType}
            toTheMetro={toTheMetro}
            setToTheMetro={setToTheMetro}
            elevators={elevators}
            setElevators={setElevators}
            gas={gas}
            setGas={setGas}
            infrastructure={infrastructure}
            setInfrastructure={setInfrastructure}
            roomArea={roomArea}
            setRoomArea={setRoomArea}
            roomsCount={roomsCount}
            setRoomsCount={setRoomsCount}
            floor={floor}
            setFloor={setFloor}
            floorH={floorH}
            setFloorH={setFloorH}
            totalArea={totalArea}
            setTotalArea={setTotalArea}
            livingArea={livingArea}
            setLivingArea={setLivingArea}
            kitchenArea={kitchenArea}
            setKitchenArea={setKitchenArea}
            ceilingHeight={ceilingHeight}
            setCeilingHeight={setCeilingHeight}
            repair={repair}
            setRepair={setRepair}
            bathrooms={bathrooms}
            setBathrooms={setBathrooms}
            balcony={balcony}
            setBalcony={setBalcony}
            periodOfUse={periodOfUse}
            setPeriodOfUse={setPeriodOfUse}
            whoPosted={whoPosted}
            setWhoPosted={setWhoPosted}
            mortgagePossible={mortgagePossible}
            setMortgagePossible={setMortgagePossible}
            materHouse={materHouse}
            setMaterHouse={setMaterHouse}
            romSlipC={romSlipC}
            setRomSlipC={setRomSlipC}
            electricity={electricity}
            setElectricity={setElectricity}
            heating={heating}
            setHeating={setHeating}
            waterSupply={waterSupply}
            setWaterSupply={setWaterSupply}
            garage={garage}
            setGarage={setGarage}
            plotArea={plotArea}
            setPlotArea={setPlotArea}
            plotType={plotType}
            setPlotType={setPlotType}
            fridge={fridge}
            setFridge={setFridge}
            dishwasher={dishwasher}
            setDishwasher={setDishwasher}
            washingMachine={washingMachine}
            setWashingMachine={setWashingMachine}
            prepayment={prepayment}
            setPrepayment={setPrepayment}
            publicUtilities={publicUtilities}
            setPublicUtilities={setPublicUtilities}
            typeBuilding={typeBuilding}
            setTypeBuilding={setTypeBuilding}
            transactionType={transactionType}
            setTransactionType={setTransactionType}
            negotiable={negotiable}
            setNegotiable={setNegotiable}
          />
        </>
      )
    case 59:
      return (
        <>
          <CommercialRealEstate
            filtersAll={filtersAll}
            columnId={columnId}
            apartmentType={apartmentType}
            setApartmentType={setApartmentType}
            yearConstruction={yearConstruction}
            setYearConstruction={setYearConstruction}
            houseType={houseType}
            setHouseType={setHouseType}
            toTheMetro={toTheMetro}
            setToTheMetro={setToTheMetro}
            elevators={elevators}
            setElevators={setElevators}
            gas={gas}
            setGas={setGas}
            infrastructure={infrastructure}
            setInfrastructure={setInfrastructure}
            roomArea={roomArea}
            setRoomArea={setRoomArea}
            roomsCount={roomsCount}
            setRoomsCount={setRoomsCount}
            floor={floor}
            setFloor={setFloor}
            floorH={floorH}
            setFloorH={setFloorH}
            totalArea={totalArea}
            setTotalArea={setTotalArea}
            livingArea={livingArea}
            setLivingArea={setLivingArea}
            kitchenArea={kitchenArea}
            setKitchenArea={setKitchenArea}
            ceilingHeight={ceilingHeight}
            setCeilingHeight={setCeilingHeight}
            repair={repair}
            setRepair={setRepair}
            bathrooms={bathrooms}
            setBathrooms={setBathrooms}
            balcony={balcony}
            setBalcony={setBalcony}
            periodOfUse={periodOfUse}
            setPeriodOfUse={setPeriodOfUse}
            whoPosted={whoPosted}
            setWhoPosted={setWhoPosted}
            mortgagePossible={mortgagePossible}
            setMortgagePossible={setMortgagePossible}
            materHouse={materHouse}
            setMaterHouse={setMaterHouse}
            romSlipC={romSlipC}
            setRomSlipC={setRomSlipC}
            electricity={electricity}
            setElectricity={setElectricity}
            heating={heating}
            setHeating={setHeating}
            waterSupply={waterSupply}
            setWaterSupply={setWaterSupply}
            garage={garage}
            setGarage={setGarage}
            plotArea={plotArea}
            setPlotArea={setPlotArea}
            plotType={plotType}
            setPlotType={setPlotType}
            fridge={fridge}
            setFridge={setFridge}
            dishwasher={dishwasher}
            setDishwasher={setDishwasher}
            washingMachine={washingMachine}
            setWashingMachine={setWashingMachine}
            prepayment={prepayment}
            setPrepayment={setPrepayment}
            publicUtilities={publicUtilities}
            setPublicUtilities={setPublicUtilities}
            typeBuilding={typeBuilding}
            setTypeBuilding={setTypeBuilding}
            transactionType={transactionType}
            setTransactionType={setTransactionType}
            negotiable={negotiable}
            setNegotiable={setNegotiable}
          />
        </>
      )
    case 60:
      return (
        <>
          <OtherBuildings
            filtersAll={filtersAll}
            columnId={columnId}
            apartmentType={apartmentType}
            setApartmentType={setApartmentType}
            yearConstruction={yearConstruction}
            setYearConstruction={setYearConstruction}
            houseType={houseType}
            setHouseType={setHouseType}
            toTheMetro={toTheMetro}
            setToTheMetro={setToTheMetro}
            elevators={elevators}
            setElevators={setElevators}
            gas={gas}
            setGas={setGas}
            infrastructure={infrastructure}
            setInfrastructure={setInfrastructure}
            roomArea={roomArea}
            setRoomArea={setRoomArea}
            roomsCount={roomsCount}
            setRoomsCount={setRoomsCount}
            floor={floor}
            setFloor={setFloor}
            floorH={floorH}
            setFloorH={setFloorH}
            totalArea={totalArea}
            setTotalArea={setTotalArea}
            livingArea={livingArea}
            setLivingArea={setLivingArea}
            kitchenArea={kitchenArea}
            setKitchenArea={setKitchenArea}
            ceilingHeight={ceilingHeight}
            setCeilingHeight={setCeilingHeight}
            repair={repair}
            setRepair={setRepair}
            bathrooms={bathrooms}
            setBathrooms={setBathrooms}
            balcony={balcony}
            setBalcony={setBalcony}
            periodOfUse={periodOfUse}
            setPeriodOfUse={setPeriodOfUse}
            whoPosted={whoPosted}
            setWhoPosted={setWhoPosted}
            mortgagePossible={mortgagePossible}
            setMortgagePossible={setMortgagePossible}
            materHouse={materHouse}
            setMaterHouse={setMaterHouse}
            romSlipC={romSlipC}
            setRomSlipC={setRomSlipC}
            electricity={electricity}
            setElectricity={setElectricity}
            heating={heating}
            setHeating={setHeating}
            waterSupply={waterSupply}
            setWaterSupply={setWaterSupply}
            garage={garage}
            setGarage={setGarage}
            plotArea={plotArea}
            setPlotArea={setPlotArea}
            plotType={plotType}
            setPlotType={setPlotType}
            fridge={fridge}
            setFridge={setFridge}
            dishwasher={dishwasher}
            setDishwasher={setDishwasher}
            washingMachine={washingMachine}
            setWashingMachine={setWashingMachine}
            prepayment={prepayment}
            setPrepayment={setPrepayment}
            publicUtilities={publicUtilities}
            setPublicUtilities={setPublicUtilities}
            typeBuilding={typeBuilding}
            setTypeBuilding={setTypeBuilding}
            transactionType={transactionType}
            setTransactionType={setTransactionType}
            negotiable={negotiable}
            setNegotiable={setNegotiable}
          />
        </>
      )
    default:
      return <></>
  }
}
export default RealEstate
