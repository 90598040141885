// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectVariant_border__lVKz8 {
  border-top: 1px solid rgb(235, 235, 235);
  margin-bottom: 30px;
}

.SelectVariant_title__OJi\\+G {
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 30px;
}

.SelectVariant_titleL__xwNlj {
  color: rgb(57, 57, 57);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/SelectVariant/index.module.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EACA,mBAAA;AACF;;AACA;EACE,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;AAEF;;AAAA;EACE,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AAGF","sourcesContent":[".border{\r\n  border-top: 1px solid rgb(235, 235, 235);\r\n  margin-bottom: 30px;\r\n}\r\n.title{\r\n  font-family: \"Fira Sans\", sans-serif;\r\n  font-weight: 500;\r\n  font-size: 20px;\r\n  margin-bottom: 30px;\r\n}\r\n.titleL{\r\n  color: rgb(57, 57, 57);\r\n  font-size: 14px;\r\n  font-weight: 600;\r\n  margin-bottom: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"border": `SelectVariant_border__lVKz8`,
	"title": `SelectVariant_title__OJi+G`,
	"titleL": `SelectVariant_titleL__xwNlj`
};
export default ___CSS_LOADER_EXPORT___;
