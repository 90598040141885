import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Parallax, Navigation } from 'swiper/modules'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import { getCategoryUrl } from '../../api'

function SwiperList({ swiperAdvertising }) {
  const [width, setWidth] = useState(window.innerWidth <= 600)
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth <= 600)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return (
    <Swiper
      autoplay
      loop
      slidesPerView={width ? 0.7 : 2.9}
      speed={500}
      navigation={true}
      parallax={true}
      modules={[Parallax, Autoplay, Navigation]}
      className={styles.swiper}
    >
      {swiperAdvertising.map((item, index) => (
        <SwiperSlide key={`${item.id}_${index}`} className={styles.slide}>
          <Link to={item.swiperRoute}>
            <img src={getCategoryUrl(item.image)} alt={''} />
            <div className={styles.textContainer}>
              <div className={styles.slideName}>{item.name}</div>
              <div className={styles.subName}>{item.subName}</div>
            </div>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default SwiperList
