import React, { useEffect, useRef } from 'react'
import {
  Map,
  Placemark,
  YMaps,
  GeolocationControl,
  SearchControl,
} from '@pbe/react-yandex-maps'
import * as actions from '../../actions'
import { useDispatch } from 'react-redux'
function MapYandex({ coords, ymapsRef, width, height, isControls = false }) {
  const dispatch = useDispatch()
  const ref = useRef()
  const ref2 = useRef()
  useEffect(() => {
    if (ref.current && coords && isControls) {
      ref.current.geometry.setCoordinates([coords.latitude, coords.longitude])
    }
  }, [coords, ref.current])

  return (
    <YMaps
      query={{
        lang: 'ru_RU',
        apikey: '949eee84-97c1-4b8b-b7c1-bbb221de7872',
        load: 'package.full',
        suggest_apikey: 'ac31a2b5-85a2-4e27-b2ad-e0781ec178f8',
      }}
      version={'2.1'}
    >
      <Map
        instanceRef={ymapsRef}
        width={width}
        height={height}
        style={{
          borderWidth: 2,
          borderColor: '#0d6efd4a',
          width: width,
          height: height,
          // overflow: 'hidden',
        }}
        state={{
          center: [Number(coords.latitude), Number(coords.longitude)],
          zoom: 15,
          controls: [],
        }}
        onLoad={(e) => {
          ymapsRef.current = e
        }}
        onClick={(event) => {
          if (isControls) {
            const coords = event.get('coords')
            dispatch(
              actions.toggleCoords({
                latitude: coords[0],
                longitude: coords[1],
              }),
            )
          }
        }}
      >
        {coords && (
          <Placemark
            instanceRef={ref}
            height={20}
            width={20}
            geometry={[Number(coords.latitude), Number(coords.longitude)]}
            options={{
              iconImageSize: [30, 30],
              draggable: true,
              preset: 'islands#greenIcon',
              hideIconOnBalloonOpen: false,
              openEmptyHint: true,
            }}
            onDragEnd={() => {
              if (isControls) {
                const coords = ref.current.geometry._coordinates
                dispatch(
                  actions.toggleCoords({
                    latitude: coords[0],
                    longitude: coords[1],
                  }),
                )
              }
            }}
          />
        )}
        {isControls && (
          <>
            <SearchControl
              instanceRef={ref2}
              options={{ float: 'left' }}
              onResultSelect={(e) => {
                const results = e.get('target').getResultsArray()
                if (results && results[0] && results[0].geometry) {
                  const coords = results[0].geometry._coordinates
                  dispatch(
                    actions.toggleCoords({
                      latitude: coords[0],
                      longitude: coords[1],
                    }),
                  )
                }
              }}
            />
            <GeolocationControl
              options={{ float: 'left' }}
              onLocationChange={(e) => {
                const coords = e.get('position')
                dispatch(
                  actions.toggleCoords({
                    latitude: coords[0],
                    longitude: coords[1],
                  }),
                )
              }}
            />
          </>
        )}
      </Map>
    </YMaps>
  )
}

export default MapYandex
