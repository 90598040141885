import React from 'react'
import { Link } from 'react-router-dom'
import { getCategoryUrl } from '../../api'

function SelectCategoryList({ item, route, className }) {
  return (
    <Link to={route}>
      <img src={getCategoryUrl(item.image)} alt={''} />
      <div className={className}>{item.name}</div>
    </Link>
  )
}
export default SelectCategoryList
