import React from 'react'
import styles from '../index.module.scss'
import SelectComponent from '../../SelectComponent'
import InputForm from '../../InputForm'

function PassengerCars({
  filtersAll,
  brand,
  setBrand,
  yearIssue,
  setYearIssue,
  rul,
  setRul,
  body,
  setBody,
  countDor,
  setCountDor,
  color,
  setColor,
  horseW,
  setHorseW,
  engineType,
  setEngineType,
  engineSize,
  setEngineSize,
  transmission,
  setTransmission,
  drive,
  setDrive,
  mileage,
  setMileage,
  stateAuto,
  setStateAuto,
  customsA,
  setCustomsA,
  guarantees,
  setGuarantees,
  pts,
  setPts,
  vin,
  setVin,
  climateControl,
  setClimateControl,
  windowLifters,
  setWindowLifters,
  heatedSeats,
  setHeatedSeats,
  rulR,
  setRulR,
  conditioner,
  setConditioner,
  rulT,
  setRulT,
  tinted,
  setTinted,
  seatRegV,
  setSeatRegV,
  seatRegP,
  setSeatRegP,
  upholstery,
  setUpholstery,
  seatCount,
  setSeatCount,
  luke,
  setLuke,
  steeringWheel,
  setSteeringWheel,
  panoramicRoof,
  setPanoramicRoof,
  centralLock,
  setCentralLock,
  signaling,
  setSignaling,
  immobilizer,
  setImmobilizer,
  alloyWheels,
  setAlloyWheels,
  aeroBody,
  setAeroBody,
  towbar,
  setTowbar,
  winterTires,
  setWinterTires,
  powerSteering,
  setPowerSteering,
  roadAdaptation,
  setRoadAdaptation,
  cruiseControl,
  setCruiseControl,
  headlightsT,
  setHeadlightsT,
  fogLights,
  setFogLights,
  headlightWashers,
  setHeadlightWashers,
  lightSensor,
  setLightSensor,
  electricMirrors,
  setElectricMirrors,
  rainSensor,
  setRainSensor,
  heatedMirrors,
  setHeatedMirrors,
  airbag,
  setAirbag,
  eba,
  setEba,
  abs,
  setAbs,
  esp,
  setEsp,
  asr,
  setAsr,
  boardComputer,
  setBoardComputer,
  gps,
  setGps,
  opticalDrive,
  setOpticalDrive,
}) {
  return (
    <>
      <div className={styles.title}>Основные параметры</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={brand}
        setValue={setBrand}
        center={true}
        filter={'brandCar'}
        label={'Марка'}
      />
      <InputForm
        label={'Год выпуска'}
        value={yearIssue}
        setValue={setYearIssue}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={rul}
        setValue={setRul}
        center={true}
        filter={'rul'}
        label={'Руль'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={body}
        setValue={setBody}
        center={true}
        filter={'bodyCar'}
        label={'Кузов'}
      />
      <InputForm
        label={'Количество дверей'}
        value={countDor}
        setValue={setCountDor}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={color}
        setValue={setColor}
        center={true}
        filter={'colorProduct'}
        label={'Цвет'}
      />
      <div className={styles.title}>Технические параметры</div>
      <InputForm
        label={'Мощность'}
        value={horseW}
        setValue={setHorseW}
        subLabel={'л.с.'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={engineType}
        setValue={setEngineType}
        center={true}
        filter={'engineType'}
        label={'Тип двигателя'}
      />
      <InputForm
        label={'Объем двигателя'}
        value={engineSize}
        setValue={setEngineSize}
        subLabel={'л'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={transmission}
        setValue={setTransmission}
        center={true}
        filter={'transmission'}
        label={'Коробка передач'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={drive}
        setValue={setDrive}
        center={true}
        filter={'drive'}
        label={'Привод'}
      />
      <div className={styles.title}>Состояние и цена</div>
      <InputForm
        label={'Пробег'}
        value={mileage}
        setValue={setMileage}
        subLabel={'км'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={stateAuto}
        setValue={setStateAuto}
        center={true}
        filter={'stateAuto'}
        label={'Состояние автомобиля'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={customsA}
        setValue={setCustomsA}
        center={true}
        filter={'gamePc'}
        label={'Pастаможен'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={guarantees}
        setValue={setGuarantees}
        center={true}
        filter={'gamePc'}
        label={'Автомобиль на гарантии'}
      />
      <InputForm
        label={'Количество владельцев по ПТС'}
        value={pts}
        setValue={setPts}
      />
      <InputForm label={'VIN'} value={vin} setValue={setVin} />
      <div className={styles.title}>Опции</div>
      <div className={styles.title}>Комфорт</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={climateControl}
        setValue={setClimateControl}
        center={true}
        filter={'climateControl'}
        label={'Климат-контроль'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={windowLifters}
        setValue={setWindowLifters}
        center={true}
        filter={'windowLifters'}
        label={'Электрические стеклоподъемники'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={heatedSeats}
        setValue={setHeatedSeats}
        center={true}
        filter={'heatedSeats'}
        label={'Обогрев сидений'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={rulR}
        setValue={setRulR}
        center={true}
        filter={'rulR'}
        label={'Регулировка руля'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={conditioner}
        setValue={setConditioner}
        center={true}
        filter={'gamePc'}
        label={'Кондиционер'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={rulT}
        setValue={setRulT}
        center={true}
        filter={'gamePc'}
        label={'Обогрев рулевого колеса'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={tinted}
        setValue={setTinted}
        center={true}
        filter={'gamePc'}
        label={'Тонированные стекла'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={seatRegV}
        setValue={setSeatRegV}
        center={true}
        filter={'seatReg'}
        label={'Регулировка сиденья водителя'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={seatRegP}
        setValue={setSeatRegP}
        center={true}
        filter={'seatReg'}
        label={'Регулировка сиденья пассажира'}
      />
      <div className={styles.title}>Комфорт</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={upholstery}
        setValue={setUpholstery}
        center={true}
        filter={'upholstery'}
        label={'Обивка салона'}
      />
      <InputForm
        label={'Количество мест'}
        value={seatCount}
        setValue={setSeatCount}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={luke}
        setValue={setLuke}
        center={true}
        filter={'luke'}
        label={'Люк'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={steeringWheel}
        setValue={setSteeringWheel}
        center={true}
        filter={'gamePc'}
        label={'Кожаный руль'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={panoramicRoof}
        setValue={setPanoramicRoof}
        center={true}
        filter={'gamePc'}
        label={'Панорамная крыша'}
      />
      <div className={styles.title}>Охранные системы</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={centralLock}
        setValue={setCentralLock}
        center={true}
        filter={'gamePc'}
        label={'Центральный замок'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={signaling}
        setValue={setSignaling}
        center={true}
        filter={'gamePc'}
        label={'Сигнализация'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={immobilizer}
        setValue={setImmobilizer}
        center={true}
        filter={'gamePc'}
        label={'Иммобилайзер'}
      />
      <div className={styles.title}>Элементы экстерьера</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={alloyWheels}
        setValue={setAlloyWheels}
        center={true}
        filter={'gamePc'}
        label={'Легкосплавные диски'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={aeroBody}
        setValue={setAeroBody}
        center={true}
        filter={'gamePc'}
        label={'Аэродинамический обвес'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={towbar}
        setValue={setTowbar}
        center={true}
        filter={'gamePc'}
        label={'Фаркоп'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={winterTires}
        setValue={setWinterTires}
        center={true}
        filter={'gamePc'}
        label={'Зимние шины'}
      />
      <div className={styles.title}>Помощь при вождении и парковке</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={powerSteering}
        setValue={setPowerSteering}
        center={true}
        filter={'powerSteering'}
        label={'Усилитель руля'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={roadAdaptation}
        setValue={setRoadAdaptation}
        center={true}
        filter={'gamePc'}
        label={'Система адаптации к дорожным условиям'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={cruiseControl}
        setValue={setCruiseControl}
        center={true}
        filter={'gamePc'}
        label={'Круиз-контроль'}
      />
      <div className={styles.title}>Обзор</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={headlightsT}
        setValue={setHeadlightsT}
        center={true}
        filter={'headlightsT'}
        label={'Тип автомобильных фар'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={fogLights}
        setValue={setFogLights}
        center={true}
        filter={'gamePc'}
        label={'Противотуманные фары'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={headlightWashers}
        setValue={setHeadlightWashers}
        center={true}
        filter={'gamePc'}
        label={'Омыватели фар'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={lightSensor}
        setValue={setLightSensor}
        center={true}
        filter={'gamePc'}
        label={'Датчик света'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={electricMirrors}
        setValue={setElectricMirrors}
        center={true}
        filter={'gamePc'}
        label={'Электрические зеркала'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={rainSensor}
        setValue={setRainSensor}
        center={true}
        filter={'gamePc'}
        label={'Датчик дождя'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={heatedMirrors}
        setValue={setHeatedMirrors}
        center={true}
        filter={'gamePc'}
        label={'Обогрев зеркал'}
      />
      <div className={styles.title}>Безопасность при вождении</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={airbag}
        setValue={setAirbag}
        center={true}
        filter={'airbag'}
        label={'Подушка безопасности фронтальная водителя'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={eba}
        setValue={setEba}
        center={true}
        filter={'gamePc'}
        label={'Помощь при экстренном торможении (EBA/Break assist)'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={abs}
        setValue={setAbs}
        center={true}
        filter={'gamePc'}
        label={'Антиблокировочная система тормозов (ABS)'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={esp}
        setValue={setEsp}
        center={true}
        filter={'gamePc'}
        label={'Система курсовой стабилизации (ESP / ESC / DSC / VSA)'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={asr}
        setValue={setAsr}
        center={true}
        filter={'gamePc'}
        label={'Антипробуксовочная система (ASR / TCS / TRC)'}
      />
      <div className={styles.title}>Мультимедиа</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={boardComputer}
        setValue={setBoardComputer}
        center={true}
        filter={'gamePc'}
        label={'Бортовой компьютер'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={gps}
        setValue={setGps}
        center={true}
        filter={'gamePc'}
        label={'Навигационная система (GPS)'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={opticalDrive}
        setValue={setOpticalDrive}
        center={true}
        filter={'opticalDriveAuto'}
        filterOr={'opticalDrive'}
        label={'Поддержка носителей в автомагнитоле'}
      />
    </>
  )
}

export default PassengerCars
