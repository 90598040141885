// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CategoryCreatorList_roleButon__Ekr-a {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 20px 0 0 20px;
  margin-bottom: -1px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  transition: background 0.2s;
}
.CategoryCreatorList_roleButon__Ekr-a:hover {
  background-color: rgb(245, 245, 245);
}

.CategoryCreatorList_icon__AVBfS {
  width: 42px;
  height: 42px;
}

.CategoryCreatorList_columnContainer__WSbr- {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-right: 24px;
  min-height: 86px;
  border-bottom: 1px solid rgb(235, 235, 235);
  transition: border 0.2s;
}

.CategoryCreatorList_categoryName__Smq4F {
  display: flex;
  align-items: center;
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: rgb(57, 57, 57);
  line-height: normal;
}

.CategoryCreatorList_listColumn__sr7R9 {
  margin-top: 10px;
  margin-bottom: 5px;
  line-height: normal;
  color: rgb(143, 143, 143);
}`, "",{"version":3,"sources":["webpack://./src/components/CategoryCreatorList/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,SAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,2BAAA;AACF;AAAE;EACE,oCAAA;AAEJ;;AACA;EACE,WAAA;EACA,YAAA;AAEF;;AAAA;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,iBAAA;EACA,oBAAA;EACA,mBAAA;EACA,gBAAA;EACA,2CAAA;EACA,uBAAA;AAGF;;AADA;EACE,aAAA;EAEA,mBAAA;EACA,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,sBAAA;EACA,mBAAA;AAIF;;AAFA;EACE,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;AAKF","sourcesContent":[".roleButon{\r\n  display: flex;\r\n  align-items: flex-start;\r\n  gap: 20px;\r\n  padding: 20px 0 0 20px;\r\n  margin-bottom: -1px;\r\n  overflow: hidden;\r\n  cursor: pointer;\r\n  border-radius: 4px;\r\n  transition: background 0.2s;\r\n  &:hover{\r\n    background-color: rgb(245, 245, 245);\r\n  }\r\n}\r\n.icon{\r\n  width: 42px;\r\n  height: 42px;\r\n}\r\n.columnContainer{\r\n  display: flex;\r\n  align-items: flex-start;\r\n  flex-direction: column;\r\n  padding-top: 10px;\r\n  padding-bottom: 20px;\r\n  padding-right: 24px;\r\n  min-height: 86px;\r\n  border-bottom: 1px solid rgb(235, 235, 235);\r\n  transition: border 0.2s;\r\n}\r\n.categoryName{\r\n  display: flex;\r\n  -webkit-box-align: center;\r\n  align-items: center;\r\n  font-family: \"Fira Sans\", sans-serif;\r\n  font-weight: 500;\r\n  font-size: 20px;\r\n  color: rgb(57, 57, 57);\r\n  line-height: normal;\r\n}\r\n.listColumn{\r\n  margin-top: 10px;\r\n  margin-bottom: 5px;\r\n  line-height: normal;\r\n  color: rgb(143, 143, 143);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"roleButon": `CategoryCreatorList_roleButon__Ekr-a`,
	"icon": `CategoryCreatorList_icon__AVBfS`,
	"columnContainer": `CategoryCreatorList_columnContainer__WSbr-`,
	"categoryName": `CategoryCreatorList_categoryName__Smq4F`,
	"listColumn": `CategoryCreatorList_listColumn__sr7R9`
};
export default ___CSS_LOADER_EXPORT___;
