import React from 'react'
import SelectComponent from '../../SelectComponent'

function Animals({
  filtersAll,
  columnId,
  breed,
  setBreed,
  type,
  setType,
  productType,
  setProductType,
}) {
  const isDisabled = columnId !== 65
  const isTypeProduct = columnId === 68 || columnId === 69
  return (
    <>
      {columnId === 61 && (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={breed}
            setValue={setBreed}
            center={true}
            filter={'breed'}
            label={'Порода'}
          />
        </>
      )}
      {columnId === 62 && (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={breed}
            setValue={setBreed}
            center={true}
            filter={'breedCat'}
            label={'Порода'}
          />
        </>
      )}
      {columnId === 63 && (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={breed}
            setValue={setBreed}
            center={true}
            filter={'birds'}
            label={'Вид птицы'}
          />
        </>
      )}
      {columnId === 64 && (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={breed}
            setValue={setBreed}
            center={true}
            filter={'rodents'}
            label={'Вид животного'}
          />
        </>
      )}
      {columnId === 66 && (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={breed}
            setValue={setBreed}
            center={true}
            filter={'farmAnimals'}
            label={'Вид животного'}
          />
        </>
      )}
      {columnId === 67 && (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={breed}
            setValue={setBreed}
            center={true}
            filter={'otherAnimals'}
            label={'Вид животного'}
          />
        </>
      )}
      {columnId === 68 && (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={breed}
            setValue={setBreed}
            center={true}
            filter={'animals'}
            label={'Вид животного'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={productType}
            setValue={setProductType}
            center={true}
            filter={'typeProducts'}
            label={'Тип товара'}
          />
        </>
      )}
      {columnId === 69 && (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={breed}
            setValue={setBreed}
            center={true}
            filter={'aquarium'}
            label={'Тип'}
          />
        </>
      )}
      {isDisabled && (
        <SelectComponent
          filtersAll={filtersAll}
          value={type}
          setValue={setType}
          center={true}
          filter={isTypeProduct ? 'typeProduct' : 'typeProductAn'}
          label={'Тип объявления'}
        />
      )}
    </>
  )
}
export default Animals
