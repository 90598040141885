import * as ActionTypes from '../constants/user.constants'

const initialState = {
  user: null,
  uploadAvatarProgress: 0,
  toggleAvatarProgress: false,
  isCropperAvatar: false,
  isSveCropper: false,
  loadings: {
    user: false,
  },
  errors: {
    user: null,
  },
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.USER_INFO_REQUEST: {
      return {
        ...state,
        isSveCropper: false,
        uploadCoverProgress: 0,
        toggleCoverProgress: false,
        isCropperCover: false,
        loadings: { ...state.loadings, user: true },
        errors: { ...state.errors, user: null },
      }
    }
    case ActionTypes.USER_INFO_SUCCESS: {
      const user = action.payload
      return {
        ...state,
        loadings: { ...state.loadings, user: false },
        errors: { ...state.errors, user: null },
        user,
      }
    }
    case ActionTypes.USER_INFO_ERROR: {
      return {
        ...state,
        loadings: { ...state.loadings, user: false },
        errors: { ...state.errors, user: action.payload },
      }
    }

    case ActionTypes.UPLOAD_USER_AVATAR: {
      return {
        ...state,
        toggleAvatarProgress: true,
      }
    }
    case ActionTypes.UPLOAD_USER_AVATAR_PROGRESS: {
      return {
        ...state,
        uploadAvatarProgress: action.payload,
      }
    }

    case ActionTypes.UPLOAD_USER_AVATAR_SUCCESS: {
      const userOld = state.user
      return {
        ...state,
        user: {
          ...userOld,
          avatar: action.payload,
        },
        uploadAvatarProgress: 0,
        toggleAvatarProgress: false,
      }
    }
    case ActionTypes.CROPPER_AVATAR_SAVE: {
      return {
        ...state,
        isCropperAvatar: false,
        isSveCropper: true,
      }
    }
    case ActionTypes.CROPPER_AVATAR_SAVE_SUCCESS: {
      const userOld = state.user
      return {
        ...state,
        user: {
          ...userOld,
          avatar_cover: action.payload,
        },
        isSveCropper: false,
      }
    }
    default:
      return state
  }
}
export default userReducer
