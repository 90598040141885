import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SwiperList from '../../../components/SwiperList'
import SelectCategory from '../../../components/SelectCategory'
import styles from './index.module.scss'
import * as actions from '../../../actions'
import ForNothingList from '../../../components/ForNothingList'
import ProductList from '../../../components/ProductList'

function Home() {
  const dispatch = useDispatch()
  const swiperAdvertising = useSelector((state) => state.app.swiperAdvertising)
  const category = useSelector((state) => state.app.category)
  const userInfo = useSelector((state) => state.user.user)
  const advertisements = useSelector((state) => state.app.advertisements)
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const onDisableAll = () => {
    dispatch(actions.toggleShowUser(false))
    dispatch(actions.toggleCategoryMobile(false))
    dispatch(actions.toggleCategory(false))
    dispatch(actions.toggleSubCategory(null))
  }

  const forNothing =
    advertisements &&
    advertisements.length > 0 &&
    advertisements.filter((a) => a.price === 0)

  return (
    <main className={styles.main} onClick={onDisableAll}>
      {swiperAdvertising && swiperAdvertising.length > 0 && (
        <SwiperList swiperAdvertising={swiperAdvertising} />
      )}
      {category && category.length > 0 && (
        <SelectCategory category={category} />
      )}
      <div className={styles.rowContainer}>
        <div className={styles.col1}>
          {forNothing && forNothing.length > 0 && (
            <div className={styles.swiperContainerProduct}>
              <h2 className={styles.title}>Отдам даром</h2>
              <ForNothingList
                forNothing={forNothing}
                user={userInfo}
                width={width}
              />
            </div>
          )}
          <div className={styles.productList}>
            <h2 className={styles.title}>Все объявления</h2>
            <div className={styles.productListContainer}>
              {advertisements.map((ad, index) => (
                <ProductList key={index} product={ad} user={userInfo} />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.colAdvert}>
          <></>
        </div>
      </div>
    </main>
  )
}

export default Home
