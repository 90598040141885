import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ProductList from '../ProductList'
import styles from './index.module.scss'

function ProductFilter({ categoryRoute, columnRoute, subCategoryRoute }) {
  const advertisements = useSelector((state) => state.app.advertisements)
  const userInfo = useSelector((state) => state.user.user)
  const coords = useSelector((state) => state.app.coords)
  const [isSort, setIsSort] = useState(false)
  const [selectSort, setSelectSort] = useState('По умолчанию')

  const filterCategory =
    advertisements && advertisements.length > 0 && subCategoryRoute
      ? advertisements.filter(
          (a) =>
            a?.category?.categoryRoute === categoryRoute &&
            a?.category?.columns?.columnRoute === columnRoute &&
            a?.category?.columns?.subCategory?.subCategoryRoute ===
              subCategoryRoute,
        )
      : advertisements && advertisements.length > 0 && columnRoute
        ? advertisements.filter(
            (a) =>
              a.category?.categoryRoute === categoryRoute &&
              a.category?.columns.columnRoute === columnRoute,
          )
        : advertisements && advertisements.length > 0
          ? advertisements.filter(
              (a) => a.category?.categoryRoute === categoryRoute,
            )
          : null

  const title =
    advertisements && advertisements.length > 0 && subCategoryRoute
      ? advertisements.find(
          (a) =>
            a?.category?.categoryRoute === categoryRoute &&
            a?.category?.columns?.columnRoute === columnRoute &&
            a?.category?.columns?.subCategory?.subCategoryRoute ===
              subCategoryRoute,
        )?.category?.columns?.subCategory
      : advertisements && advertisements.length > 0 && columnRoute
        ? advertisements.find(
            (a) =>
              a?.category?.categoryRoute === categoryRoute &&
              a?.category?.columns?.columnRoute === columnRoute,
          ).category.columns
        : advertisements && advertisements.length > 0
          ? advertisements.find(
              (a) => a?.category?.categoryRoute === categoryRoute,
            )?.category
          : null

  const onSort = (name) => {
    setSelectSort(name)
    setIsSort(false)
  }

  function toRadians(degrees) {
    return (degrees * Math.PI) / 180
  }

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371 // Earth's radius in km
    const dLat = toRadians(lat2 - lat1)
    const dLon = toRadians(lon2 - lon1)
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    return R * c
  }
  const sortProduct = () => {
    if (selectSort === 'По умолчанию' && filterCategory) {
      return filterCategory
    } else if (selectSort === 'По новизне' && filterCategory) {
      return filterCategory
        .slice()
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    } else if (selectSort === 'По стоимости' && filterCategory) {
      return filterCategory.slice().sort((a, b) => a.price - b.price)
    } else if (selectSort === 'По удаленности' && filterCategory && coords) {
      const filteredLocations = []
      const my_lat = coords.latitude
      const my_lon = coords.longitude
      for (const location of filterCategory) {
        const { latitudes, longitudes } = JSON.parse(location.coords)
        const dist = calculateDistance(my_lat, my_lon, latitudes, longitudes)
        if (dist <= 100) {
          filteredLocations.push(location)
        }
      }
      return filteredLocations
    }
    return []
  }

  return (
    <div className={styles.productInfo}>
      <div className={styles.catecoryContainer}>
        <div className={styles.titleContainer}>
          {title && <h1 className={styles.title}>{title.name}</h1>}
          <div></div>
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.sort}>
            <div className={styles.sortContainer}>
              <button
                className={styles.selectContainer}
                onClick={() => {
                  setIsSort(!isSort)
                }}
              >
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <path
                    d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                    fill="#333"
                    fillRule="evenodd"
                  ></path>
                </svg>
                <span className={styles.textSort}>{selectSort}</span>
              </button>
            </div>
          </div>
          {isSort && (
            <ul
              data-test-component="SelectList"
              className={styles.selectListContainer}
            >
              <li
                className={styles.selectItem}
                data-test-component="SelectListItem"
                onClick={() => {
                  onSort('По умолчанию')
                }}
              >
                <span className={styles.itemName}>По умолчанию</span>
              </li>
              <li
                className={styles.selectItem}
                data-test-component="SelectListItem"
                onClick={() => {
                  onSort('По новизне')
                }}
              >
                <span className={styles.itemName}>По новизне</span>
              </li>
              <li
                className={styles.selectItem}
                data-test-component="SelectListItem"
                onClick={() => {
                  onSort('По стоимости')
                }}
              >
                <span className={styles.itemName}>По стоимости</span>
              </li>
              <li
                className={styles.selectItem}
                data-test-component="SelectListItem"
                onClick={() => {
                  onSort('По удаленности')
                }}
              >
                <span className={styles.itemName}>По удаленности</span>
              </li>
            </ul>
          )}
        </div>
      </div>
      {sortProduct() && sortProduct().length > 0 ? (
        <div className={styles.productListContainer}>
          {sortProduct().map((item, index) => (
            <ProductList key={index} product={item} user={userInfo} />
          ))}
        </div>
      ) : (
        <>
          <></>
        </>
      )}
    </div>
  )
}

export default ProductFilter
