import React from 'react'
import PageWrapper from '../../../components/PageWrapper'
import { useSelector } from 'react-redux'

function WomenWardrobeA() {
  const category = useSelector((state) => state.app.category)
  const filtersAll = useSelector((state) => state.filtersIt.filtersAll)
  const aksessuary = filtersAll.filter((item) => item.type === 1)
  return (
    <PageWrapper
      filtersAll={filtersAll}
      isFiltersAll={true}
      categoryRoute={null}
      columnRoute={'zhenskaya-odezhda'}
      subCategoryRoute={'aksessuary'}
      category={category}
      categoryId={1}
      columnsId={1}
      subCategoryId={1}
      isAksessuary={true}
      aksessuary={aksessuary}
      title={'Вещи, электроника и прочее'}
      subTitle={'женская одежда'}
    />
  )
}
export default WomenWardrobeA
