const routesLik = Object.freeze({
  root: '/',
  login: '/login',
  register: '/register',
  productCreate: '/product/create',
  womenWardrobe: '/zhenskaya-odezhda',
  womenWardrobeA: '/zhenskaya-odezhda/aksessuary',
  womenWardrobeAId: '/zhenskaya-odezhda/aksessuary/:id',
  womenWardrobeBluzy: '/zhenskaya-odezhda/bluzy-rubashki',
  womenWardrobeBluzyId: '/zhenskaya-odezhda/bluzy-rubashki/:id',
  womenWardrobeDlyaBeremennyh: '/zhenskaya-odezhda/dlya-beremennyh',
  womenWardrobeDlyaBeremennyhId: '/zhenskaya-odezhda/dlya-beremennyh/:id',
  womenWardrobeVerhnyaya: '/zhenskaya-odezhda/verhnyaya',
  womenWardrobeVerhnyayaId: '/zhenskaya-odezhda/verhnyaya/:id',
  uslugi: '/uslugi',
  obuchenie: '/uslugi/obuchenie',
})
export default routesLik
