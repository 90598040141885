// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubCategoryList_link__T50Q- {
  color: rgb(112, 146, 254);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-size: 14px;
  line-height: 1.71;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}
.SubCategoryList_link__T50Q-:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/SubCategoryList/index.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,oBAAA;EACA,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,wCAAA;EACA,aAAA;AACF;AAAE;EACE,0BAAA;AAEJ","sourcesContent":[".link{\r\n  color: rgb(112, 146, 254);\r\n  font-weight: normal;\r\n  font-style: normal;\r\n  font-stretch: normal;\r\n  letter-spacing: normal;\r\n  font-size: 14px;\r\n  line-height: 1.71;\r\n  text-decoration: none;\r\n  -webkit-tap-highlight-color: transparent;\r\n  outline: none;\r\n  &:hover{\r\n    text-decoration: underline;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `SubCategoryList_link__T50Q-`
};
export default ___CSS_LOADER_EXPORT___;
