import React from 'react'
import styles from '../index.module.scss'
import SelectComponent from '../../SelectComponent'
import InputForm from '../../InputForm'

function SellingAHouse({
  filtersAll,
  columnId,
  apartmentType,
  setApartmentType,
  gas,
  setGas,
  floor,
  setFloor,
  totalArea,
  setTotalArea,
  bathrooms,
  setBathrooms,
  periodOfUse,
  setPeriodOfUse,
  whoPosted,
  setWhoPosted,
  materHouse,
  setMaterHouse,
  romSlipC,
  setRomSlipC,
  electricity,
  setElectricity,
  heating,
  setHeating,
  waterSupply,
  setWaterSupply,
  garage,
  setGarage,
  plotArea,
  setPlotArea,
  plotType,
  setPlotType,
  fridge,
  setFridge,
  dishwasher,
  setDishwasher,
  washingMachine,
  setWashingMachine,
  prepayment,
  setPrepayment,
  publicUtilities,
  setPublicUtilities,
}) {
  const isEnabled55 = columnId === 55 || columnId === 58
  const isEnabled2 = columnId === 55
  return (
    <>
      <div className={styles.border} />
      <div className={styles.title}>Параметры дома</div>
      <div className={styles.titleL}>Основные</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={apartmentType}
        setValue={setApartmentType}
        center={true}
        filter={'houseTypeH'}
        label={'Тип дома'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={materHouse}
        setValue={setMaterHouse}
        center={true}
        filter={'materHouse'}
        label={'Материал дома'}
      />
      <InputForm
        label={'Площадь дома'}
        subLabel={'м²'}
        value={totalArea}
        setValue={setTotalArea}
      />
      <InputForm label={'Этажей'} value={floor} setValue={setFloor} />
      <InputForm
        label={'Количество спален'}
        value={romSlipC}
        setValue={setRomSlipC}
      />
      <div className={styles.titleL}>Дополнительные</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={bathrooms}
        setValue={setBathrooms}
        center={true}
        filter={'bathrooms'}
        label={'Санузлы'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={electricity}
        setValue={setElectricity}
        center={true}
        filter={'electricity'}
        label={'Электричество'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={heating}
        setValue={setHeating}
        center={true}
        filter={'heating'}
        label={'Отопление'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={gas}
        setValue={setGas}
        center={true}
        filter={'gamePc'}
        label={'Газ'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={waterSupply}
        setValue={setWaterSupply}
        center={true}
        filter={'heating'}
        label={'Водоснабжение'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={garage}
        setValue={setGarage}
        center={true}
        filter={'garage'}
        label={'Гараж'}
      />
      {isEnabled55 && (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={fridge}
            setValue={setFridge}
            center={true}
            filter={'gamePc'}
            label={'Холодильник'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={dishwasher}
            setValue={setDishwasher}
            center={true}
            filter={'gamePc'}
            label={'Посудомоечная машина'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={washingMachine}
            setValue={setWashingMachine}
            center={true}
            filter={'gamePc'}
            label={'Стиральная машина'}
          />
        </>
      )}
      <div className={styles.border} />
      <div className={styles.title}>Параметры участкаа</div>
      <InputForm
        label={'Площадь участка'}
        value={plotArea}
        setValue={setPlotArea}
        subLabel={'сот.'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={plotType}
        setValue={setPlotType}
        center={true}
        filter={'plotType'}
        label={'Тип участка'}
      />
      <div className={styles.border} />
      <div className={styles.title}>Условия</div>
      {isEnabled55 ? (
        <>
          {isEnabled2 ? (
            <SelectComponent
              filtersAll={filtersAll}
              value={prepayment}
              setValue={setPrepayment}
              center={true}
              filter={'prepayment'}
              label={'Предоплата'}
            />
          ) : (
            <InputForm
              label={'Залог собственнику'}
              value={prepayment}
              setValue={setPrepayment}
              subLabel={'₽'}
            />
          )}
        </>
      ) : (
        <SelectComponent
          filtersAll={filtersAll}
          value={periodOfUse}
          setValue={setPeriodOfUse}
          center={true}
          filter={'periodOfUseN'}
          label={'Срок владения'}
        />
      )}
      {isEnabled2 && (
        <SelectComponent
          filtersAll={filtersAll}
          value={publicUtilities}
          setValue={setPublicUtilities}
          center={true}
          filter={'publicUtilities'}
          label={'Коммунальные услуги'}
        />
      )}
      <SelectComponent
        filtersAll={filtersAll}
        value={whoPosted}
        setValue={setWhoPosted}
        center={true}
        filter={'whoPosted'}
        label={'Кто разместил'}
      />
      <div className={styles.border} />
    </>
  )
}

export default SellingAHouse
