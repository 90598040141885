import React from 'react'
import styles from './index.module.scss'

function InputForm({ value, setValue, label, subLabel }) {
  return (
    <>
      <div className={`${styles.flexCreatorIn} ${styles.flexCreatorInC}`}>
        <div className={styles.labelW}>{label}</div>
        <div className={styles.labelS}>
          <div className={styles.inputPriceC}>
            <input
              value={value || ''}
              onChange={(e) => setValue(e.target.value)}
              className={styles.inputPrice}
            />
            {subLabel && <div>{subLabel}</div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default InputForm
