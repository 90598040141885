import React from 'react'
import styles from './index.module.scss'

function AuthInput({ className, field, form, ...props }) {
  return (
    <>
      <input
        autoFocus
        required
        className={`${className} ${styles.inputContainer}`}
        {...field}
        {...props}
      />
    </>
  )
}

export default AuthInput
