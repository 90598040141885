import React from 'react'
import SelectComponent from '../SelectComponent'

function SubCategoryIdSwitch({
  subCategoryId,
  filtersAll,
  brand,
  setBrand,
  size,
  setSize,
  season,
  setSeason,
  length,
  setLength,
  sex,
  setSex,
  sizeHeight,
  setSizeHeight,
  weightGroup,
  setWeightGroup,
  fasteningC,
  setFasteningC,
  peculiaritiesC,
  setPeculiaritiesC,
  adjustmentsC,
  setAdjustmentsC,
  strollersT,
  setStrollersT,
  strollersBl,
  setStrollersBl,
  strollersCir,
  setStrollersCir,
  operatingOs,
  setOperatingOs,
  builtMemory,
  setBuiltMemory,
  periodOfUse,
  setPeriodOfUse,
  diagonal,
  setDiagonal,
  ozu,
  setOzu,
  camera1,
  setCamera1,
  camera2,
  setCamera2,
  compatibilityA,
  setCompatibilityA,
  compatibilityI,
  setCompatibilityI,
  moistureProtection,
  setMoistureProtection,
  pedometer,
  setPedometer,
  heartMete,
  setHeartMete,
  sleepMete,
  setSleepMete,
  phoneCall,
  setPhoneCall,
  autoResp,
  setAutoResp,
  aon,
  setAon,
  device,
  setDevice,
  amperes,
  setAmperes,
  usbCount,
  setUsbCount,
  connectT,
  setConnectT,
  cable,
  setCable,
  materialCh,
  setMaterialCh,
  categCam,
  setCategCam,
  viewfinder,
  setViewfinder,
  imageStabilizer,
  setImageStabilizer,
  videoQuality,
  setVideoQuality,
  typeSd,
  setTypeSd,
  zoomOp,
  setZoomOp,
  actionCamera,
  setActionCamera,
  r360,
  setR360,
  conType,
  setConType,
  wifi,
  setWifi,
  motionSen,
  setMotionSen,
  microphone,
  setMicrophone,
  mountType,
  setMountType,
  autofocus,
  setAutofocus,
  swivelHead,
  setSwivelHead,
  playVid,
  setPlayVid,
  ssd,
  setSsd,
  videoTy,
  setVideoTy,
  game,
  setGame,
  opticalDrive,
  setOpticalDrive,
  hdmi,
  setHdmi,
  speakers,
  setSpeakers,
  usb,
  setUsb,
  smartTV,
  setSmartTV,
  t3d,
  setT3d,
  hd,
  setHd,
  sizeDisplay,
  setSizeDisplay,
  hdr,
  setHdr,
  karaoke,
  setKaraoke,
  acousticType,
  setAcousticType,
  portable,
  setPortable,
  fm,
  setFm,
  touchScreen,
  setTouchScreen,
  displayType,
  setDisplayType,
  displayColor,
  setDisplayColor,
  backlight,
  setBacklight,
  wireless,
  setWireless,
  volumeCh,
  setVolumeCh,
  waterN,
  setWaterN,
  noiseReduction,
  setNoiseReduction,
  scopeOfApplication,
  setScopeOfApplication,
  safety,
  setSafety,
  timer,
  setTimer,
  installationType,
  setInstallationType,
  utensilCapacity,
  setUtensilCapacity,
  leakProtection,
  setLeakProtection,
  delayStart,
  setDelayStart,
  typeCleaning,
  setTypeCleaning,
  kg,
  setKg,
  spin,
  setSpin,
  drying,
  setDrying,
  loadType,
  setLoadType,
  height,
  setHeight,
  depth,
  setDepth,
  swidth,
  setSWidth,
  applicationArea,
  setApplicationArea,
  forChild,
  setForChild,
  typeSport,
  setTypeSport,
  frame,
  setFrame,
  platform,
  setPlatform,
  genre,
  setGenre,
  theme,
  setTheme,
}) {
  switch (subCategoryId) {
    case 1:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
        </>
      )
    case 2:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
        </>
      )
    case 3:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={sex}
            setValue={setSex}
            center={true}
            filter={'sexChild'}
            label={'Пол'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
        </>
      )
    case 4:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
        </>
      )
    case 5:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
        </>
      )
    case 6:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
        </>
      )
    case 7:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'womanHatsBrand'}
            label={'Бренд'}
          />
        </>
      )
    case 8:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandHomeWomanProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
        </>
      )
    case 9:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandHomeWomanProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
        </>
      )
    case 10:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandHomeWomanProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
        </>
      )
    case 11:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
        </>
      )
    case 12:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'shoesSize'}
            label={'Размер'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
        </>
      )
    case 13:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
        </>
      )
    case 14:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={length}
            setValue={setLength}
            center={true}
            filter={'lengthProduct'}
            label={'Длина'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
        </>
      )
    case 15:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
        </>
      )
    case 16:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
        </>
      )
    case 17:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
        </>
      )
    case 18:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
        </>
      )
    case 19:
      return <></>
    case 20:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
        </>
      )
    case 21:
      return <></>
    case 22:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
        </>
      )
    case 23:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
        </>
      )
    case 24:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
        </>
      )
    case 25:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'shoesSize'}
            label={'Размер'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
        </>
      )
    case 26:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
        </>
      )
    case 27:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
        </>
      )
    case 28:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
        </>
      )
    case 29:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
        </>
      )
    case 30:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
        </>
      )
    case 31:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeProduct'}
            label={'Размер'}
          />
        </>
      )
    case 32:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            filterOr={'brandAFromMomProduct'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'sizeManProduct'}
            label={'Размер'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
        </>
      )
    case 33:
      return <></>
    case 34:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={sex}
            setValue={setSex}
            center={true}
            filter={'sexChild'}
            label={'Пол'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={sizeHeight}
            setValue={setSizeHeight}
            center={true}
            filter={'yearsChild'}
            label={'Размер (рост)'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
        </>
      )
    case 35:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={sex}
            setValue={setSex}
            center={true}
            filter={'sexChild'}
            label={'Пол'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={sizeHeight}
            setValue={setSizeHeight}
            center={true}
            filter={'yearsChild'}
            label={'Размер (рост)'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
        </>
      )
    case 36:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={sex}
            setValue={setSex}
            center={true}
            filter={'sexChild'}
            label={'Пол'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
        </>
      )
    case 37:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={sex}
            setValue={setSex}
            center={true}
            filter={'sexChild'}
            label={'Пол'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={sizeHeight}
            setValue={setSizeHeight}
            center={true}
            filter={'yearsChild'}
            label={'Размер (рост)'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
        </>
      )
    case 38:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={sex}
            setValue={setSex}
            center={true}
            filter={'sexChild'}
            label={'Пол'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={sizeHeight}
            setValue={setSizeHeight}
            center={true}
            filter={'yearsChild'}
            label={'Размер (рост)'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
        </>
      )
    case 39:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={sex}
            setValue={setSex}
            center={true}
            filter={'sexChild'}
            label={'Пол'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={sizeHeight}
            setValue={setSizeHeight}
            center={true}
            filter={'yearsChild'}
            label={'Размер (рост)'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
        </>
      )
    case 40:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={sex}
            setValue={setSex}
            center={true}
            filter={'sexChild'}
            label={'Пол'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
        </>
      )
    case 41:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={sex}
            setValue={setSex}
            center={true}
            filter={'sexChild'}
            label={'Пол'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={size}
            setValue={setSize}
            center={true}
            filter={'shoesChild'}
            label={'Размер'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
        </>
      )
    case 42:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={sex}
            setValue={setSex}
            center={true}
            filter={'sexChild'}
            label={'Пол'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={sizeHeight}
            setValue={setSizeHeight}
            center={true}
            filter={'yearsChild'}
            label={'Размер (рост)'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
        </>
      )
    case 43:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={length}
            setValue={setLength}
            center={true}
            filter={'lengthProduct'}
            label={'Длина'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={sizeHeight}
            setValue={setSizeHeight}
            center={true}
            filter={'yearsChild'}
            label={'Размер (рост)'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
        </>
      )
    case 44:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={sex}
            setValue={setSex}
            center={true}
            filter={'sexChild'}
            label={'Пол'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={sizeHeight}
            setValue={setSizeHeight}
            center={true}
            filter={'yearsChild'}
            label={'Размер (рост)'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
        </>
      )
    case 45:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={sex}
            setValue={setSex}
            center={true}
            filter={'sexChild'}
            label={'Пол'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={sizeHeight}
            setValue={setSizeHeight}
            center={true}
            filter={'yearsChild'}
            label={'Размер (рост)'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
        </>
      )
    case 46:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={sex}
            setValue={setSex}
            center={true}
            filter={'sexChild'}
            label={'Пол'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={sizeHeight}
            setValue={setSizeHeight}
            center={true}
            filter={'yearsChild'}
            label={'Размер (рост)'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
        </>
      )
    case 47:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={sex}
            setValue={setSex}
            center={true}
            filter={'sexChild'}
            label={'Пол'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={sizeHeight}
            setValue={setSizeHeight}
            center={true}
            filter={'yearsChild'}
            label={'Размер (рост)'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
        </>
      )
    case 48:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={sex}
            setValue={setSex}
            center={true}
            filter={'sexChild'}
            label={'Пол'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={sizeHeight}
            setValue={setSizeHeight}
            center={true}
            filter={'yearsChild'}
            label={'Размер (рост)'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'accessoriesBrand'}
            label={'Бренд'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={season}
            setValue={setSeason}
            center={true}
            filter={'seasonProduct'}
            label={'Сезон'}
          />
        </>
      )
    case 49:
      return <></>
    case 50:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandCarSeats'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={weightGroup}
            setValue={setWeightGroup}
            center={true}
            filter={'groupChild'}
            label={'Группа'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={fasteningC}
            setValue={setFasteningC}
            center={true}
            isMulti={true}
            filter={'setFasteningCChild'}
            label={'Крепление'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={peculiaritiesC}
            setValue={setPeculiaritiesC}
            center={true}
            isMulti={true}
            filter={'peculiaritiesCChild'}
            label={'Особенности'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={adjustmentsC}
            setValue={setAdjustmentsC}
            center={true}
            isMulti={true}
            filter={'adjustmentsChild'}
            label={'Регулировки'}
          />
        </>
      )
    case 51:
      return <></>
    case 52:
      return <></>
    case 53:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandCarSeats'}
            label={'Марка'}
            filterOr={'brandCarSeatsStrollers'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={weightGroup}
            setValue={setWeightGroup}
            center={true}
            filter={'ageChild'}
            label={'Возраст'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={strollersBl}
            setValue={setStrollersBl}
            center={true}
            filter={'strollersBlChild'}
            label={'Количество блоков'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={strollersCir}
            setValue={setStrollersCir}
            center={true}
            filter={'strollersCirChild'}
            label={'Тип колес'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={strollersT}
            setValue={setStrollersT}
            center={true}
            isMulti={true}
            filter={'strollersTChild'}
            label={'Особенности'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={adjustmentsC}
            setValue={setAdjustmentsC}
            center={true}
            isMulti={true}
            filter={'adjustmentsStrollChild'}
            label={'Регулировки'}
          />
        </>
      )
    case 54:
      return <></>
    case 55:
      return <></>
    case 56:
      return <></>
    case 57:
      return <></>
    case 58:
      return <></>
    case 59:
      return <></>
    case 60:
      return <></>
    case 71:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandMobilePhone'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={operatingOs}
            setValue={setOperatingOs}
            center={true}
            filter={'operatingOs'}
            label={'Операционная система'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={builtMemory}
            setValue={setBuiltMemory}
            center={true}
            filter={'builtMemory'}
            label={'Встроенная память'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={periodOfUse}
            setValue={setPeriodOfUse}
            center={true}
            filter={'periodOfUse'}
            label={'Срок использования'}
          />
        </>
      )
    case 72:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandMobilePhone'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={diagonal}
            setValue={setDiagonal}
            center={true}
            filter={'diagonalTablet'}
            label={'Диагональ экрана'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={operatingOs}
            setValue={setOperatingOs}
            center={true}
            filter={'operatingOs'}
            label={'Операционная система'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={ozu}
            setValue={setOzu}
            center={true}
            filter={'ozuTablet'}
            label={'Оперативная память'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={builtMemory}
            setValue={setBuiltMemory}
            center={true}
            filter={'builtMemory'}
            label={'Встроенная память'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={camera1}
            setValue={setCamera1}
            center={true}
            filter={'pixCam'}
            label={'Основная камера'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={camera2}
            setValue={setCamera2}
            center={true}
            filter={'pixCam'}
            label={'Фронтальная камера'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={periodOfUse}
            setValue={setPeriodOfUse}
            center={true}
            filter={'periodOfUse'}
            label={'Срок использования'}
          />
        </>
      )
    case 73:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandMobilePhone'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={compatibilityA}
            setValue={setCompatibilityA}
            center={true}
            filter={'compatibilityA'}
            label={'Совместимость с Android'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={compatibilityI}
            setValue={setCompatibilityI}
            center={true}
            filter={'compatibilityI'}
            label={'Совместимость с iOS'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={moistureProtection}
            setValue={setMoistureProtection}
            center={true}
            filter={'moistureProtection'}
            label={'Влагозащита'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={pedometer}
            setValue={setPedometer}
            center={true}
            filter={'pedometer'}
            label={'Шагомер'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={heartMete}
            setValue={setHeartMete}
            center={true}
            filter={'heartMete'}
            label={'Пульсометр'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={sleepMete}
            setValue={setSleepMete}
            center={true}
            filter={'sleepMete'}
            label={'Мониторинг сна'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={phoneCall}
            setValue={setPhoneCall}
            center={true}
            filter={'phoneCall'}
            label={'Телефонные звонки'}
          />
        </>
      )
    case 74:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={autoResp}
            setValue={setAutoResp}
            center={true}
            filter={'autoResp'}
            label={'Автоответчик'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={aon}
            setValue={setAon}
            center={true}
            filter={'aon'}
            label={'АОН'}
          />
        </>
      )
    case 76:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandMobilePhone'}
            label={'Марка устройства'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={device}
            setValue={setDevice}
            center={true}
            filter={'device'}
            label={'Устройство'}
          />
        </>
      )
    case 77:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={amperes}
            setValue={setAmperes}
            center={true}
            filter={'amperes'}
            label={'Емкость батареи'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={usbCount}
            setValue={setUsbCount}
            center={true}
            filter={'usbCount'}
            label={'Количество портов USB'}
          />
        </>
      )
    case 78:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={connectT}
            setValue={setConnectT}
            center={true}
            filter={'connectT'}
            label={'Разъем подключения'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={cable}
            setValue={setCable}
            center={true}
            filter={'cable'}
            label={'Наличие кабеля'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={amperes}
            setValue={setAmperes}
            center={true}
            filter={'amperesC'}
            label={'Выходная сила тока'}
          />
        </>
      )
    case 79:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={materialCh}
            setValue={setMaterialCh}
            center={true}
            filter={'materialCh'}
            label={'Материал'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={device}
            setValue={setDevice}
            center={true}
            filter={'deviceC'}
            label={'Устройство'}
          />
        </>
      )
    case 81:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={categCam}
            setValue={setCategCam}
            center={true}
            filter={'categCam'}
            label={'Категория камеры'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandCam'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={viewfinder}
            setValue={setViewfinder}
            center={true}
            filter={'viewfinder'}
            label={'Видоискатель'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={imageStabilizer}
            setValue={setImageStabilizer}
            center={true}
            filter={'imageStabilizer'}
            label={'Стабилизатор изображения'}
          />
        </>
      )
    case 82:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandCam'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={videoQuality}
            setValue={setVideoQuality}
            center={true}
            filter={'videoQuality'}
            label={'Качество видео'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={typeSd}
            setValue={setTypeSd}
            center={true}
            filter={'typeSd'}
            label={'Формат носителя'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={zoomOp}
            setValue={setZoomOp}
            center={true}
            filter={'zoomOp'}
            label={'Оптический Zoom'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={viewfinder}
            setValue={setViewfinder}
            center={true}
            filter={'viewfinder'}
            label={'Видоискатель'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={imageStabilizer}
            setValue={setImageStabilizer}
            center={true}
            filter={'imageStabilizer'}
            label={'Стабилизатор изображения'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={actionCamera}
            setValue={setActionCamera}
            center={true}
            filter={'actionCamera'}
            label={'Экшн-камера'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={r360}
            setValue={setR360}
            center={true}
            filter={'r360'}
            label={'Съемка в 360 градусов'}
          />
        </>
      )
    case 83:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={conType}
            setValue={setConType}
            center={true}
            filter={'conType'}
            label={'Тип подключения'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={wifi}
            setValue={setWifi}
            center={true}
            filter={'wifi'}
            label={'Wi-fi'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={motionSen}
            setValue={setMotionSen}
            center={true}
            filter={'motionSen'}
            label={'Датчик движения'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={microphone}
            setValue={setMicrophone}
            center={true}
            filter={'microphone'}
            label={'Встроенный микрофон'}
          />
        </>
      )
    case 84:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandLenses'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={mountType}
            setValue={setMountType}
            center={true}
            filter={'mountType'}
            label={'Тип байонета'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={autofocus}
            setValue={setAutofocus}
            center={true}
            filter={'autofocus'}
            label={'Автофокус'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={imageStabilizer}
            setValue={setImageStabilizer}
            center={true}
            filter={'imageStabilizer'}
            label={'Стабилизатор изображения'}
          />
        </>
      )
    case 85:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandLenses'}
            filterOr={'brandCam'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={swivelHead}
            setValue={setSwivelHead}
            center={true}
            filter={'swivelHead'}
            label={'Поворотная головка'}
          />
        </>
      )
    case 89:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={diagonal}
            setValue={setDiagonal}
            center={true}
            filter={'diagonalR'}
            label={'Диагональ экрана'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={playVid}
            setValue={setPlayVid}
            center={true}
            filter={'playVid'}
            label={'Воспроизведение видео'}
          />
        </>
      )
    case 90:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandPrint'}
            label={'Марка'}
          />
        </>
      )
    case 92:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'pcBrand'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={diagonal}
            setValue={setDiagonal}
            center={true}
            filter={'diagonalNoteBook'}
            label={'Диагональ экрана'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={ozu}
            setValue={setOzu}
            center={true}
            filter={'ozuPc'}
            label={'Оперативная память'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={ssd}
            setValue={setSsd}
            center={true}
            filter={'ssdPc'}
            label={'Общий объем всех накопителей'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={periodOfUse}
            setValue={setPeriodOfUse}
            center={true}
            filter={'periodOfUse'}
            label={'Срок использования'}
          />
        </>
      )
    case 93:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={ozu}
            setValue={setOzu}
            center={true}
            filter={'ozuPc'}
            label={'Оперативная память'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={videoTy}
            setValue={setVideoTy}
            center={true}
            filter={'videoTy'}
            label={'Тип видеокарты'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={game}
            setValue={setGame}
            center={true}
            filter={'gamePc'}
            label={'Игровой'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={opticalDrive}
            setValue={setOpticalDrive}
            center={true}
            filter={'opticalDrive'}
            label={'Тип оптического привода'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={ssd}
            setValue={setSsd}
            center={true}
            filter={'ssdPc'}
            label={'Общий объем всех накопителей'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={periodOfUse}
            setValue={setPeriodOfUse}
            center={true}
            filter={'periodOfUse'}
            label={'Срок использования'}
          />
        </>
      )
    case 94:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'pcBrand'}
            filterOr={'brandMonitor'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={diagonal}
            setValue={setDiagonal}
            center={true}
            filter={'diagonalMonitor'}
            label={'Диагональ экрана'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={hdmi}
            setValue={setHdmi}
            center={true}
            filter={'hdmiMonitor'}
            label={'Разъем HDMI'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={speakers}
            setValue={setSpeakers}
            center={true}
            filter={'speakersMonitor'}
            label={'Встроенные динамики'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={usb}
            setValue={setUsb}
            center={true}
            filter={'usbMonitor'}
            label={'USB разъем'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={periodOfUse}
            setValue={setPeriodOfUse}
            center={true}
            filter={'periodOfUse'}
            label={'Срок использования'}
          />
        </>
      )
    case 95:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandKeyboardsAndMouse'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={conType}
            setValue={setConType}
            center={true}
            filter={'conTypeKeyboardsAndMouse'}
            label={'Тип подключения'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={game}
            setValue={setGame}
            center={true}
            filter={'gamePc'}
            label={'Игровые'}
          />
        </>
      )
    case 101:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandKeyboardsAndMouse'}
            filterOr={'pcBrand'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={conType}
            setValue={setConType}
            center={true}
            filter={'conTypeKeyboardsAndMouse'}
            label={'Тип подключения'}
          />
        </>
      )
    case 102:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={device}
            setValue={setDevice}
            center={true}
            filter={'devicePc'}
            label={'Устройство'}
          />
        </>
      )
    case 104:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'tvBrand'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={diagonal}
            setValue={setDiagonal}
            center={true}
            filter={'diagonalTv'}
            label={'Диагональ экрана'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={smartTV}
            setValue={setSmartTV}
            center={true}
            filter={'gamePc'}
            label={'Smart TV'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={t3d}
            setValue={setT3d}
            center={true}
            filter={'gamePc'}
            label={'Поддержка 3D'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={hd}
            setValue={setHd}
            center={true}
            filter={'sizeDisplay'}
            label={'Разрешение экрана'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={sizeDisplay}
            setValue={setSizeDisplay}
            center={true}
            filter={'gamePc'}
            label={'Изогнутый экран'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={wifi}
            setValue={setWifi}
            center={true}
            filter={'gamePc'}
            label={'Wi-Fi'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={hdr}
            setValue={setHdr}
            center={true}
            filter={'gamePc'}
            label={'Поддержка HDR'}
          />
        </>
      )
    case 105:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'tvBrand'}
            filterOr={'pcBrand'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={t3d}
            setValue={setT3d}
            center={true}
            filter={'gamePc'}
            label={'Поддержка 3D'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={hd}
            setValue={setHd}
            center={true}
            filter={'sizeProjectors'}
            label={'Разрешение'}
          />
        </>
      )
    case 106:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'audioBrand'}
            label={'Марка'}
          />
        </>
      )
    case 107:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'audioBrand'}
            filterOr={'tvBrand'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={karaoke}
            setValue={setKaraoke}
            center={true}
            filter={'gamePc'}
            label={'Караоке'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={acousticType}
            setValue={setAcousticType}
            center={true}
            filter={'acousticType'}
            label={'Тип акустики'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={wifi}
            setValue={setWifi}
            center={true}
            filter={'gamePc'}
            label={'Wi-Fi'}
          />
        </>
      )
    case 108:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'audioBrand'}
            filterOr={'tvBrand'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={portable}
            setValue={setPortable}
            center={true}
            filter={'gamePc'}
            label={'Караоке'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={wifi}
            setValue={setWifi}
            center={true}
            filter={'gamePc'}
            label={'Портативный'}
          />
        </>
      )
    case 109:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'audioBrand'}
            filterOr={'tvBrand'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={wifi}
            setValue={setWifi}
            center={true}
            filter={'gamePc'}
            label={'Wi-Fi'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={karaoke}
            setValue={setKaraoke}
            center={true}
            filter={'gamePc'}
            label={'Караоке'}
          />
        </>
      )
    case 110:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'audioBrand'}
            filterOr={'pcBrand'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={wifi}
            setValue={setWifi}
            center={true}
            filter={'gamePc'}
            label={'Wi-Fi'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={touchScreen}
            setValue={setTouchScreen}
            center={true}
            filter={'gamePc'}
            label={'Сенсорный экран'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={fm}
            setValue={setFm}
            center={true}
            filter={'gamePc'}
            label={'Радио'}
          />
        </>
      )
    case 111:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'audioBrand'}
            filterOr={'pcBrand'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={diagonal}
            setValue={setDiagonal}
            center={true}
            filter={'diagonalTablet'}
            label={'Диагональ экрана'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={displayType}
            setValue={setDisplayType}
            center={true}
            filter={'displayType'}
            label={'Тип дисплея'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={displayColor}
            setValue={setDisplayColor}
            center={true}
            filter={'gamePc'}
            label={'Цветной экран'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={touchScreen}
            setValue={setTouchScreen}
            center={true}
            filter={'gamePc'}
            label={'Сенсорный экран'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={backlight}
            setValue={setBacklight}
            center={true}
            filter={'gamePc'}
            label={'Подсветка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={wifi}
            setValue={setWifi}
            center={true}
            filter={'gamePc'}
            label={'Wi-Fi'}
          />
        </>
      )
    case 112:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'digitalTVBrand'}
            label={'Марка'}
          />
        </>
      )
    case 113:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'receiversBrand'}
            label={'Марка'}
          />
        </>
      )
    case 114:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandHeadphones'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={wireless}
            setValue={setWireless}
            center={true}
            filter={'gamePc'}
            label={'Беспроводные'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={microphone}
            setValue={setMicrophone}
            center={true}
            filter={'gamePc'}
            label={'Микрофон'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={volumeCh}
            setValue={setVolumeCh}
            center={true}
            filter={'gamePc'}
            label={'Регулировка громкости'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={waterN}
            setValue={setWaterN}
            center={true}
            filter={'gamePc'}
            label={'Водонепроницаемость'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={noiseReduction}
            setValue={setNoiseReduction}
            center={true}
            filter={'gamePc'}
            label={'Шумоподавление'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={conType}
            setValue={setConType}
            center={true}
            filter={'conType'}
            label={'Тип подключения'}
          />
        </>
      )
    case 115:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={conType}
            setValue={setConType}
            center={true}
            filter={'conType'}
            label={'Тип подключения'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={connectT}
            setValue={setConnectT}
            center={true}
            filter={'connectT'}
            label={'Разъемы'}
          />
        </>
      )
    case 117:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={scopeOfApplication}
            setValue={setScopeOfApplication}
            center={true}
            filter={'scopeOfApplication'}
            label={'Область применения'}
          />
        </>
      )
    case 118:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandHoods'}
            label={'Марка'}
          />
        </>
      )
    case 122:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={safety}
            setValue={setSafety}
            center={true}
            filter={'safety'}
            label={'Безопасность'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={timer}
            setValue={setTimer}
            center={true}
            filter={'gamePc'}
            label={'Таймер'}
          />
        </>
      )
    case 123:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandDishwashers'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={installationType}
            setValue={setInstallationType}
            center={true}
            filter={'installationType'}
            label={'Тип установки'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={utensilCapacity}
            setValue={setUtensilCapacity}
            center={true}
            filter={'utensilCapacity'}
            label={'Вместимость посуды'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={leakProtection}
            setValue={setLeakProtection}
            center={true}
            filter={'gamePc'}
            label={'Защита от протечек'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={delayStart}
            setValue={setDelayStart}
            center={true}
            filter={'gamePc'}
            label={'Отсрочка запуска'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={periodOfUse}
            setValue={setPeriodOfUse}
            center={true}
            filter={'periodOfUse'}
            label={'Срок использования'}
          />
        </>
      )
    case 126:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandСleaner'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={typeCleaning}
            setValue={setTypeCleaning}
            center={true}
            filter={'typeCleaning'}
            label={'Тип уборки'}
          />
        </>
      )
    case 127:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandDishwashers'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={kg}
            setValue={setKg}
            center={true}
            filter={'kg'}
            label={'Загрузка, кг'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={leakProtection}
            setValue={setLeakProtection}
            center={true}
            filter={'gamePc'}
            label={'Защита от протечек'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={spin}
            setValue={setSpin}
            center={true}
            filter={'spin'}
            label={'Скорость отжима'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={drying}
            setValue={setDrying}
            center={true}
            filter={'gamePc'}
            label={'Сушка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={loadType}
            setValue={setLoadType}
            center={true}
            filter={'loadType'}
            label={'Тип загрузки'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={height}
            setValue={setHeight}
            center={true}
            filter={'height'}
            label={'Высота, см'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={depth}
            setValue={setDepth}
            center={true}
            filter={'depth'}
            label={'Глубина, см'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={swidth}
            setValue={setSWidth}
            center={true}
            filter={'width'}
            label={'Ширина, см'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={periodOfUse}
            setValue={setPeriodOfUse}
            center={true}
            filter={'periodOfUse'}
            label={'Срок использования'}
          />
        </>
      )
    case 129:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandDishwashers'}
            label={'Марка'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={installationType}
            setValue={setInstallationType}
            center={true}
            filter={'installationType'}
            label={'Тип установки'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={height}
            setValue={setHeight}
            center={true}
            filter={'height'}
            label={'Высота'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={periodOfUse}
            setValue={setPeriodOfUse}
            center={true}
            filter={'periodOfUse'}
            label={'Срок использования'}
          />
        </>
      )
    case 130:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={brand}
            setValue={setBrand}
            center={true}
            filter={'brandSewing'}
            label={'Марка'}
          />
        </>
      )
    case 161:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={sex}
            setValue={setSex}
            center={true}
            filter={'sex'}
            label={'Пол'}
          />
        </>
      )
    case 164:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={applicationArea}
            setValue={setApplicationArea}
            center={true}
            filter={'applicationArea'}
            label={'Зона применения'}
          />
        </>
      )
    case 170:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={typeSport}
            setValue={setTypeSport}
            center={true}
            filter={'typeSport'}
            label={'Вид спорта'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={forChild}
            setValue={setForChild}
            center={true}
            filter={'gamePc'}
            label={'Для детей'}
          />
        </>
      )
    case 171:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={frame}
            setValue={setFrame}
            center={true}
            filter={'frame'}
            label={'Рама / Рост велосипедиста'}
          />
        </>
      )
    case 172:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={forChild}
            setValue={setForChild}
            center={true}
            filter={'gamePc'}
            label={'Для детей'}
          />
        </>
      )
    case 173:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={forChild}
            setValue={setForChild}
            center={true}
            filter={'gamePc'}
            label={'Для детей'}
          />
        </>
      )
    case 175:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={forChild}
            setValue={setForChild}
            center={true}
            filter={'gamePc'}
            label={'Для детей'}
          />
        </>
      )
    case 176:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={forChild}
            setValue={setForChild}
            center={true}
            filter={'gamePc'}
            label={'Для детей'}
          />
        </>
      )
    case 177:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={forChild}
            setValue={setForChild}
            center={true}
            filter={'gamePc'}
            label={'Для детей'}
          />
        </>
      )
    case 188:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={platform}
            setValue={setPlatform}
            center={true}
            filter={'platform'}
            label={'Платформа'}
            filterOr={'gameFromPc'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={genre}
            setValue={setGenre}
            center={true}
            filter={'genre'}
            label={'Жанр'}
          />
        </>
      )
    case 189:
      return (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={theme}
            setValue={setTheme}
            center={true}
            filter={'theme'}
            label={'Тематика'}
          />
        </>
      )
    default:
      return <></>
  }
}
export default SubCategoryIdSwitch
