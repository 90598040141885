// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectCategory_swiper__nLcF\\+ {
  width: 100%;
  margin-bottom: 48px;
}

.SelectCategory_slide__70rVs {
  position: relative;
}
.SelectCategory_slide__70rVs a {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
}
.SelectCategory_slide__70rVs img {
  width: 120px;
  height: 120px;
  border-radius: 15px;
  transition: box-shadow 0.4s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.04) 0 2px 4px 0;
}
@media (min-width: 992px) {
  .SelectCategory_slide__70rVs img {
    width: 140px;
    height: 140px;
    border-radius: 15px;
  }
}
@media (max-width: 600px) {
  .SelectCategory_slide__70rVs img {
    width: 90px;
    height: 90px;
  }
}
.SelectCategory_slide__70rVs img:hover {
  box-shadow: rgba(0, 0, 0, 0.08) 0 8px 16px 0;
}

.SelectCategory_slideName__VoUXM {
  font-size: 14px;
  line-height: 1.43;
  font-weight: 600;
  height: 40px;
  width: 100%;
  color: rgb(51, 51, 51);
  white-space: normal;
  text-align: center;
  opacity: 0.8;
  transition: opacity 200ms;
}`, "",{"version":3,"sources":["webpack://./src/components/SelectCategory/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;AACF;;AACA;EACE,kBAAA;AAEF;AADE;EACE,aAAA;EAAc,mBAAA;EAAoB,sBAAA;EAClC,qBAAA;AAKJ;AAHE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uCAAA;EACA,2CAAA;AAKJ;AAJI;EANF;IAOI,YAAA;IACA,aAAA;IACA,mBAAA;EAOJ;AACF;AANI;EAXF;IAYI,WAAA;IACA,YAAA;EASJ;AACF;AARI;EACE,4CAAA;AAUN;;AALA;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,yBAAA;AAQF","sourcesContent":[".swiper{\r\n  width: 100%;\r\n  margin-bottom: 48px;\r\n}\r\n.slide{\r\n  position: relative;\r\n  & a {\r\n    display: flex;align-items: center;flex-direction: column;\r\n    text-decoration: none;\r\n  }\r\n  & img {\r\n    width: 120px;\r\n    height: 120px;\r\n    border-radius: 15px;\r\n    transition: box-shadow 0.4s ease-in-out;\r\n    box-shadow: rgba(0, 0, 0, 0.04) 0 2px 4px 0;\r\n    @media (min-width: 992px) {\r\n      width: 140px;\r\n      height: 140px;\r\n      border-radius: 15px;\r\n    }\r\n    @media (max-width: 600px) {\r\n      width: 90px;\r\n      height: 90px;\r\n    }\r\n    &:hover{\r\n      box-shadow: rgba(0, 0, 0, 0.08) 0 8px 16px 0;\r\n    }\r\n  }\r\n\r\n}\r\n.slideName{\r\n  font-size: 14px;\r\n  line-height: 1.43;\r\n  font-weight: 600;\r\n  height: 40px;\r\n  width: 100%;\r\n  color: rgb(51, 51, 51);\r\n  white-space: normal;\r\n  text-align: center;\r\n  opacity: 0.8;\r\n  transition: opacity 200ms;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swiper": `SelectCategory_swiper__nLcF+`,
	"slide": `SelectCategory_slide__70rVs`,
	"slideName": `SelectCategory_slideName__VoUXM`
};
export default ___CSS_LOADER_EXPORT___;
