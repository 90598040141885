// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectComponent_select__0kZlh {
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  width: 280px;
}

.SelectComponent_options__b2a4r {
  padding: 8px 30px 8px 10px !important;
  line-height: normal;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  color: rgb(57, 57, 57);
  background-color: rgb(255, 255, 255);
  font-weight: normal;
  display: block;
  padding-block-start: 0;
  padding-block-end: 1px;
  min-block-size: 1.2em;
  padding-inline: 2px;
}
.SelectComponent_options__b2a4r:hover {
  background-color: #f9f9f9;
}`, "",{"version":3,"sources":["webpack://./src/components/SelectComponent/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;EACA,aAAA;EACA,YAAA;AACF;;AACA;EACE,qCAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;EACA,sBAAA;EACA,oCAAA;EACA,mBAAA;EACA,cAAA;EACA,sBAAA;EACA,sBAAA;EACA,qBAAA;EACA,mBAAA;AAEF;AADE;EACE,yBAAA;AAGJ","sourcesContent":[".select{\r\n  border-radius: 5px;\r\n  background-color: #f9f9f9;\r\n  color: #333;\r\n  font-size: 16px;\r\n  cursor: pointer;\r\n  outline: none;\r\n  width: 280px;\r\n}\r\n.options{\r\n  padding: 8px 30px 8px 10px!important;\r\n  line-height: normal;\r\n  cursor: pointer;\r\n  position: relative;\r\n  font-size: 14px;\r\n  color: rgb(57, 57, 57);\r\n  background-color: rgb(255, 255, 255);\r\n  font-weight: normal;\r\n  display: block;\r\n  padding-block-start: 0;\r\n  padding-block-end: 1px;\r\n  min-block-size: 1.2em;\r\n  padding-inline: 2px;\r\n  &:hover{\r\n    background-color: #f9f9f9;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `SelectComponent_select__0kZlh`,
	"options": `SelectComponent_options__b2a4r`
};
export default ___CSS_LOADER_EXPORT___;
