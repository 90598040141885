import React from 'react'
import styles from '../index.module.scss'
import SelectComponent from '../../SelectComponent'

function OtherBuildings({
  filtersAll,
  typeBuilding,
  setTypeBuilding,
  transactionType,
  setTransactionType,
  whoPosted,
  setWhoPosted,
  negotiable,
  setNegotiable,
}) {
  return (
    <>
      <div className={styles.border} />
      <div className={styles.title}>Параметры дома</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={typeBuilding}
        setValue={setTypeBuilding}
        center={true}
        filter={'typeBuildingOther'}
        label={'Тип строения'}
      />
      <div className={styles.border} />
      <div className={styles.title}>Условия</div>
      <SelectComponent
        filtersAll={filtersAll}
        value={transactionType}
        setValue={setTransactionType}
        center={true}
        filter={'transactionType'}
        label={'Тип сделки'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={negotiable}
        setValue={setNegotiable}
        center={true}
        filter={'gamePc'}
        label={'Торг возможен'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={whoPosted}
        setValue={setWhoPosted}
        center={true}
        filter={'whoPosted'}
        label={'Кто разместил'}
      />
      <div className={styles.border} />
    </>
  )
}

export default OtherBuildings
