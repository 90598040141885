import React from 'react'
import ThingsElectronicsAndMore from './ThingsElectronicsAndMore'
import { useSelector } from 'react-redux'
import Services from './Services'
import ApplicationsForServices from './ApplicationsForServices'
import RealEstate from './RealEstate'
import Animals from './Animals'
import PassengerCars from './PassengerCars'
import SpecialEquipmentAndMotorcycles from './SpecialEquipmentAndMotorcycles'
import SparePartsAndAutoProducts from './SparePartsAndAutoProducts'
import Vacancies from './Vacancies'
function SelectVariant({
  variant,
  columnId,
  subCategoryId,
  setTypeA,
  typeA,
  brand,
  setBrand,
  state,
  setState,
  color,
  setColor,
  type,
  setType,
  size,
  setSize,
  season,
  setSeason,
  length,
  setLength,
  sex,
  setSex,
  sizeHeight,
  setSizeHeight,
  weightGroup,
  setWeightGroup,
  fasteningC,
  setFasteningC,
  peculiaritiesC,
  setPeculiaritiesC,
  adjustmentsC,
  setAdjustmentsC,
  strollersT,
  setStrollersT,
  strollersBl,
  setStrollersBl,
  strollersCir,
  setStrollersCir,
  operatingOs,
  setOperatingOs,
  builtMemory,
  setBuiltMemory,
  periodOfUse,
  setPeriodOfUse,
  diagonal,
  setDiagonal,
  ozu,
  setOzu,
  camera1,
  setCamera1,
  camera2,
  setCamera2,
  compatibilityA,
  setCompatibilityA,
  compatibilityI,
  setCompatibilityI,
  moistureProtection,
  setMoistureProtection,
  pedometer,
  setPedometer,
  heartMete,
  setHeartMete,
  sleepMete,
  setSleepMete,
  phoneCall,
  setPhoneCall,
  autoResp,
  setAutoResp,
  aon,
  setAon,
  device,
  setDevice,
  amperes,
  setAmperes,
  usbCount,
  setUsbCount,
  connectT,
  setConnectT,
  cable,
  setCable,
  materialCh,
  setMaterialCh,
  categCam,
  setCategCam,
  viewfinder,
  setViewfinder,
  imageStabilizer,
  setImageStabilizer,
  videoQuality,
  setVideoQuality,
  typeSd,
  setTypeSd,
  zoomOp,
  setZoomOp,
  actionCamera,
  setActionCamera,
  r360,
  setR360,
  conType,
  setConType,
  wifi,
  setWifi,
  motionSen,
  setMotionSen,
  microphone,
  setMicrophone,
  mountType,
  setMountType,
  autofocus,
  setAutofocus,
  swivelHead,
  setSwivelHead,
  playVid,
  setPlayVid,
  ssd,
  setSsd,
  videoTy,
  setVideoTy,
  game,
  setGame,
  opticalDrive,
  setOpticalDrive,
  hdmi,
  setHdmi,
  speakers,
  setSpeakers,
  usb,
  setUsb,
  smartTV,
  setSmartTV,
  t3d,
  setT3d,
  hd,
  setHd,
  sizeDisplay,
  setSizeDisplay,
  hdr,
  setHdr,
  karaoke,
  setKaraoke,
  acousticType,
  setAcousticType,
  portable,
  setPortable,
  fm,
  setFm,
  touchScreen,
  setTouchScreen,
  displayType,
  setDisplayType,
  displayColor,
  setDisplayColor,
  backlight,
  setBacklight,
  wireless,
  setWireless,
  volumeCh,
  setVolumeCh,
  waterN,
  setWaterN,
  noiseReduction,
  setNoiseReduction,
  scopeOfApplication,
  setScopeOfApplication,
  safety,
  setSafety,
  timer,
  setTimer,
  installationType,
  setInstallationType,
  utensilCapacity,
  setUtensilCapacity,
  leakProtection,
  setLeakProtection,
  delayStart,
  setDelayStart,
  typeCleaning,
  setTypeCleaning,
  kg,
  setKg,
  spin,
  setSpin,
  drying,
  setDrying,
  loadType,
  setLoadType,
  height,
  setHeight,
  depth,
  setDepth,
  swidth,
  setSWidth,
  applicationArea,
  setApplicationArea,
  forChild,
  setForChild,
  typeSport,
  setTypeSport,
  frame,
  setFrame,
  platform,
  setPlatform,
  genre,
  setGenre,
  theme,
  setTheme,
  priceOf,
  setPriceOf,
  placeDelivery,
  setPlaceDelivery,
  typeService,
  setTypeService,
  apartmentType,
  setApartmentType,
  yearConstruction,
  setYearConstruction,
  houseType,
  setHouseType,
  toTheMetro,
  setToTheMetro,
  elevators,
  setElevators,
  gas,
  setGas,
  infrastructure,
  setInfrastructure,
  roomArea,
  setRoomArea,
  roomsCount,
  setRoomsCount,
  floor,
  setFloor,
  floorH,
  setFloorH,
  totalArea,
  setTotalArea,
  livingArea,
  setLivingArea,
  kitchenArea,
  setKitchenArea,
  ceilingHeight,
  setCeilingHeight,
  repair,
  setRepair,
  bathrooms,
  setBathrooms,
  balcony,
  setBalcony,
  whoPosted,
  setWhoPosted,
  mortgagePossible,
  setMortgagePossible,
  materHouse,
  setMaterHouse,
  romSlipC,
  setRomSlipC,
  electricity,
  setElectricity,
  heating,
  setHeating,
  waterSupply,
  setWaterSupply,
  garage,
  setGarage,
  plotArea,
  setPlotArea,
  plotType,
  setPlotType,
  fridge,
  setFridge,
  dishwasher,
  setDishwasher,
  washingMachine,
  setWashingMachine,
  prepayment,
  setPrepayment,
  publicUtilities,
  setPublicUtilities,
  typeBuilding,
  setTypeBuilding,
  transactionType,
  setTransactionType,
  negotiable,
  setNegotiable,
  breed,
  setBreed,
  productType,
  setProductType,

  yearIssue,
  setYearIssue,
  rul,
  setRul,
  body,
  setBody,
  countDor,
  setCountDor,
  horseW,
  setHorseW,
  engineType,
  setEngineType,
  engineSize,
  setEngineSize,
  transmission,
  setTransmission,
  drive,
  setDrive,
  mileage,
  setMileage,
  stateAuto,
  setStateAuto,
  customsA,
  setCustomsA,
  guarantees,
  setGuarantees,
  pts,
  setPts,
  vin,
  setVin,
  climateControl,
  setClimateControl,
  windowLifters,
  setWindowLifters,
  heatedSeats,
  setHeatedSeats,
  rulR,
  setRulR,
  conditioner,
  setConditioner,
  rulT,
  setRulT,
  tinted,
  setTinted,
  seatRegV,
  setSeatRegV,
  seatRegP,
  setSeatRegP,
  upholstery,
  setUpholstery,
  seatCount,
  setSeatCount,
  luke,
  setLuke,
  steeringWheel,
  setSteeringWheel,
  panoramicRoof,
  setPanoramicRoof,
  centralLock,
  setCentralLock,
  signaling,
  setSignaling,
  immobilizer,
  setImmobilizer,
  alloyWheels,
  setAlloyWheels,
  aeroBody,
  setAeroBody,
  towbar,
  setTowbar,
  winterTires,
  setWinterTires,
  powerSteering,
  setPowerSteering,
  roadAdaptation,
  setRoadAdaptation,
  cruiseControl,
  setCruiseControl,
  headlightsT,
  setHeadlightsT,
  fogLights,
  setFogLights,
  headlightWashers,
  setHeadlightWashers,
  lightSensor,
  setLightSensor,
  electricMirrors,
  setElectricMirrors,
  rainSensor,
  setRainSensor,
  heatedMirrors,
  setHeatedMirrors,
  airbag,
  setAirbag,
  eba,
  setEba,
  abs,
  setAbs,
  esp,
  setEsp,
  asr,
  setAsr,
  boardComputer,
  setBoardComputer,
  gps,
  setGps,
  typeAutoD,
  setTypeAutoD,
  typeAuto,
  setTypeAuto,
  article,
  setArticle,
  diameter,
  setDiameter,
  profession,
  setProfession,
  requiredExperience,
  setRequiredExperience,
  workingHours,
  setWorkingHours,
  employmentType,
  setEmploymentType,
  natureWork,
  setNatureWork,
  otherTerms,
  setOtherTerms,
  company,
  setCompany,
  salary,
  setSalary,
  categoryBusiness,
  setCategoryBusiness,
}) {
  const filtersAll = useSelector((state) => state.filtersIt.filtersAll)
  switch (variant) {
    case 'Вещи, электроника и прочее':
      return (
        <>
          <ThingsElectronicsAndMore
            filtersAll={filtersAll}
            columnId={columnId}
            subCategoryId={subCategoryId}
            setTypeA={setTypeA}
            typeA={typeA}
            brand={brand}
            setBrand={setBrand}
            state={state}
            setState={setState}
            color={color}
            setColor={setColor}
            type={type}
            setType={setType}
            size={size}
            setSize={setSize}
            season={season}
            setSeason={setSeason}
            length={length}
            setLength={setLength}
            sex={sex}
            setSex={setSex}
            sizeHeight={sizeHeight}
            setSizeHeight={setSizeHeight}
            weightGroup={weightGroup}
            setWeightGroup={setWeightGroup}
            fasteningC={fasteningC}
            setFasteningC={setFasteningC}
            peculiaritiesC={peculiaritiesC}
            setPeculiaritiesC={setPeculiaritiesC}
            adjustmentsC={adjustmentsC}
            setAdjustmentsC={setAdjustmentsC}
            strollersT={strollersT}
            setStrollersT={setStrollersT}
            strollersBl={strollersBl}
            setStrollersBl={setStrollersBl}
            strollersCir={strollersCir}
            setStrollersCir={setStrollersCir}
            operatingOs={operatingOs}
            setOperatingOs={setOperatingOs}
            builtMemory={builtMemory}
            setBuiltMemory={setBuiltMemory}
            periodOfUse={periodOfUse}
            setPeriodOfUse={setPeriodOfUse}
            diagonal={diagonal}
            setDiagonal={setDiagonal}
            ozu={ozu}
            setOzu={setOzu}
            camera1={camera1}
            setCamera1={setCamera1}
            camera2={camera2}
            setCamera2={setCamera2}
            compatibilityA={compatibilityA}
            setCompatibilityA={setCompatibilityA}
            compatibilityI={compatibilityI}
            setCompatibilityI={setCompatibilityI}
            moistureProtection={moistureProtection}
            setMoistureProtection={setMoistureProtection}
            pedometer={pedometer}
            setPedometer={setPedometer}
            heartMete={heartMete}
            setHeartMete={setHeartMete}
            sleepMete={sleepMete}
            setSleepMete={setSleepMete}
            phoneCall={phoneCall}
            setPhoneCall={setPhoneCall}
            autoResp={autoResp}
            setAutoResp={setAutoResp}
            aon={aon}
            setAon={setAon}
            device={device}
            setDevice={setDevice}
            amperes={amperes}
            setAmperes={setAmperes}
            usbCount={usbCount}
            setUsbCount={setUsbCount}
            connectT={connectT}
            setConnectT={setConnectT}
            cable={cable}
            setCable={setCable}
            materialCh={materialCh}
            setMaterialCh={setMaterialCh}
            categCam={categCam}
            setCategCam={setCategCam}
            viewfinder={viewfinder}
            setViewfinder={setViewfinder}
            imageStabilizer={imageStabilizer}
            setImageStabilizer={setImageStabilizer}
            videoQuality={videoQuality}
            setVideoQuality={setVideoQuality}
            typeSd={typeSd}
            setTypeSd={setTypeSd}
            zoomOp={zoomOp}
            setZoomOp={setZoomOp}
            actionCamera={actionCamera}
            setActionCamera={setActionCamera}
            r360={r360}
            setR360={setR360}
            conType={conType}
            setConType={setConType}
            wifi={wifi}
            setWifi={setWifi}
            motionSen={motionSen}
            setMotionSen={setMotionSen}
            microphone={microphone}
            setMicrophone={setMicrophone}
            mountType={mountType}
            setMountType={setMountType}
            autofocus={autofocus}
            setAutofocus={setAutofocus}
            swivelHead={swivelHead}
            setSwivelHead={setSwivelHead}
            playVid={playVid}
            setPlayVid={setPlayVid}
            ssd={ssd}
            setSsd={setSsd}
            videoTy={videoTy}
            setVideoTy={setVideoTy}
            game={game}
            setGame={setGame}
            opticalDrive={opticalDrive}
            setOpticalDrive={setOpticalDrive}
            hdmi={hdmi}
            setHdmi={setHdmi}
            speakers={speakers}
            setSpeakers={setSpeakers}
            usb={usb}
            setUsb={setUsb}
            smartTV={smartTV}
            setSmartTV={setSmartTV}
            t3d={t3d}
            setT3d={setT3d}
            hd={hd}
            setHd={setHd}
            sizeDisplay={sizeDisplay}
            setSizeDisplay={setSizeDisplay}
            hdr={hdr}
            setHdr={setHdr}
            karaoke={karaoke}
            setKaraoke={setKaraoke}
            acousticType={acousticType}
            setAcousticType={setAcousticType}
            portable={portable}
            setPortable={setPortable}
            fm={fm}
            setFm={setFm}
            touchScreen={touchScreen}
            setTouchScreen={setTouchScreen}
            displayType={displayType}
            setDisplayType={setDisplayType}
            displayColor={displayColor}
            setDisplayColor={setDisplayColor}
            backlight={backlight}
            setBacklight={setBacklight}
            wireless={wireless}
            setWireless={setWireless}
            volumeCh={volumeCh}
            setVolumeCh={setVolumeCh}
            waterN={waterN}
            setWaterN={setWaterN}
            noiseReduction={noiseReduction}
            setNoiseReduction={setNoiseReduction}
            scopeOfApplication={scopeOfApplication}
            setScopeOfApplication={setScopeOfApplication}
            safety={safety}
            setSafety={setSafety}
            timer={timer}
            setTimer={setTimer}
            installationType={installationType}
            setInstallationType={setInstallationType}
            utensilCapacity={utensilCapacity}
            setUtensilCapacity={setUtensilCapacity}
            leakProtection={leakProtection}
            setLeakProtection={setLeakProtection}
            delayStart={delayStart}
            setDelayStart={setDelayStart}
            typeCleaning={typeCleaning}
            setTypeCleaning={setTypeCleaning}
            kg={kg}
            setKg={setKg}
            spin={spin}
            setSpin={setSpin}
            drying={drying}
            setDrying={setDrying}
            loadType={loadType}
            setLoadType={setLoadType}
            height={height}
            setHeight={setHeight}
            depth={depth}
            setDepth={setDepth}
            swidth={swidth}
            setSWidth={setSWidth}
            applicationArea={applicationArea}
            setApplicationArea={setApplicationArea}
            forChild={forChild}
            setForChild={setForChild}
            typeSport={typeSport}
            setTypeSport={setTypeSport}
            frame={frame}
            setFrame={setFrame}
            platform={platform}
            setPlatform={setPlatform}
            genre={genre}
            setGenre={setGenre}
            theme={theme}
            setTheme={setTheme}
          />
        </>
      )
    case 'Услуги':
      return (
        <>
          <Services
            filtersAll={filtersAll}
            columnId={columnId}
            subCategoryId={subCategoryId}
            priceOf={priceOf}
            setPriceOf={setPriceOf}
            placeDelivery={placeDelivery}
            setPlaceDelivery={setPlaceDelivery}
          />
        </>
      )
    case 'Заявки на услуги':
      return (
        <>
          <ApplicationsForServices
            filtersAll={filtersAll}
            columnId={columnId}
            subCategoryId={subCategoryId}
            priceOf={priceOf}
            setPriceOf={setPriceOf}
            placeDelivery={placeDelivery}
            setPlaceDelivery={setPlaceDelivery}
            typeService={typeService}
            setTypeService={setTypeService}
          />
        </>
      )
    case 'Недвижимость':
      return (
        <>
          {columnId && columnId.value && (
            <RealEstate
              filtersAll={filtersAll}
              columnId={columnId.value}
              subCategoryId={subCategoryId}
              apartmentType={apartmentType}
              setApartmentType={setApartmentType}
              yearConstruction={yearConstruction}
              setYearConstruction={setYearConstruction}
              houseType={houseType}
              setHouseType={setHouseType}
              toTheMetro={toTheMetro}
              setToTheMetro={setToTheMetro}
              elevators={elevators}
              setElevators={setElevators}
              gas={gas}
              setGas={setGas}
              infrastructure={infrastructure}
              setInfrastructure={setInfrastructure}
              roomArea={roomArea}
              setRoomArea={setRoomArea}
              roomsCount={roomsCount}
              setRoomsCount={setRoomsCount}
              floor={floor}
              setFloor={setFloor}
              floorH={floorH}
              setFloorH={setFloorH}
              totalArea={totalArea}
              setTotalArea={setTotalArea}
              livingArea={livingArea}
              setLivingArea={setLivingArea}
              kitchenArea={kitchenArea}
              setKitchenArea={setKitchenArea}
              ceilingHeight={ceilingHeight}
              setCeilingHeight={setCeilingHeight}
              repair={repair}
              setRepair={setRepair}
              bathrooms={bathrooms}
              setBathrooms={setBathrooms}
              balcony={balcony}
              setBalcony={setBalcony}
              periodOfUse={periodOfUse}
              setPeriodOfUse={setPeriodOfUse}
              whoPosted={whoPosted}
              setWhoPosted={setWhoPosted}
              mortgagePossible={mortgagePossible}
              setMortgagePossible={setMortgagePossible}
              materHouse={materHouse}
              setMaterHouse={setMaterHouse}
              romSlipC={romSlipC}
              setRomSlipC={setRomSlipC}
              electricity={electricity}
              setElectricity={setElectricity}
              heating={heating}
              setHeating={setHeating}
              waterSupply={waterSupply}
              setWaterSupply={setWaterSupply}
              garage={garage}
              setGarage={setGarage}
              plotArea={plotArea}
              setPlotArea={setPlotArea}
              plotType={plotType}
              setPlotType={setPlotType}
              fridge={fridge}
              setFridge={setFridge}
              dishwasher={dishwasher}
              setDishwasher={setDishwasher}
              washingMachine={washingMachine}
              setWashingMachine={setWashingMachine}
              prepayment={prepayment}
              setPrepayment={setPrepayment}
              publicUtilities={publicUtilities}
              setPublicUtilities={setPublicUtilities}
              typeBuilding={typeBuilding}
              setTypeBuilding={setTypeBuilding}
              transactionType={transactionType}
              setTransactionType={setTransactionType}
              negotiable={negotiable}
              setNegotiable={setNegotiable}
            />
          )}
        </>
      )
    case 'Животные':
      return (
        <>
          {columnId && columnId.value && (
            <Animals
              filtersAll={filtersAll}
              columnId={columnId.value}
              breed={breed}
              setBreed={setBreed}
              productType={productType}
              setProductType={setProductType}
              type={type}
              setType={setType}
            />
          )}
        </>
      )
    case 'Легковые автомобили':
      return (
        <>
          {columnId && columnId.value && (
            <PassengerCars
              filtersAll={filtersAll}
              columnId={columnId.value}
              brand={brand}
              setBrand={setBrand}
              yearIssue={yearIssue}
              setYearIssue={setYearIssue}
              rul={rul}
              setRul={setRul}
              body={body}
              setBody={setBody}
              countDor={countDor}
              setCountDor={setCountDor}
              horseW={horseW}
              setHorseW={setHorseW}
              engineType={engineType}
              setEngineType={setEngineType}
              engineSize={engineSize}
              setEngineSize={setEngineSize}
              transmission={transmission}
              setTransmission={setTransmission}
              drive={drive}
              setDrive={setDrive}
              mileage={mileage}
              setMileage={setMileage}
              stateAuto={stateAuto}
              setStateAuto={setStateAuto}
              customsA={customsA}
              setCustomsA={setCustomsA}
              guarantees={guarantees}
              setGuarantees={setGuarantees}
              pts={pts}
              setPts={setPts}
              vin={vin}
              setVin={setVin}
              climateControl={climateControl}
              setClimateControl={setClimateControl}
              windowLifters={windowLifters}
              setWindowLifters={setWindowLifters}
              heatedSeats={heatedSeats}
              setHeatedSeats={setHeatedSeats}
              rulR={rulR}
              setRulR={setRulR}
              conditioner={conditioner}
              setConditioner={setConditioner}
              rulT={rulT}
              setRulT={setRulT}
              tinted={tinted}
              setTinted={setTinted}
              seatRegV={seatRegV}
              setSeatRegV={setSeatRegV}
              seatRegP={seatRegP}
              setSeatRegP={setSeatRegP}
              upholstery={upholstery}
              setUpholstery={setUpholstery}
              seatCount={seatCount}
              setSeatCount={setSeatCount}
              luke={luke}
              setLuke={setLuke}
              steeringWheel={steeringWheel}
              setSteeringWheel={setSteeringWheel}
              panoramicRoof={panoramicRoof}
              setPanoramicRoof={setPanoramicRoof}
              centralLock={centralLock}
              setCentralLock={setCentralLock}
              signaling={signaling}
              setSignaling={setSignaling}
              immobilizer={immobilizer}
              setImmobilizer={setImmobilizer}
              alloyWheels={alloyWheels}
              setAlloyWheels={setAlloyWheels}
              aeroBody={aeroBody}
              setAeroBody={setAeroBody}
              towbar={towbar}
              setTowbar={setTowbar}
              winterTires={winterTires}
              setWinterTires={setWinterTires}
              powerSteering={powerSteering}
              setPowerSteering={setPowerSteering}
              roadAdaptation={roadAdaptation}
              setRoadAdaptation={setRoadAdaptation}
              cruiseControl={cruiseControl}
              setCruiseControl={setCruiseControl}
              headlightsT={headlightsT}
              setHeadlightsT={setHeadlightsT}
              fogLights={fogLights}
              setFogLights={setFogLights}
              headlightWashers={headlightWashers}
              setHeadlightWashers={setHeadlightWashers}
              lightSensor={lightSensor}
              setLightSensor={setLightSensor}
              electricMirrors={electricMirrors}
              setElectricMirrors={setElectricMirrors}
              rainSensor={rainSensor}
              setRainSensor={setRainSensor}
              heatedMirrors={heatedMirrors}
              setHeatedMirrors={setHeatedMirrors}
              airbag={airbag}
              setAirbag={setAirbag}
              eba={eba}
              setEba={setEba}
              abs={abs}
              setAbs={setAbs}
              esp={esp}
              setEsp={setEsp}
              asr={asr}
              setAsr={setAsr}
              boardComputer={boardComputer}
              setBoardComputer={setBoardComputer}
              gps={gps}
              setGps={setGps}
              typeAutoD={typeAutoD}
              setTypeAutoD={setTypeAutoD}
              typeAuto={typeAuto}
              setTypeAuto={setTypeAuto}
              article={article}
              setArticle={setArticle}
              diameter={diameter}
              setDiameter={setDiameter}
              type={type}
              setType={setType}
              color={color}
              setColor={setColor}
              opticalDrive={opticalDrive}
              setOpticalDrive={setOpticalDrive}
            />
          )}
        </>
      )
    case 'Спецтехника и мотоциклы':
      return (
        <>
          {columnId && columnId.value && (
            <SpecialEquipmentAndMotorcycles
              filtersAll={filtersAll}
              columnId={columnId.label}
              typeA={typeA}
              setTypeA={setTypeA}
              brand={brand}
              setBrand={setBrand}
              yearIssue={yearIssue}
              setYearIssue={setYearIssue}
              rul={rul}
              setRul={setRul}
              body={body}
              setBody={setBody}
              countDor={countDor}
              setCountDor={setCountDor}
              horseW={horseW}
              setHorseW={setHorseW}
              engineType={engineType}
              setEngineType={setEngineType}
              engineSize={engineSize}
              setEngineSize={setEngineSize}
              transmission={transmission}
              setTransmission={setTransmission}
              drive={drive}
              setDrive={setDrive}
              mileage={mileage}
              setMileage={setMileage}
              stateAuto={stateAuto}
              setStateAuto={setStateAuto}
              customsA={customsA}
              setCustomsA={setCustomsA}
              guarantees={guarantees}
              setGuarantees={setGuarantees}
              pts={pts}
              setPts={setPts}
              vin={vin}
              setVin={setVin}
              climateControl={climateControl}
              setClimateControl={setClimateControl}
              windowLifters={windowLifters}
              setWindowLifters={setWindowLifters}
              heatedSeats={heatedSeats}
              setHeatedSeats={setHeatedSeats}
              rulR={rulR}
              setRulR={setRulR}
              conditioner={conditioner}
              setConditioner={setConditioner}
              rulT={rulT}
              setRulT={setRulT}
              tinted={tinted}
              setTinted={setTinted}
              seatRegV={seatRegV}
              setSeatRegV={setSeatRegV}
              seatRegP={seatRegP}
              setSeatRegP={setSeatRegP}
              upholstery={upholstery}
              setUpholstery={setUpholstery}
              seatCount={seatCount}
              setSeatCount={setSeatCount}
              luke={luke}
              setLuke={setLuke}
              steeringWheel={steeringWheel}
              setSteeringWheel={setSteeringWheel}
              panoramicRoof={panoramicRoof}
              setPanoramicRoof={setPanoramicRoof}
              centralLock={centralLock}
              setCentralLock={setCentralLock}
              signaling={signaling}
              setSignaling={setSignaling}
              immobilizer={immobilizer}
              setImmobilizer={setImmobilizer}
              alloyWheels={alloyWheels}
              setAlloyWheels={setAlloyWheels}
              aeroBody={aeroBody}
              setAeroBody={setAeroBody}
              towbar={towbar}
              setTowbar={setTowbar}
              winterTires={winterTires}
              setWinterTires={setWinterTires}
              powerSteering={powerSteering}
              setPowerSteering={setPowerSteering}
              roadAdaptation={roadAdaptation}
              setRoadAdaptation={setRoadAdaptation}
              cruiseControl={cruiseControl}
              setCruiseControl={setCruiseControl}
              headlightsT={headlightsT}
              setHeadlightsT={setHeadlightsT}
              fogLights={fogLights}
              setFogLights={setFogLights}
              headlightWashers={headlightWashers}
              setHeadlightWashers={setHeadlightWashers}
              lightSensor={lightSensor}
              setLightSensor={setLightSensor}
              electricMirrors={electricMirrors}
              setElectricMirrors={setElectricMirrors}
              rainSensor={rainSensor}
              setRainSensor={setRainSensor}
              heatedMirrors={heatedMirrors}
              setHeatedMirrors={setHeatedMirrors}
              airbag={airbag}
              setAirbag={setAirbag}
              eba={eba}
              setEba={setEba}
              abs={abs}
              setAbs={setAbs}
              esp={esp}
              setEsp={setEsp}
              asr={asr}
              setAsr={setAsr}
              boardComputer={boardComputer}
              setBoardComputer={setBoardComputer}
              gps={gps}
              setGps={setGps}
              typeAutoD={typeAutoD}
              setTypeAutoD={setTypeAutoD}
              typeAuto={typeAuto}
              setTypeAuto={setTypeAuto}
              article={article}
              setArticle={setArticle}
              diameter={diameter}
              setDiameter={setDiameter}
              type={type}
              setType={setType}
              color={color}
              setColor={setColor}
              opticalDrive={opticalDrive}
              setOpticalDrive={setOpticalDrive}
              state={state}
              setState={setState}
            />
          )}
        </>
      )
    case 'Запчасти и автотовары':
      return (
        <>
          {columnId && columnId.label && (
            <SparePartsAndAutoProducts
              filtersAll={filtersAll}
              columnId={columnId.label}
              typeA={typeA}
              setTypeA={setTypeA}
              brand={brand}
              setBrand={setBrand}
              yearIssue={yearIssue}
              setYearIssue={setYearIssue}
              rul={rul}
              setRul={setRul}
              body={body}
              setBody={setBody}
              countDor={countDor}
              setCountDor={setCountDor}
              horseW={horseW}
              setHorseW={setHorseW}
              engineType={engineType}
              setEngineType={setEngineType}
              engineSize={engineSize}
              setEngineSize={setEngineSize}
              transmission={transmission}
              setTransmission={setTransmission}
              drive={drive}
              setDrive={setDrive}
              mileage={mileage}
              setMileage={setMileage}
              stateAuto={stateAuto}
              setStateAuto={setStateAuto}
              customsA={customsA}
              setCustomsA={setCustomsA}
              guarantees={guarantees}
              setGuarantees={setGuarantees}
              pts={pts}
              setPts={setPts}
              vin={vin}
              setVin={setVin}
              climateControl={climateControl}
              setClimateControl={setClimateControl}
              windowLifters={windowLifters}
              setWindowLifters={setWindowLifters}
              heatedSeats={heatedSeats}
              setHeatedSeats={setHeatedSeats}
              rulR={rulR}
              setRulR={setRulR}
              conditioner={conditioner}
              setConditioner={setConditioner}
              rulT={rulT}
              setRulT={setRulT}
              tinted={tinted}
              setTinted={setTinted}
              seatRegV={seatRegV}
              setSeatRegV={setSeatRegV}
              seatRegP={seatRegP}
              setSeatRegP={setSeatRegP}
              upholstery={upholstery}
              setUpholstery={setUpholstery}
              seatCount={seatCount}
              setSeatCount={setSeatCount}
              luke={luke}
              setLuke={setLuke}
              steeringWheel={steeringWheel}
              setSteeringWheel={setSteeringWheel}
              panoramicRoof={panoramicRoof}
              setPanoramicRoof={setPanoramicRoof}
              centralLock={centralLock}
              setCentralLock={setCentralLock}
              signaling={signaling}
              setSignaling={setSignaling}
              immobilizer={immobilizer}
              setImmobilizer={setImmobilizer}
              alloyWheels={alloyWheels}
              setAlloyWheels={setAlloyWheels}
              aeroBody={aeroBody}
              setAeroBody={setAeroBody}
              towbar={towbar}
              setTowbar={setTowbar}
              winterTires={winterTires}
              setWinterTires={setWinterTires}
              powerSteering={powerSteering}
              setPowerSteering={setPowerSteering}
              roadAdaptation={roadAdaptation}
              setRoadAdaptation={setRoadAdaptation}
              cruiseControl={cruiseControl}
              setCruiseControl={setCruiseControl}
              headlightsT={headlightsT}
              setHeadlightsT={setHeadlightsT}
              fogLights={fogLights}
              setFogLights={setFogLights}
              headlightWashers={headlightWashers}
              setHeadlightWashers={setHeadlightWashers}
              lightSensor={lightSensor}
              setLightSensor={setLightSensor}
              electricMirrors={electricMirrors}
              setElectricMirrors={setElectricMirrors}
              rainSensor={rainSensor}
              setRainSensor={setRainSensor}
              heatedMirrors={heatedMirrors}
              setHeatedMirrors={setHeatedMirrors}
              airbag={airbag}
              setAirbag={setAirbag}
              eba={eba}
              setEba={setEba}
              abs={abs}
              setAbs={setAbs}
              esp={esp}
              setEsp={setEsp}
              asr={asr}
              setAsr={setAsr}
              boardComputer={boardComputer}
              setBoardComputer={setBoardComputer}
              gps={gps}
              setGps={setGps}
              typeAutoD={typeAutoD}
              setTypeAutoD={setTypeAutoD}
              typeAuto={typeAuto}
              setTypeAuto={setTypeAuto}
              article={article}
              setArticle={setArticle}
              diameter={diameter}
              setDiameter={setDiameter}
              type={type}
              setType={setType}
              color={color}
              setColor={setColor}
              opticalDrive={opticalDrive}
              setOpticalDrive={setOpticalDrive}
              state={state}
              setState={setState}
            />
          )}
        </>
      )
    case 'Вакансии':
      return (
        <>
          {columnId && columnId.label && (
            <Vacancies
              filtersAll={filtersAll}
              columnId={columnId.label}
              profession={profession}
              setProfession={setProfession}
              requiredExperience={requiredExperience}
              setRequiredExperience={setRequiredExperience}
              workingHours={workingHours}
              setWorkingHours={setWorkingHours}
              employmentType={employmentType}
              setEmploymentType={setEmploymentType}
              natureWork={natureWork}
              setNatureWork={setNatureWork}
              otherTerms={otherTerms}
              setOtherTerms={setOtherTerms}
              company={company}
              setCompany={setCompany}
              salary={salary}
              setSalary={setSalary}
              categoryBusiness={categoryBusiness}
              setCategoryBusiness={setCategoryBusiness}
            />
          )}
        </>
      )
    default:
      return <></>
  }
}

export default SelectVariant
