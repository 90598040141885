// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.ListColumnCreator_columnsBox__VJW4v {
  display: inline-block;
  font-size: 14px;
  color: rgb(143, 143, 143);
  line-height: 24px;
  cursor: pointer;
  margin-right: 10px;
}
.ListColumnCreator_columnsBox__VJW4v:after {
  content: "•";
  margin-left: 9px;
  position: relative;
  top: 1px;
  color: rgb(143, 143, 143);
}
.ListColumnCreator_columnsBox__VJW4v:hover {
  color: rgb(112, 146, 254);
}

.ListColumnCreator_setColumnId__MaE\\+E {
  background: none;
  border: none;
  font: inherit;
  color: inherit;
  padding: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ListColumnCreator/index.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,qBAAA;EACA,eAAA;EACA,yBAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;AAEF;AADE;EACE,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,QAAA;EACA,yBAAA;AAGJ;AADE;EACE,yBAAA;AAGJ;;AAAA;EACE,gBAAA;EACA,YAAA;EACA,aAAA;EACA,cAAA;EACA,UAAA;EACA,eAAA;EACA,wCAAA;EACA,aAAA;AAGF","sourcesContent":["@charset \"UTF-8\";\n.columnsBox {\n  display: inline-block;\n  font-size: 14px;\n  color: rgb(143, 143, 143);\n  line-height: 24px;\n  cursor: pointer;\n  margin-right: 10px;\n}\n.columnsBox:after {\n  content: \"•\";\n  margin-left: 9px;\n  position: relative;\n  top: 1px;\n  color: rgb(143, 143, 143);\n}\n.columnsBox:hover {\n  color: rgb(112, 146, 254);\n}\n\n.setColumnId {\n  background: none;\n  border: none;\n  font: inherit;\n  color: inherit;\n  padding: 0;\n  cursor: pointer;\n  -webkit-tap-highlight-color: transparent;\n  outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"columnsBox": `ListColumnCreator_columnsBox__VJW4v`,
	"setColumnId": `ListColumnCreator_setColumnId__MaE+E`
};
export default ___CSS_LOADER_EXPORT___;
