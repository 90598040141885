// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IsActiveSubCategoryList_linkItem__y7Xcc {
  width: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
}

.IsActiveSubCategoryList_link__Smnyl {
  color: rgb(112, 146, 254);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-size: 14px;
  line-height: 1.71;
  text-decoration: none;
}
.IsActiveSubCategoryList_link__Smnyl:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/IsActiveSubCategoryList/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AACA;EACE,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,oBAAA;EACA,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;AAEF;AADE;EACE,0BAAA;AAGJ","sourcesContent":[".linkItem{\r\n  width: 100%;\r\n  padding-top: 3px;\r\n  padding-bottom: 3px;\r\n}\r\n.link{\r\n  color: rgb(112, 146, 254);\r\n  font-weight: normal;\r\n  font-style: normal;\r\n  font-stretch: normal;\r\n  letter-spacing: normal;\r\n  font-size: 14px;\r\n  line-height: 1.71;\r\n  text-decoration: none;\r\n  &:hover{\r\n    text-decoration: underline;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkItem": `IsActiveSubCategoryList_linkItem__y7Xcc`,
	"link": `IsActiveSubCategoryList_link__Smnyl`
};
export default ___CSS_LOADER_EXPORT___;
