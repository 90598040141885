import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import { getCategoryUrl } from '../../api'
function CategoryList({ category, index, setCategoryIndex, categoryIndex }) {
  const [categoryActive, setCategoryActive] = useState(false)
  useEffect(() => {
    setCategoryActive(categoryIndex === index)
  }, [categoryIndex, index])
  return (
    <>
      <li
        onMouseOut={() => {
          setCategoryActive(true)
        }}
        onMouseOver={() => {
          setCategoryIndex(index)
        }}
      >
        <div
          className={styles.itemContainer}
          style={{
            backgroundImage: `url(${getCategoryUrl(category.icon)})`,
            backgroundColor: categoryActive ? 'rgb(245, 245, 245)' : '',
          }}
        >
          {index === 0 ? (
            <div className={styles.nameNull}>{category.name}</div>
          ) : (
            <Link to={`/${category.categoryRoute}`} className={styles.name}>
              {category.name}
            </Link>
          )}
        </div>
      </li>
    </>
  )
}
export default CategoryList
