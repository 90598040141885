import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { getCategoryUrl } from '../../api'
import { Link } from 'react-router-dom'
function ColumnList({
  column,
  columnIndex,
  index,
  setColumnIndex,
  categoryRoute,
}) {
  const [categoryActive, setCategoryActive] = useState(false)
  useEffect(() => {
    setCategoryActive(columnIndex === index)
  }, [columnIndex, index])
  return (
    <li
      onMouseOut={() => {
        setCategoryActive(true)
      }}
      onMouseOver={() => {
        setColumnIndex(index)
      }}
    >
      <div
        className={column.icon ? styles.itemContainer : styles.itemContainer2}
        style={{
          backgroundImage: column.icon
            ? `url(${getCategoryUrl(column.icon)})`
            : 'none',
          backgroundColor: categoryActive ? 'rgb(245, 245, 245)' : '',
        }}
      >
        <Link
          to={
            categoryRoute
              ? `/${categoryRoute}/${column.columnRoute}`
              : `/${column.columnRoute}`
          }
          className={styles.columnName}
        >
          {column.name}
        </Link>
      </div>
    </li>
  )
}
export default ColumnList
