import { baseInstance } from './index'

export const allCategoryInfo = () => {
  return baseInstance.get('/all_category')
}

export const allAdvertisementsInfo = () => {
  return baseInstance.get('/allAdvertisements')
}
export const allSwiperInfo = () => {
  return baseInstance.get('/swiperHome')
}

export const productCreate = (formData, onUploadProgress) => {
  return baseInstance.post('/product_create', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  })
}
