import React from 'react'
import SelectComponent from '../../SelectComponent'
import SubCategoryIdSwitch from '../../SubCategoryIdSwitch'

function ThingsElectronicsAndMore({
  filtersAll,
  subCategoryId,
  setTypeA,
  typeA,
  brand,
  setBrand,
  state,
  setState,
  color,
  setColor,
  type,
  setType,
  size,
  setSize,
  season,
  setSeason,
  length,
  setLength,
  sex,
  setSex,
  sizeHeight,
  setSizeHeight,
  weightGroup,
  setWeightGroup,
  fasteningC,
  setFasteningC,
  peculiaritiesC,
  setPeculiaritiesC,
  adjustmentsC,
  setAdjustmentsC,
  strollersT,
  setStrollersT,
  strollersBl,
  setStrollersBl,
  strollersCir,
  setStrollersCir,
  operatingOs,
  setOperatingOs,
  builtMemory,
  setBuiltMemory,
  periodOfUse,
  setPeriodOfUse,
  diagonal,
  setDiagonal,
  ozu,
  setOzu,
  camera1,
  setCamera1,
  camera2,
  setCamera2,
  compatibilityA,
  setCompatibilityA,
  compatibilityI,
  setCompatibilityI,
  moistureProtection,
  setMoistureProtection,
  pedometer,
  setPedometer,
  heartMete,
  setHeartMete,
  sleepMete,
  setSleepMete,
  phoneCall,
  setPhoneCall,
  autoResp,
  setAutoResp,
  aon,
  setAon,
  device,
  setDevice,
  amperes,
  setAmperes,
  usbCount,
  setUsbCount,
  connectT,
  setConnectT,
  cable,
  setCable,
  materialCh,
  setMaterialCh,
  categCam,
  setCategCam,
  viewfinder,
  setViewfinder,
  imageStabilizer,
  setImageStabilizer,
  videoQuality,
  setVideoQuality,
  typeSd,
  setTypeSd,
  zoomOp,
  setZoomOp,
  actionCamera,
  setActionCamera,
  r360,
  setR360,
  conType,
  setConType,
  wifi,
  setWifi,
  motionSen,
  setMotionSen,
  microphone,
  setMicrophone,
  mountType,
  setMountType,
  autofocus,
  setAutofocus,
  swivelHead,
  setSwivelHead,
  playVid,
  setPlayVid,
  ssd,
  setSsd,
  videoTy,
  setVideoTy,
  game,
  setGame,
  opticalDrive,
  setOpticalDrive,
  hdmi,
  setHdmi,
  speakers,
  setSpeakers,
  usb,
  setUsb,
  smartTV,
  setSmartTV,
  t3d,
  setT3d,
  hd,
  setHd,
  sizeDisplay,
  setSizeDisplay,
  hdr,
  setHdr,
  karaoke,
  setKaraoke,
  acousticType,
  setAcousticType,
  portable,
  setPortable,
  fm,
  setFm,
  touchScreen,
  setTouchScreen,
  displayType,
  setDisplayType,
  displayColor,
  setDisplayColor,
  backlight,
  setBacklight,
  wireless,
  setWireless,
  volumeCh,
  setVolumeCh,
  waterN,
  setWaterN,
  noiseReduction,
  setNoiseReduction,
  scopeOfApplication,
  setScopeOfApplication,
  safety,
  setSafety,
  timer,
  setTimer,
  installationType,
  setInstallationType,
  utensilCapacity,
  setUtensilCapacity,
  leakProtection,
  setLeakProtection,
  delayStart,
  setDelayStart,
  typeCleaning,
  setTypeCleaning,
  kg,
  setKg,
  spin,
  setSpin,
  drying,
  setDrying,
  loadType,
  setLoadType,
  height,
  setHeight,
  depth,
  setDepth,
  swidth,
  setSWidth,
  applicationArea,
  setApplicationArea,
  forChild,
  setForChild,
  typeSport,
  setTypeSport,
  frame,
  setFrame,
  platform,
  setPlatform,
  genre,
  setGenre,
  theme,
  setTheme,
}) {
  const isDisabled =
    subCategoryId &&
    subCategoryId.value !== 19 &&
    subCategoryId.value !== 33 &&
    subCategoryId.value !== 49 &&
    subCategoryId.value !== 50 &&
    subCategoryId.value !== 61 &&
    subCategoryId.value !== 62 &&
    subCategoryId.value !== 63 &&
    subCategoryId.value !== 64 &&
    subCategoryId.value !== 65 &&
    subCategoryId.value !== 66 &&
    subCategoryId.value !== 67 &&
    subCategoryId.value !== 68 &&
    subCategoryId.value !== 69 &&
    subCategoryId.value !== 70 &&
    subCategoryId.value !== 71 &&
    subCategoryId.value !== 72 &&
    subCategoryId.value !== 77 &&
    subCategoryId.value !== 82 &&
    subCategoryId.value !== 89 &&
    subCategoryId.value !== 90 &&
    subCategoryId.value !== 107 &&
    subCategoryId.value !== 111 &&
    subCategoryId.value !== 134 &&
    subCategoryId.value !== 146 &&
    subCategoryId.value !== 149 &&
    subCategoryId.value !== 157 &&
    subCategoryId.value !== 169 &&
    subCategoryId.value !== 184 &&
    subCategoryId.value !== 188 &&
    subCategoryId.value !== 195 &&
    subCategoryId.value !== 196

  const isDisabled2 =
    subCategoryId && subCategoryId.value !== 29 && subCategoryId.value !== 39

  const isDisabled3 =
    subCategoryId &&
    subCategoryId.value !== 51 &&
    subCategoryId.value !== 52 &&
    subCategoryId.value !== 53 &&
    subCategoryId.value !== 54 &&
    subCategoryId.value !== 55 &&
    subCategoryId.value !== 56 &&
    subCategoryId.value !== 57 &&
    subCategoryId.value !== 58 &&
    subCategoryId.value !== 59 &&
    subCategoryId.value !== 60 &&
    subCategoryId.value !== 73 &&
    subCategoryId.value !== 74 &&
    subCategoryId.value !== 75 &&
    subCategoryId.value !== 76 &&
    subCategoryId.value !== 78 &&
    subCategoryId.value !== 80 &&
    subCategoryId.value !== 81 &&
    subCategoryId.value !== 83 &&
    subCategoryId.value !== 84 &&
    subCategoryId.value !== 85 &&
    subCategoryId.value !== 86 &&
    subCategoryId.value !== 87 &&
    subCategoryId.value !== 88 &&
    subCategoryId.value !== 91 &&
    subCategoryId.value !== 92 &&
    subCategoryId.value !== 93 &&
    subCategoryId.value !== 94 &&
    subCategoryId.value !== 95 &&
    subCategoryId.value !== 96 &&
    subCategoryId.value !== 97 &&
    subCategoryId.value !== 98 &&
    subCategoryId.value !== 99 &&
    subCategoryId.value !== 100 &&
    subCategoryId.value !== 101 &&
    subCategoryId.value !== 102 &&
    subCategoryId.value !== 103 &&
    subCategoryId.value !== 104 &&
    subCategoryId.value !== 105 &&
    subCategoryId.value !== 106 &&
    subCategoryId.value !== 108 &&
    subCategoryId.value !== 109 &&
    subCategoryId.value !== 110 &&
    subCategoryId.value !== 112 &&
    subCategoryId.value !== 113 &&
    subCategoryId.value !== 115 &&
    subCategoryId.value !== 116 &&
    subCategoryId.value !== 117 &&
    subCategoryId.value !== 118 &&
    subCategoryId.value !== 119 &&
    subCategoryId.value !== 120 &&
    subCategoryId.value !== 121 &&
    subCategoryId.value !== 122 &&
    subCategoryId.value !== 123 &&
    subCategoryId.value !== 124 &&
    subCategoryId.value !== 125 &&
    subCategoryId.value !== 126 &&
    subCategoryId.value !== 127 &&
    subCategoryId.value !== 128 &&
    subCategoryId.value !== 129 &&
    subCategoryId.value !== 130 &&
    subCategoryId.value !== 131 &&
    subCategoryId.value !== 132 &&
    subCategoryId.value !== 133 &&
    subCategoryId.value !== 135 &&
    subCategoryId.value !== 136 &&
    subCategoryId.value !== 137 &&
    subCategoryId.value !== 138 &&
    subCategoryId.value !== 139 &&
    subCategoryId.value !== 140 &&
    subCategoryId.value !== 141 &&
    subCategoryId.value !== 142 &&
    subCategoryId.value !== 143 &&
    subCategoryId.value !== 144 &&
    subCategoryId.value !== 145 &&
    subCategoryId.value !== 147 &&
    subCategoryId.value !== 148 &&
    subCategoryId.value !== 150 &&
    subCategoryId.value !== 151 &&
    subCategoryId.value !== 152 &&
    subCategoryId.value !== 153 &&
    subCategoryId.value !== 154 &&
    subCategoryId.value !== 155 &&
    subCategoryId.value !== 156 &&
    subCategoryId.value !== 158 &&
    subCategoryId.value !== 159 &&
    subCategoryId.value !== 160 &&
    subCategoryId.value !== 161 &&
    subCategoryId.value !== 162 &&
    subCategoryId.value !== 163 &&
    subCategoryId.value !== 164 &&
    subCategoryId.value !== 165 &&
    subCategoryId.value !== 166 &&
    subCategoryId.value !== 167 &&
    subCategoryId.value !== 168 &&
    subCategoryId.value !== 170 &&
    subCategoryId.value !== 172 &&
    subCategoryId.value !== 173 &&
    subCategoryId.value !== 174 &&
    subCategoryId.value !== 175 &&
    subCategoryId.value !== 176 &&
    subCategoryId.value !== 177 &&
    subCategoryId.value !== 178 &&
    subCategoryId.value !== 179 &&
    subCategoryId.value !== 180 &&
    subCategoryId.value !== 181 &&
    subCategoryId.value !== 182 &&
    subCategoryId.value !== 183 &&
    subCategoryId.value !== 185 &&
    subCategoryId.value !== 186 &&
    subCategoryId.value !== 187 &&
    subCategoryId.value !== 189 &&
    subCategoryId.value !== 190 &&
    subCategoryId.value !== 191 &&
    subCategoryId.value !== 192 &&
    subCategoryId.value !== 193 &&
    subCategoryId.value !== 194

  return (
    <>
      {subCategoryId && subCategoryId.value ? (
        <>
          {isDisabled && isDisabled2 && (
            <SelectComponent
              filtersAll={filtersAll}
              value={typeA}
              setValue={setTypeA}
              center={true}
              filter={subCategoryId.value}
              label={'Тип'}
            />
          )}
          <SubCategoryIdSwitch
            subCategoryId={subCategoryId.value}
            filtersAll={filtersAll}
            brand={brand}
            setBrand={setBrand}
            size={size}
            setSize={setSize}
            season={season}
            setSeason={setSeason}
            length={length}
            setLength={setLength}
            sex={sex}
            setSex={setSex}
            sizeHeight={sizeHeight}
            setSizeHeight={setSizeHeight}
            weightGroup={weightGroup}
            setWeightGroup={setWeightGroup}
            fasteningC={fasteningC}
            setFasteningC={setFasteningC}
            peculiaritiesC={peculiaritiesC}
            setPeculiaritiesC={setPeculiaritiesC}
            adjustmentsC={adjustmentsC}
            setAdjustmentsC={setAdjustmentsC}
            strollersT={strollersT}
            setStrollersT={setStrollersT}
            strollersBl={strollersBl}
            setStrollersBl={setStrollersBl}
            strollersCir={strollersCir}
            setStrollersCir={setStrollersCir}
            operatingOs={operatingOs}
            setOperatingOs={setOperatingOs}
            builtMemory={builtMemory}
            setBuiltMemory={setBuiltMemory}
            periodOfUse={periodOfUse}
            setPeriodOfUse={setPeriodOfUse}
            diagonal={diagonal}
            setDiagonal={setDiagonal}
            ozu={ozu}
            setOzu={setOzu}
            camera1={camera1}
            setCamera1={setCamera1}
            camera2={camera2}
            setCamera2={setCamera2}
            compatibilityA={compatibilityA}
            setCompatibilityA={setCompatibilityA}
            compatibilityI={compatibilityI}
            setCompatibilityI={setCompatibilityI}
            moistureProtection={moistureProtection}
            setMoistureProtection={setMoistureProtection}
            pedometer={pedometer}
            setPedometer={setPedometer}
            heartMete={heartMete}
            setHeartMete={setHeartMete}
            sleepMete={sleepMete}
            setSleepMete={setSleepMete}
            phoneCall={phoneCall}
            setPhoneCall={setPhoneCall}
            autoResp={autoResp}
            setAutoResp={setAutoResp}
            aon={aon}
            setAon={setAon}
            device={device}
            setDevice={setDevice}
            amperes={amperes}
            setAmperes={setAmperes}
            usbCount={usbCount}
            setUsbCount={setUsbCount}
            connectT={connectT}
            setConnectT={setConnectT}
            cable={cable}
            setCable={setCable}
            materialCh={materialCh}
            setMaterialCh={setMaterialCh}
            categCam={categCam}
            setCategCam={setCategCam}
            viewfinder={viewfinder}
            setViewfinder={setViewfinder}
            imageStabilizer={imageStabilizer}
            setImageStabilizer={setImageStabilizer}
            videoQuality={videoQuality}
            setVideoQuality={setVideoQuality}
            typeSd={typeSd}
            setTypeSd={setTypeSd}
            zoomOp={zoomOp}
            setZoomOp={setZoomOp}
            actionCamera={actionCamera}
            setActionCamera={setActionCamera}
            r360={r360}
            setR360={setR360}
            conType={conType}
            setConType={setConType}
            wifi={wifi}
            setWifi={setWifi}
            motionSen={motionSen}
            setMotionSen={setMotionSen}
            microphone={microphone}
            setMicrophone={setMicrophone}
            mountType={mountType}
            setMountType={setMountType}
            autofocus={autofocus}
            setAutofocus={setAutofocus}
            swivelHead={swivelHead}
            setSwivelHead={setSwivelHead}
            playVid={playVid}
            setPlayVid={setPlayVid}
            ssd={ssd}
            setSsd={setSsd}
            videoTy={videoTy}
            setVideoTy={setVideoTy}
            game={game}
            setGame={setGame}
            opticalDrive={opticalDrive}
            setOpticalDrive={setOpticalDrive}
            hdmi={hdmi}
            setHdmi={setHdmi}
            speakers={speakers}
            setSpeakers={setSpeakers}
            usb={usb}
            setUsb={setUsb}
            smartTV={smartTV}
            setSmartTV={setSmartTV}
            t3d={t3d}
            setT3d={setT3d}
            hd={hd}
            setHd={setHd}
            sizeDisplay={sizeDisplay}
            setSizeDisplay={setSizeDisplay}
            hdr={hdr}
            setHdr={setHdr}
            karaoke={karaoke}
            setKaraoke={setKaraoke}
            acousticType={acousticType}
            setAcousticType={setAcousticType}
            portable={portable}
            setPortable={setPortable}
            fm={fm}
            setFm={setFm}
            touchScreen={touchScreen}
            setTouchScreen={setTouchScreen}
            displayType={displayType}
            setDisplayType={setDisplayType}
            displayColor={displayColor}
            setDisplayColor={setDisplayColor}
            backlight={backlight}
            setBacklight={setBacklight}
            wireless={wireless}
            setWireless={setWireless}
            volumeCh={volumeCh}
            setVolumeCh={setVolumeCh}
            waterN={waterN}
            setWaterN={setWaterN}
            noiseReduction={noiseReduction}
            setNoiseReduction={setNoiseReduction}
            scopeOfApplication={scopeOfApplication}
            setScopeOfApplication={setScopeOfApplication}
            safety={safety}
            setSafety={setSafety}
            timer={timer}
            setTimer={setTimer}
            installationType={installationType}
            setInstallationType={setInstallationType}
            utensilCapacity={utensilCapacity}
            setUtensilCapacity={setUtensilCapacity}
            leakProtection={leakProtection}
            setLeakProtection={setLeakProtection}
            delayStart={delayStart}
            setDelayStart={setDelayStart}
            typeCleaning={typeCleaning}
            setTypeCleaning={setTypeCleaning}
            kg={kg}
            setKg={setKg}
            spin={spin}
            setSpin={setSpin}
            drying={drying}
            setDrying={setDrying}
            loadType={loadType}
            setLoadType={setLoadType}
            height={height}
            setHeight={setHeight}
            depth={depth}
            setDepth={setDepth}
            swidth={swidth}
            setSWidth={setSWidth}
            applicationArea={applicationArea}
            setApplicationArea={setApplicationArea}
            forChild={forChild}
            setForChild={setForChild}
            typeSport={typeSport}
            setTypeSport={setTypeSport}
            frame={frame}
            setFrame={setFrame}
            platform={platform}
            setPlatform={setPlatform}
            genre={genre}
            setGenre={setGenre}
            theme={theme}
            setTheme={setTheme}
          />
          {isDisabled && isDisabled3 && (
            <SelectComponent
              filtersAll={filtersAll}
              value={color}
              setValue={setColor}
              center={true}
              filter={'colorProduct'}
              label={'Цвет'}
            />
          )}
          <SelectComponent
            filtersAll={filtersAll}
            value={state}
            setValue={setState}
            center={true}
            filter={'stateProduct'}
            label={'Состояние товара'}
          />
          <SelectComponent
            filtersAll={filtersAll}
            value={type}
            setValue={setType}
            center={true}
            filter={'typeProduct'}
            label={'Тип объявления'}
          />
        </>
      ) : (
        <>
          <></>
        </>
      )}
    </>
  )
}

export default ThingsElectronicsAndMore
