import React from 'react'
import { Link } from 'react-router-dom'
import styles from './index.module.scss'

function SubCategoryList({ subCategory, columnRoute, categoryRoute }) {
  return (
    <li>
      <Link
        to={
          categoryRoute
            ? `/${categoryRoute}/${columnRoute}/${subCategory.subCategoryRoute}`
            : `/${columnRoute}/${subCategory.subCategoryRoute}`
        }
        className={styles.link}
      >
        {subCategory.name}
      </Link>
    </li>
  )
}
export default SubCategoryList
