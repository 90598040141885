import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PageWrapper from '../../../components/PageWrapper'

function WomenWardrobe() {
  const category = useSelector((state) => state.app.category)
  const filtersAll = useSelector((state) => state.filtersIt.filtersAll)
  const categoryFind = category
    .find((c) => c.id === 1)
    .columns.find((c) => c.id === 1).subCategory
  const brands = filtersAll.filter((item) => item.type === 'accessoriesBrand')
  return (
    <PageWrapper
      categoryRoute={null}
      columnRoute={'zhenskaya-odezhda'}
      subCategoryRoute={null}
      category={category}
      categoryFind={categoryFind}
      isBrands={true}
      isSubCategory={true}
      brands={brands}
      categoryId={1}
      columnsId={1}
      title={'Женская одежда'}
    />
  )
}

export default WomenWardrobe
