// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColumnList_itemContainer__Vdagz, .ColumnList_itemContainer2__oXgLe {
  display: block;
  cursor: default;
  padding: 8px 16px 8px 52px;
  margin: 0 0 0 1px;
  background-size: 24px;
  background-position: 14px 8px;
  background-repeat: no-repeat;
}

.ColumnList_itemContainer2__oXgLe {
  padding: 8px 16px 8px 10px;
  width: 250px;
}

.ColumnList_columnName__9Oy5M {
  color: rgb(51, 51, 51);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-decoration: underline;
  font-size: 14px;
  line-height: 1.71;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ColumnList/index.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,0BAAA;EACA,iBAAA;EACA,qBAAA;EACA,6BAAA;EACA,4BAAA;AACF;;AACA;EACE,0BAAA;EACA,YAAA;AAEF;;AAAA;EACE,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,oBAAA;EACA,sBAAA;EACA,0BAAA;EACA,eAAA;EACA,iBAAA;EACA,wCAAA;EACA,aAAA;AAGF","sourcesContent":[".itemContainer, .itemContainer2{\r\n  display: block;\r\n  cursor: default;\r\n  padding: 8px 16px 8px 52px;\r\n  margin: 0 0 0 1px;\r\n  background-size: 24px;\r\n  background-position: 14px 8px;\r\n  background-repeat: no-repeat;\r\n}\r\n.itemContainer2{\r\n  padding: 8px 16px 8px 10px;\r\n  width: 250px;\r\n}\r\n.columnName{\r\n  color: rgb(51, 51, 51);\r\n  font-weight: normal;\r\n  font-style: normal;\r\n  font-stretch: normal;\r\n  letter-spacing: normal;\r\n  text-decoration: underline;\r\n  font-size: 14px;\r\n  line-height: 1.71;\r\n  -webkit-tap-highlight-color: transparent;\r\n  outline: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemContainer": `ColumnList_itemContainer__Vdagz`,
	"itemContainer2": `ColumnList_itemContainer2__oXgLe`,
	"columnName": `ColumnList_columnName__9Oy5M`
};
export default ___CSS_LOADER_EXPORT___;
