import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import appReducer from './app.reducer'
import userReducer from './user.reducer'
import authReducer from './auth.reducer'
import filtersReducer from './filters.reducer'

const rootReducer = (history) =>
  combineReducers({
    auth: authReducer,
    app: appReducer,
    user: userReducer,
    filtersIt: filtersReducer,
    router: connectRouter(history),
  })
export default rootReducer
