import React from 'react'
import VariantContainer from '../VariantContainer'
import styles from './index.module.scss'
import Select from 'react-select'

function SelectComponent({
  filtersAll,
  filter,
  setValue,
  value,
  label,
  center = false,
  filterOr = null,
  filterOr2 = null,
  filterOr3 = null,
  isMulti = false,
}) {
  const options = [
    ...filtersAll
      .filter(
        (i) =>
          i.type === filter ||
          i.type === filterOr ||
          i.type === filterOr2 ||
          i.type === filterOr3,
      )
      .filter(
        (item, index, self) =>
          index === self.findIndex((el) => el.name === item.name),
      )
      .map((item) => {
        return {
          value: item.name,
          label: item.name,
        }
      }),
  ]
  return (
    <>
      <VariantContainer label={label} center={center}>
        <Select
          className={styles.select}
          value={value}
          placeholder={'Не выбрано'}
          onChange={(newValue) => {
            setValue(newValue)
          }}
          options={options}
          isClearable
          isSearchable
          isMulti={isMulti}
        />
      </VariantContainer>
    </>
  )
}
export default SelectComponent
