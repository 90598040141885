import React from 'react'
import SelectComponent from '../../SelectComponent'
import InputForm from '../../InputForm'

function Vacancies({
  filtersAll,
  columnId,
  profession,
  setProfession,
  requiredExperience,
  setRequiredExperience,
  workingHours,
  setWorkingHours,
  employmentType,
  setEmploymentType,
  natureWork,
  setNatureWork,
  otherTerms,
  setOtherTerms,
  company,
  setCompany,
}) {
  const isDisabledProfession =
    columnId === 'Добыча сырья, энергетика' ||
    columnId === 'Управление недвижимостью'

  return (
    <>
      {!isDisabledProfession && (
        <SelectComponent
          filtersAll={filtersAll}
          value={profession}
          setValue={setProfession}
          center={true}
          filter={'professionType'}
          label={'Профессия'}
        />
      )}
      <SelectComponent
        filtersAll={filtersAll}
        value={requiredExperience}
        setValue={setRequiredExperience}
        center={true}
        filter={'requiredExperience'}
        label={'Требуемый опыт'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={workingHours}
        setValue={setWorkingHours}
        center={true}
        filter={'workingHours'}
        label={'График работы'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={employmentType}
        setValue={setEmploymentType}
        center={true}
        filter={'employmentType'}
        label={'Тип занятости'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={natureWork}
        setValue={setNatureWork}
        center={true}
        filter={'natureWork'}
        label={'Характер работы'}
      />
      <SelectComponent
        filtersAll={filtersAll}
        value={otherTerms}
        setValue={setOtherTerms}
        center={true}
        filter={'otherTerms'}
        label={'Другие условия'}
      />
      <InputForm label={'Компания'} value={company} setValue={setCompany} />
    </>
  )
}
export default Vacancies
