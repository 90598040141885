import React from 'react'
import styles from './index.module.scss'
function Characteristics({ name, label }) {
  if (name) {
    return (
      <div className={styles.categ}>
        <dt className={styles.desctopInfoCWLable}>{label}</dt>
        <dd className={styles.subsLab}>{name}</dd>
      </div>
    )
  }
}
export default Characteristics
