import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as actions from '../../../actions'
import { useDispatch, useSelector } from 'react-redux'
import styles from './index.module.scss'
import SwiperList from '../../../components/SwiperList'
import { Field, Form, Formik } from 'formik'
import AuthInput from '../../../components/AuthInput'
import FormAuthContainer from '../../../components/FormAuthContainer'
import { Link } from 'react-router-dom'
import routesLik from '../../../constants/routes.constants'

function LoginPage() {
  const dispatch = useDispatch()
  const swiperAdvertising = useSelector((state) => state.app.swiperAdvertising)
  const coords = useSelector((state) => state.app.coords)
  const [width, setWidth] = useState(window.innerWidth <= 600)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth <= 600)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const onDisableAll = () => {
    dispatch(actions.toggleShowUser(false))
    dispatch(actions.toggleCategoryMobile(false))
    dispatch(actions.toggleCategory(false))
    dispatch(actions.toggleSubCategory(null))
  }

  const initialValues = useMemo(
    () => ({
      email: '',
      password: '',
    }),
    [],
  )
  const submitLoginForm = useCallback(
    (credentials) => {
      dispatch(
        actions.signIn({ ...credentials, coords: JSON.stringify(coords) }),
      )
    },
    [dispatch, coords],
  )

  return (
    <main className={styles.main} onClick={onDisableAll}>
      {!width && swiperAdvertising && swiperAdvertising.length > 0 && (
        <SwiperList swiperAdvertising={swiperAdvertising} />
      )}
      <div className={`${styles.main} ${styles.loginContainer}`}>
        <Formik initialValues={initialValues} onSubmit={submitLoginForm}>
          {() => (
            <Form className={styles.formContainer}>
              <FormAuthContainer>
                <h3 className={styles.titleForm}>Войти в кабинет</h3>
                <Field
                  type="email"
                  name="email"
                  placeholder={'Ваш Email'}
                  component={AuthInput}
                  className={styles.inputForm}
                />
                <Field
                  type="password"
                  name="password"
                  placeholder={'Ваш Пароль'}
                  component={AuthInput}
                  className={styles.inputForm}
                />
                <button type={'submit'} className={styles.logIn}>
                  Войти
                </button>
                <Link to={routesLik.register} className={styles.linkRegister}>
                  Регистрация
                </Link>
              </FormAuthContainer>
            </Form>
          )}
        </Formik>
      </div>
    </main>
  )
}

export default LoginPage
