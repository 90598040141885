// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CategoryList_itemContainer__YrZ5Q {
  display: block;
  cursor: default;
  padding: 8px 16px 8px 48px;
  margin: 0px 0px 0px -12px;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: 12px 8px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.CategoryList_itemContainer__YrZ5Q:hover {
  background-color: rgb(245, 245, 245);
}
.CategoryList_itemContainer__YrZ5Q:hover .CategoryList_name__j0tdK {
  text-decoration: none;
}

.CategoryList_nameNull__nHLT9 {
  color: rgb(51, 51, 51);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-size: 14px;
  line-height: 1.71;
}

.CategoryList_name__j0tdK {
  color: rgb(51, 51, 51);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-decoration: underline;
  font-size: 14px;
  line-height: 1.71;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/CategoryList/index.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,0BAAA;EACA,yBAAA;EACA,qBAAA;EACA,4BAAA;EACA,6BAAA;EACA,2BAAA;EACA,8BAAA;AACF;AAAE;EACE,oCAAA;AAEJ;AAAE;EACE,qBAAA;AAEJ;;AACA;EACE,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,oBAAA;EACA,sBAAA;EACA,eAAA;EACA,iBAAA;AAEF;;AAAA;EACE,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,oBAAA;EACA,sBAAA;EACA,0BAAA;EACA,eAAA;EACA,iBAAA;EACA,wCAAA;EACA,aAAA;AAGF","sourcesContent":[".itemContainer{\r\n  display: block;\r\n  cursor: default;\r\n  padding: 8px 16px 8px 48px;\r\n  margin: 0px 0px 0px -12px;\r\n  background-size: 24px;\r\n  background-repeat: no-repeat;\r\n  background-position: 12px 8px;\r\n  border-top-left-radius: 4px;\r\n  border-bottom-left-radius: 4px;\r\n  &:hover{\r\n    background-color: rgb(245, 245, 245);\r\n  }\r\n  &:hover .name{\r\n    text-decoration: none;\r\n  }\r\n}\r\n.nameNull{\r\n  color: rgb(51, 51, 51);\r\n  font-weight: normal;\r\n  font-style: normal;\r\n  font-stretch: normal;\r\n  letter-spacing: normal;\r\n  font-size: 14px;\r\n  line-height: 1.71;\r\n}\r\n.name{\r\n  color: rgb(51, 51, 51);\r\n  font-weight: normal;\r\n  font-style: normal;\r\n  font-stretch: normal;\r\n  letter-spacing: normal;\r\n  text-decoration: underline;\r\n  font-size: 14px;\r\n  line-height: 1.71;\r\n  -webkit-tap-highlight-color: transparent;\r\n  outline: none;\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemContainer": `CategoryList_itemContainer__YrZ5Q`,
	"name": `CategoryList_name__j0tdK`,
	"nameNull": `CategoryList_nameNull__nHLT9`
};
export default ___CSS_LOADER_EXPORT___;
