// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VariantContainer_flexCreatorIn__0XiDk {
  display: flex;
  margin-bottom: 20px;
}

.VariantContainer_flexCreatorInS__FG1IT {
  align-items: flex-start;
}

.VariantContainer_flexCreatorInC__tVBIW {
  align-items: center;
}

.VariantContainer_labelW__E4\\+4D {
  display: flex;
  max-width: 24.3%;
  width: 100%;
  font-size: 14px;
  color: rgb(143, 143, 143);
  text-align: left;
  font-weight: normal;
  line-height: 20px;
  padding: 5px 0;
}

.VariantContainer_labelS__Y-Rf- {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 75%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/VariantContainer/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;;AACA;EACE,uBAAA;AAEF;;AAAA;EACE,mBAAA;AAGF;;AADA;EACE,aAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;EACA,yBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;AAIF;;AAFA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,cAAA;EACA,WAAA;AAKF","sourcesContent":[".flexCreatorIn{\r\n  display: flex;\r\n  margin-bottom: 20px;\r\n}\r\n.flexCreatorInS{\r\n  align-items: flex-start;\r\n}\r\n.flexCreatorInC{\r\n  align-items: center;\r\n}\r\n.labelW{\r\n  display: flex;\r\n  max-width: 24.3%;\r\n  width: 100%;\r\n  font-size: 14px;\r\n  color: rgb(143, 143, 143);\r\n  text-align: left;\r\n  font-weight: normal;\r\n  line-height: 20px;\r\n  padding: 5px 0;\r\n}\r\n.labelS{\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: flex-start;\r\n  max-width: 75%;\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexCreatorIn": `VariantContainer_flexCreatorIn__0XiDk`,
	"flexCreatorInS": `VariantContainer_flexCreatorInS__FG1IT`,
	"flexCreatorInC": `VariantContainer_flexCreatorInC__tVBIW`,
	"labelW": `VariantContainer_labelW__E4+4D`,
	"labelS": `VariantContainer_labelS__Y-Rf-`
};
export default ___CSS_LOADER_EXPORT___;
