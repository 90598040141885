import React from 'react'

function Footer() {
  return (
    <footer style={{ paddingTop: '25px', paddingBottom: '25px' }}>
      <></>
    </footer>
  )
}
export default Footer
