import React from 'react'
import SelectComponent from '../../SelectComponent'
import InputForm from '../../InputForm'

function SparePartsAndAutoProducts({
  filtersAll,
  columnId,
  typeA,
  setTypeA,
  typeAuto,
  setTypeAuto,
  brand,
  setBrand,
  diameter,
  setDiameter,
  state,
  setState,
  article,
  setArticle,
  type,
  setType,
}) {
  const isEnabled76 = columnId === 'Запчасти'
  const isEnabled77 = columnId === 'Шины и диски'
  const isEnabled78 =
    columnId !== 'Масла и автохимия' &&
    columnId !== 'Автоэлектроника и GPS' &&
    columnId !== 'Аксессуары и инструменты' &&
    columnId !== 'Аудио и видео' &&
    columnId !== 'Противоугонные устройства' &&
    columnId !== 'Багажные системы и прицепы' &&
    columnId !== 'Мотоэкипировка'
  const isEnabledAll = columnId !== 'Другое'
  return (
    <>
      {isEnabledAll && (
        <>
          <SelectComponent
            filtersAll={filtersAll}
            value={typeA}
            setValue={setTypeA}
            center={true}
            filter={columnId}
            label={'Тип'}
          />
          {isEnabled78 && (
            <SelectComponent
              filtersAll={filtersAll}
              value={typeAuto}
              setValue={setTypeAuto}
              center={true}
              filter={'typeAuto'}
              label={'Вид транспорта'}
            />
          )}
          {isEnabled76 && (
            <SelectComponent
              filtersAll={filtersAll}
              value={brand}
              setValue={setBrand}
              center={true}
              filter={'brandCar'}
              label={'Марка'}
            />
          )}
          {isEnabled77 && (
            <SelectComponent
              filtersAll={filtersAll}
              value={diameter}
              setValue={setDiameter}
              center={true}
              filter={'diameter'}
              label={'Диаметр, дюймов'}
            />
          )}
          {isEnabled78 && (
            <SelectComponent
              filtersAll={filtersAll}
              value={state}
              setValue={setState}
              center={true}
              filter={'stateProduct'}
              label={'Состояние товара'}
            />
          )}
          {isEnabled76 && (
            <InputForm
              label={'Артикул запчасти'}
              value={article}
              setValue={setArticle}
            />
          )}
          <SelectComponent
            filtersAll={filtersAll}
            value={type}
            setValue={setType}
            center={true}
            filter={'typeProduct'}
            label={'Тип объявления'}
          />
        </>
      )}
    </>
  )
}
export default SparePartsAndAutoProducts
