// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileMain_mobileMain__Eb-Yv {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFFFFF;
  z-index: 100;
}

.MobileMain_mainContainer__p1496 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-inline: 25px;
  height: 100%;
  overflow: auto;
}
@media (max-width: 600px) {
  .MobileMain_mainContainer__p1496 {
    padding-inline: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/MobileMain/index.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,mBAAA;EACA,YAAA;AACF;;AACA;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,oBAAA;EACA,YAAA;EACA,cAAA;AAEF;AADE;EAPF;IAQI,oBAAA;EAIF;AACF","sourcesContent":[".mobileMain{\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  background: #FFFFFF;\r\n  z-index: 100;\r\n}\r\n.mainContainer{\r\n  display: flex;\r\n  align-items: flex-start;\r\n  flex-direction: column;\r\n  padding-inline: 25px;\r\n  height: 100%;\r\n  overflow: auto;\r\n  @media (max-width: 600px) {\r\n    padding-inline: 15px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileMain": `MobileMain_mobileMain__Eb-Yv`,
	"mainContainer": `MobileMain_mainContainer__p1496`
};
export default ___CSS_LOADER_EXPORT___;
