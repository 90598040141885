import { takeEvery, call, put, all, take, fork } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import * as ActionTypes from '../constants/app.constants'
import * as actions from '../actions/app.actions'
import * as api from '../api/app.api'
import { socketUrl } from '../api'
import { END, eventChannel } from 'redux-saga'

function createUploader(action) {
  let emit
  const chan = eventChannel((emitter) => {
    emit = emitter
    return () => {}
  })
  const formData = new FormData()
  action.album.forEach((image) => {
    formData.append('images', image)
  })
  formData.append('data', JSON.stringify(action))
  const onUploadProgress = ({ total, loaded }) => {
    const percentage = Math.round((loaded * 100) / total)
    emit(percentage)
    if (percentage === 100) emit(END)
  }
  const uploadPromise = api.productCreate(formData, onUploadProgress)
  return [uploadPromise, chan]
}

function* uploadProgressWatcher(chan) {
  while (true) {
    const progress = yield take(chan)
    yield put(actions.productUploadImagesProgress(progress))
  }
}

const joinUserOnline = (action) => {
  return new Promise(() => {
    socketUrl.emit('joinUser', action)
  })
}

export function* userOnline(action) {
  try {
    yield call(joinUserOnline, action.payload)
  } catch (error) {
    toast.error(error.message)
  }
}

export function* allCategory() {
  try {
    const response = yield call(api.allCategoryInfo)
    if (response) {
      yield put(actions.allCategorySuccess(response))
    }
  } catch (error) {
    yield put(actions.allCategoryError(error.message))
    toast.error(error.message)
  }
}
export function* allAdvertisements() {
  try {
    const response = yield call(api.allAdvertisementsInfo)
    if (response) {
      yield put(actions.allAdvertisementsSuccess(response))
    }
  } catch (error) {
    yield put(actions.allAdvertisementsError(error.message))
    toast.error(error.message)
  }
}

export function* allSwiper() {
  try {
    const response = yield call(api.allSwiperInfo)
    if (response) {
      yield put(actions.allSwiperSuccess(response))
    }
  } catch (error) {
    yield put(actions.allSwiperError(error.message))
    toast.error(error.message)
  }
}

export function* productCreate(action) {
  try {
    const [uploadPromise, chan] = yield call(createUploader, action.payload)
    yield fork(uploadProgressWatcher, chan)
    const response = yield call(() => uploadPromise)
    if (response) {
      yield put(actions.productCreateSuccess(response))
      toast.success('Объявление успешно создано')
    }
  } catch (error) {
    yield put(actions.productCreateError(error.message))
    toast.error(error.message)
  }
}

export default function* appSagas() {
  yield all([
    takeEvery(ActionTypes.ONLINE_USER_INFO_REQUEST, userOnline),
    takeEvery(ActionTypes.ALL_CATEGORY_INFO_REQUEST, allCategory),
    takeEvery(ActionTypes.ALL_ADVERTISEMENTS_INFO_REQUEST, allAdvertisements),
    takeEvery(ActionTypes.ALL_SWIPER_INFO_REQUEST, allSwiper),
    takeEvery(ActionTypes.PRODUCT_CREATE_REQUEST, productCreate),
  ])
}
