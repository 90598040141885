// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputForm_flexCreatorIn__9eHUS {
  display: flex;
  margin-bottom: 20px;
}

.InputForm_flexCreatorInS__IUdUK {
  align-items: flex-start;
}

.InputForm_flexCreatorInC__NbUKl {
  align-items: center;
}

.InputForm_disabled__l5wEh {
  pointer-events: none;
  opacity: 0.4;
}

.InputForm_labelW__TsHnk {
  display: flex;
  max-width: 24.3%;
  width: 100%;
  font-size: 14px;
  color: rgb(143, 143, 143);
  text-align: left;
  font-weight: normal;
  line-height: 20px;
  padding: 5px 0;
}

.InputForm_labelS__33KnW {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 75%;
  width: 100%;
}

.InputForm_inputPriceC__UYaJL {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
}

.InputForm_inputPrice__hZVJ- {
  outline: none;
  box-shadow: none;
  box-sizing: border-box;
  padding: 0 10px;
  text-align: left;
  appearance: none;
  height: 32px;
  font-size: 14px;
  color: rgb(57, 57, 57);
  border-radius: 4px;
  border: 1px solid rgb(235, 235, 235);
  background-color: rgb(255, 255, 255);
  min-width: 0;
  width: 100%;
  max-width: 250px;
}`, "",{"version":3,"sources":["webpack://./src/components/InputForm/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;;AACA;EACE,uBAAA;AAEF;;AAAA;EACE,mBAAA;AAGF;;AADA;EACE,oBAAA;EACA,YAAA;AAIF;;AAFA;EACE,aAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;EACA,yBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;AAKF;;AAHA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,cAAA;EACA,WAAA;AAMF;;AAJA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;AAOF;;AALA;EACE,aAAA;EACA,gBAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;EACA,oCAAA;EACA,oCAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;AAQF","sourcesContent":[".flexCreatorIn{\r\n  display: flex;\r\n  margin-bottom: 20px;\r\n}\r\n.flexCreatorInS{\r\n  align-items: flex-start;\r\n}\r\n.flexCreatorInC{\r\n  align-items: center;\r\n}\r\n.disabled {\r\n  pointer-events: none;\r\n  opacity: 0.4;\r\n}\r\n.labelW{\r\n  display: flex;\r\n  max-width: 24.3%;\r\n  width: 100%;\r\n  font-size: 14px;\r\n  color: rgb(143, 143, 143);\r\n  text-align: left;\r\n  font-weight: normal;\r\n  line-height: 20px;\r\n  padding: 5px 0;\r\n}\r\n.labelS{\r\n  position: relative;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: flex-start;\r\n  max-width: 75%;\r\n  width: 100%;\r\n}\r\n.inputPriceC{\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 15px;\r\n  width: 100%;\r\n}\r\n.inputPrice{\r\n  outline: none;\r\n  box-shadow: none;\r\n  box-sizing: border-box;\r\n  padding: 0 10px;\r\n  text-align: left;\r\n  appearance: none;\r\n  height: 32px;\r\n  font-size: 14px;\r\n  color: rgb(57, 57, 57);\r\n  border-radius: 4px;\r\n  border: 1px solid rgb(235, 235, 235);\r\n  background-color: rgb(255, 255, 255);\r\n  min-width: 0;\r\n  width: 100%;\r\n  max-width: 250px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexCreatorIn": `InputForm_flexCreatorIn__9eHUS`,
	"flexCreatorInS": `InputForm_flexCreatorInS__IUdUK`,
	"flexCreatorInC": `InputForm_flexCreatorInC__NbUKl`,
	"disabled": `InputForm_disabled__l5wEh`,
	"labelW": `InputForm_labelW__TsHnk`,
	"labelS": `InputForm_labelS__33KnW`,
	"inputPriceC": `InputForm_inputPriceC__UYaJL`,
	"inputPrice": `InputForm_inputPrice__hZVJ-`
};
export default ___CSS_LOADER_EXPORT___;
