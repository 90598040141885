import React from 'react'
import { Link } from 'react-router-dom'
import styles from './index.module.scss'

function IsActiveSubCategoryList({ subCategory, categoryRoute, columnRoute }) {
  return (
    <div className={styles.linkItem}>
      <Link
        className={styles.link}
        to={
          categoryRoute
            ? `/${categoryRoute}/${columnRoute}/${subCategory.subCategoryRoute}`
            : `/${columnRoute}/${subCategory.subCategoryRoute}`
        }
      >
        {subCategory.name}
      </Link>
    </div>
  )
}
export default IsActiveSubCategoryList
