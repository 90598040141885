import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../actions'
import styles from './index.module.scss'
import SwiperList from '../../../components/SwiperList'
import { Field, Form, Formik } from 'formik'
import FormAuthContainer from '../../../components/FormAuthContainer'
import AuthInput from '../../../components/AuthInput'
import { Link } from 'react-router-dom'
import routesLik from '../../../constants/routes.constants'

function RegisterPage() {
  const dispatch = useDispatch()
  const swiperAdvertising = useSelector((state) => state.app.swiperAdvertising)
  const coords = useSelector((state) => state.app.coords)

  const [width, setWidth] = useState(window.innerWidth <= 600)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth <= 600)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const onDisableAll = () => {
    dispatch(actions.toggleShowUser(false))
    dispatch(actions.toggleCategoryMobile(false))
    dispatch(actions.toggleCategory(false))
    dispatch(actions.toggleSubCategory(null))
  }

  const initialValues = useMemo(
    () => ({
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      password: '',
      confirmPassword: '',
    }),
    [],
  )
  const submitRegisterForm = useCallback(
    (credentials) => {
      dispatch(actions.signUp({ ...credentials, coords: coords }))
    },
    [dispatch, coords],
  )

  return (
    <main className={styles.main} onClick={onDisableAll}>
      {!width && swiperAdvertising && swiperAdvertising.length > 0 && (
        <SwiperList swiperAdvertising={swiperAdvertising} />
      )}
      <div className={`${styles.main} ${styles.registerContainer}`}>
        <Formik initialValues={initialValues} onSubmit={submitRegisterForm}>
          {() => (
            <Form className={styles.formContainer}>
              <FormAuthContainer>
                <h3 className={styles.titleForm}>Регистрация</h3>
                <div className={styles.fieldFlex}>
                  <Field
                    type="text"
                    name="firstName"
                    placeholder={'Ваш имя'}
                    component={AuthInput}
                    className={styles.input}
                  />
                  <Field
                    type="text"
                    name="lastName"
                    placeholder={'Ваш Фамилия'}
                    component={AuthInput}
                    className={styles.input}
                  />
                </div>
                <Field
                  type="phone"
                  name="phone"
                  placeholder={'Ваш Телефон'}
                  component={AuthInput}
                  className={styles.inputForm}
                />
                <Field
                  type="email"
                  name="email"
                  placeholder={'Ваш Email'}
                  component={AuthInput}
                  className={styles.inputForm}
                />
                <div className={styles.fieldFlex}>
                  <Field
                    type="password"
                    name="password"
                    placeholder={'Пароль'}
                    component={AuthInput}
                    className={styles.input}
                  />
                  <Field
                    type="password"
                    name="confirmPassword"
                    placeholder={'Повторите пароль'}
                    component={AuthInput}
                    className={styles.input}
                  />
                </div>
                <button type={'submit'} className={styles.logIn}>
                  Регистрация
                </button>
                <Link to={routesLik.login} className={styles.linkRegister}>
                  Войти
                </Link>
              </FormAuthContainer>
            </Form>
          )}
        </Formik>
      </div>
    </main>
  )
}

export default RegisterPage
