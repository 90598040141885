import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import * as actions from '../../actions'
import styles from './index.module.scss'
import { getCategoryUrl } from '../../api'
import ListColumnCreator from '../ListColumnCreator'

function CategoryCreatorList({ category, setColumnId }) {
  const dispatch = useDispatch()

  const stageChange = useCallback(
    (callback) => {
      dispatch(actions.productCreateStage(callback.name))
      dispatch(actions.categoryId(callback.id))
    },
    [dispatch],
  )

  return (
    <div
      className={styles.roleButon}
      onClick={() => {
        stageChange(category)
      }}
    >
      <img
        src={getCategoryUrl(category.icon)}
        alt={category.name}
        className={styles.icon}
      />
      <div className={styles.columnContainer}>
        <h3 className={styles.categoryName}>{category.name}</h3>
        <ul className={styles.listColumn}>
          {category.columns.map((item, index) => (
            <ListColumnCreator
              columns={item}
              key={index}
              setColumnId={setColumnId}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}

export default CategoryCreatorList
