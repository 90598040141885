// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageContainer_main__S8bY3 {
  position: relative;
  flex: 1 1 auto;
  background: linear-gradient(rgb(255, 255, 255) 0px, rgb(250, 250, 250) 222px);
}

.PageContainer_rowContainer__5JHDj {
  display: flex;
  align-items: flex-start;
  padding-inline: 10px;
}
@media (max-width: 600px) {
  .PageContainer_rowContainer__5JHDj {
    padding-inline: 10px;
  }
}

.PageContainer_col1__KOID9 {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 24%;
  width: 100%;
  padding-inline: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/PageContainer/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,6EAAA;AACF;;AACA;EACE,aAAA;EACA,uBAAA;EACA,oBAAA;AAEF;AADE;EAJF;IAKI,oBAAA;EAIF;AACF;;AAFA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,cAAA;EACA,WAAA;EACA,oBAAA;AAKF","sourcesContent":[".main{\r\n  position: relative;\r\n  flex: 1 1 auto;\r\n  background: linear-gradient(rgb(255, 255, 255) 0px, rgb(250, 250, 250) 222px);\r\n}\r\n.rowContainer{\r\n  display: flex;\r\n  align-items: flex-start;\r\n  padding-inline: 10px;\r\n  @media (max-width: 600px) {\r\n    padding-inline: 10px;\r\n  }\r\n}\r\n.col1{\r\n  display: flex;\r\n  align-items: center;\r\n  flex-direction: column;\r\n  max-width: 24%;\r\n  width: 100%;\r\n  padding-inline: 24px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `PageContainer_main__S8bY3`,
	"rowContainer": `PageContainer_rowContainer__5JHDj`,
	"col1": `PageContainer_col1__KOID9`
};
export default ___CSS_LOADER_EXPORT___;
