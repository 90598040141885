// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ForNothingList_swiper__n3jYf {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/ForNothingList/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF","sourcesContent":[".swiper{\r\n  width: 100%;\r\n}\r\n.swiperSlide{\r\n  //@media screen and (min-width: 576px) {\r\n  //  width: calc(25% - 6px)!important;\r\n  //}\r\n  //@media screen and (min-width: 768px) {\r\n  //  width: calc(25% - 12px)!important;\r\n  //}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swiper": `ForNothingList_swiper__n3jYf`
};
export default ___CSS_LOADER_EXPORT___;
